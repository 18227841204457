export class ProductSubtype {
  private Key: string;
  private Value: string;

  setKey(key: string): void {
    this.Key = key;
  }

  getKey(): string {
    return this.Key;
  }

  setValue(value: string): void {
    this.Value = value;
  }

  getValue(): string {
    return this.Value;
  }
}
