import { Injectable } from "@angular/core";

import { Address } from "src/app/ui/models/domains/pdv/address";
import { AbstractParser } from "../parser";

@Injectable()
export class NetworkAddressParser extends AbstractParser<Address> {
  parse(payload: any): Address {
    if (payload.Company.CompanyInfo.Address.length > 0) {
      const address = new Address();
      address.setStreet(payload.Company.CompanyInfo.Address[0].Street);
      address.setNumber(payload.Company.CompanyInfo.Address[0].Number);
      address.setComplement(payload.Company.CompanyInfo.Address[0].Complement);
      address.setZipCode(payload.Company.CompanyInfo.Address[0].ZipCode);
      address.setDistrict(payload.Company.CompanyInfo.Address[0].District);
      address.setCity(payload.Company.CompanyInfo.Address[0].City);
      address.setRegion(payload.Company.CompanyInfo.Address[0].State);
      return address;
    }
    return null;
  }
}
