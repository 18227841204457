import { StateAcronymModel } from "./state-acronym-model";

export class PractitionerRegistration {
  private registration: number = 0;
  private state: StateAcronymModel = new StateAcronymModel();

  setRegistration(value: number): void {
    this.registration = value;
  }

  getRegistration(): number {
    return this.registration;
  }

  setState(value: StateAcronymModel): void {
    this.state = value;
  }

  getState(): StateAcronymModel {
    return this.state;
  }
}
