import { ReleaseNoteItem } from "./release-note-item.model";
import { retry } from "rxjs/operators";

export class ReleaseNote {
  private name: string;
  private items: Array<ReleaseNoteItem>;

  constructor(name: string, items: Array<ReleaseNoteItem>) {
    this.name = name;
    this.items = items;
  }

  public getName(): string {
    return this.name;
  }

  public getItems(): Array<ReleaseNoteItem> {
    return this.items;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public setItems(items: Array<ReleaseNoteItem>): void {
    this.items = items;
  }
}
