import { Component, OnInit } from "@angular/core";
import { FileExportProcessor } from "src/app/ui/models/domains/pdv/exports/file-export-processor";
import { FileExportOrderRequest } from "src/app/ui/models/domains/pdv/file-export-order-request";
import { Network } from "src/app/ui/models/domains/pdv/network";
import { PdvCycleItem } from "src/app/ui/models/domains/pdv/pdv-cycle-item";
import { PdvFinancialStatement } from "src/app/ui/models/domains/pdv/pdv-financial-statement";
import { LoadingService } from "src/app/ui/services/loading.service";
import { FileExportService } from "src/app/ui/services/pdv/file-export.service";
import { NetworkService } from "src/app/ui/services/pdv/network.service";
import { Toast } from "../../../toast/toast";

@Component({
  selector: "pdv-financial-statement-result",
  templateUrl: "./financial-statement-result.component.html",
  styleUrls: ["./financial-statement-result.component.scss"],
})
export class FinancialStatementResultComponent implements OnInit {
  public displayedColumns = ["month", "sellingCycle", "totalSales", "totalReceivable", "export"];
  private fileExportProcessor = new FileExportProcessor();

  constructor(private networkService: NetworkService, private loadingService: LoadingService, private toast: Toast, private fileExportService: FileExportService) {}

  ngOnInit(): void {}

  getPageableResultData(): PdvFinancialStatement {
    const result = (this.networkService.getFinancialStatementsPageableResult().getItems()[0] as PdvFinancialStatement) || new PdvFinancialStatement(null, null);
    return result;
  }

  getNetwork(): Network {
    return this.getPageableResultData().getNetwork();
  }

  getPdvCycleItemList(): Array<PdvCycleItem> {
    return this.getPageableResultData().getPdvCycleItemList() || [];
  }

  async export(pdvCycleItem: PdvCycleItem): Promise<any> {
    const ref = this.loadingService.beginLoading();
    const networkFiscalNumber = this.getNetwork().getFiscalNumber().getValue();

    const startAt = `${pdvCycleItem.getStartPeriod().slice(8, 10)}${pdvCycleItem.getStartPeriod().slice(5, 7)}${pdvCycleItem.getStartPeriod().slice(0, 4)} ${pdvCycleItem
      .getStartPeriod()
      .slice(11, 16)}`;
    const endAt = `${pdvCycleItem.getEndPeriod().slice(8, 10)}${pdvCycleItem.getEndPeriod().slice(5, 7)}${pdvCycleItem.getEndPeriod().slice(0, 4)} ${pdvCycleItem
      .getEndPeriod()
      .slice(11, 16)}`;
    const fileExportOrderRequest = new FileExportOrderRequest(this.networkService, this.toast, this.fileExportService, networkFiscalNumber, startAt, endAt);
    await this.fileExportProcessor.processFile(fileExportOrderRequest, ref);
  }
}
