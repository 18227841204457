import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";

import { MaskInput } from "src/app/ui/models/domains/pdv/mask-input";
import { SalesOrder } from "src/app/ui/models/domains/pdv/sales-order";
import { SalesOrderItem } from "src/app/ui/models/domains/pdv/sales-order-item";
import { StateAcronymModel } from "src/app/ui/models/domains/pdv/state-acronym-model";
import { StateService } from "src/app/ui/services/pdv/state.service";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";
import { NavigatorService } from "src/app/ui/services/pdv/navigator.service";
import { MatTableDataSource } from "@angular/material";
import { AppContext } from "src/app/ui/contexts/app-context";

@Component({
  selector: "pdv-purchase-content",
  templateUrl: "./purchase-content.component.html",
  styleUrls: ["./purchase-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseContentComponent implements OnInit {
  salesOrder: SalesOrder;
  placeHolder = "Digite o Código de Barras, Ean, Nome ou Escaneie o Produto";
  startPurchase: boolean;
  stateList: StateAcronymModel[] = [];
  dateMask = MaskInput.getDateMask();
  currencyMask = MaskInput.getCurrency();
  displayedColumns: string[] = ["product", "quantity", "gross-price", "network-price", "discount", "requires-prescription", "recipe-data", "receipt-date", "delete"];
  dataSource: MatTableDataSource<SalesOrderItemViewAdapter> = new MatTableDataSource<SalesOrderItemViewAdapter>();
  today: number = Date.now();
  hasDeleteData = false;
  constructor(
    private appContext: AppContext,
    private purchaseService: PurchaseService,
    private stateService: StateService,
    private navigatorService: NavigatorService,
    private cdr: ChangeDetectorRef) { }

  async ngOnInit() {
    this.purchaseService.getSalesOrder().subscribe((salesOrder) => {
      this.salesOrder = salesOrder;
      let changes = 0;
      salesOrder.getSalesOrderItemList().forEach((item) => {
        const existingItem = this.dataSource.data.find(dataItem => dataItem.salesOrderItem.getProduct().getEan().getValue() === item.getProduct().getEan().getValue());
        if (existingItem) {
          if (JSON.stringify(existingItem.salesOrderItem) !== JSON.stringify(item)) {
            changes++;
          }
        }
      });
      
      if (changes > 0 || this.dataSource.data.length <= 0 || salesOrder.getSalesOrderItemList().length <= 0) {
        this.dataSource.data = salesOrder.getSalesOrderItemList().map((e) => {
          const item = new SalesOrderItemViewAdapter(e);
          if (item.salesOrderItem.getRequireMedicalPrescription()) {
            if (item.practitionerState == null || item.practitionerState == "") {
              item.practitionerState = this.purchaseService.getNetwork().getAddress().getRegion();
            }
          }
          if(item.salesOrderItem.getProduct().getUsedNetworkSellerPrice()) {
            item.sellerPrice = item.salesOrderItem.calculateDiscountedPrice().toFixed(2).replace(".", ",");
          }
          return item;
        });
        this.cdr.markForCheck();
      }
    });
    this.stateList = await this.stateService.getStates();
    const skuControl = this.appContext.getNetwork().isSkuControl();
    if (skuControl) {
      this.placeHolder = "Digite o Código de Barras, EAN, Nome do Produto, SKU ou Escaneie o Produto";
      this.cdr.detectChanges();
    }
  }

  onTab(event: KeyboardEvent, index: number) {
    if (event.key === "Tab") {
      event.preventDefault();
      const currentInput = event.target as HTMLElement;
      const currentRow = currentInput.closest('tr');
      if (currentRow) {
        const inputFields = Array.from(currentRow.querySelectorAll(`#field`));
        const nextElement = inputFields[index];

        if (nextElement) {
          if (nextElement.tagName.toLowerCase() === 'mat-select') {
            (nextElement as HTMLElement).focus();
          } else {
            (nextElement as HTMLInputElement).focus();
          }
        }
      }
    }
  }

  hasShopper(): boolean {
    if (this.salesOrder) {
      try {
        this.startPurchase = this.salesOrder.getShopper() ? true : false;
        return this.startPurchase;
      } catch {
        return false;
      }
    }
  }

  hasSalesOrderItem(): boolean {
    return this.salesOrder.getSalesOrderItemList().length > 0;
  }

  removeSalesOrderItem(salesOrderItem: SalesOrderItem): void {
    this.hasDeleteData = true;
    this.purchaseService.removeSalesOrderItem(salesOrderItem);
  }

  removeSalesOrderItemQuantity(salesOrderItem: SalesOrderItem): void {
    this.hasDeleteData = true;
    this.purchaseService.removeSalesOrderItemQuantity(salesOrderItem);
  }

  getState(salesOrderItem: SalesOrderItem): string {
    if (salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue()) {
      const index = this.stateList.findIndex((item) => item.getValue() === salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue());
      if (index < 0) {
        return "";
      }
      return this.stateList[index].getValue();
    }
    return "";
  }

  getRegistration(salesOrderItem: SalesOrderItem): string {
    if (salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration()) {
      return salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration().toString();
    }
    return "";
  }

  getMedicalPrescriptionDate(salesOrderItem: SalesOrderItem): string {
    return salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate();
  }

  isStateInputRequired(salesOrderItem: SalesOrderItem): boolean {
    const stateValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue();
    const registrationValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration();
    const dateValue = salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate();
    if (!!stateValue) {
      return false;
    } else if (!!registrationValue || !!dateValue) {
      return true;
    }
    return salesOrderItem.getRequireMedicalPrescription();
  }

  isRegistrationInputRequired(salesOrderItem: SalesOrderItem): boolean {
    const stateValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue();
    const registrationValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration();
    const dateValue = salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate();
    if (!!registrationValue) {
      return false;
    } else if (!!stateValue || !!dateValue) {
      return true;
    }
    return salesOrderItem.getRequireMedicalPrescription();
  }

  isDateInputRequired(salesOrderItem: SalesOrderItem): boolean {
    const stateValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue();
    const registrationValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration();
    const dateValue = salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate();
    if (!!dateValue) {
      return false;
    } else if (!!stateValue || !!registrationValue) {
      return true;
    }
    return salesOrderItem.getRequireMedicalPrescription();
  }

  isInternetExplorer(): boolean {
    return this.navigatorService.isInternetExplorer();
  }

  editPriceValidation(salesOrderItem: SalesOrderItem) {
    const grossPrice = salesOrderItem.getProduct().getGrossPrice();
    const calculatePrice = salesOrderItem.calculatePrice();
    if (grossPrice || calculatePrice != null) {
      return true;
    }
    return false;
  }

  keyPressNumeric(event: any) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();

      return false;
    }
  }

  handleSaveRow() {
    this.salesOrder.setSalesOrderItemList(this.dataSource.data.map((e) => e.salesOrderItem));
    this.purchaseService.setSalesOrder(this.salesOrder);
  }

  productDetail(salesOrderItem: SalesOrderItem): string {
    return `
      ${salesOrderItem.getProduct().getName()}
      \n${salesOrderItem.getProduct().getPresentation() ? salesOrderItem.getProduct().getPresentation() : ""}
      Substânica: ${salesOrderItem.getProduct().getSubstance() ? salesOrderItem.getProduct().getSubstance() : "-"}
      EAN: ${salesOrderItem.getProduct().getEan().getValue() ? salesOrderItem.getProduct().getEan().getValue() : "-"}
      Lab: ${salesOrderItem.getProduct().getLaboratory() ? salesOrderItem.getProduct().getLaboratory() : "-"}
      Tipo: ${salesOrderItem.getProduct().getType() ? salesOrderItem.getProduct().getType() : "-"} 
    `;
  }

  copyToClipboard(value: string) {
    const el = document.createElement("textarea");
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
}

class SalesOrderItemViewAdapter {
  constructor(item: SalesOrderItem) {
    this._salesOrderItem = item;
  }
  private _salesOrderItem: SalesOrderItem;

  public get canEditPrice() {
    return this._salesOrderItem.getProduct().getCanInputPrice();
  }
  get salesOrderItem() {
    return this._salesOrderItem;
  }
  get practitionerState() {
    return this._salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue();
  }
  set practitionerState(value: string) {
    var e = new StateAcronymModel();
    e.setValue(value);
    this._salesOrderItem.getMedicalPrescription().getPractitionerRegistration().setState(e);
  }

  get practitionerRegister() {
    const value = this._salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration();
    return value > 0 ? value : null;
  }
  set practitionerRegister(value: number | null) {
    this._salesOrderItem.getMedicalPrescription().getPractitionerRegistration().setRegistration(value);
  }

  get practitionerDate() {
    return this._salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate();
  }
  set practitionerDate(value: string) {
    this._salesOrderItem.getMedicalPrescription().setMedicalPrescriptionDate(value);
  }

  get sellerPrice() {
    const price = this._salesOrderItem.getProduct().getSellerUnitPrice().toString();
    return price.replace(".", ",");
  }
  set sellerPrice(value: string) {
    const price = this.stringToFloat(value);
    this._salesOrderItem.getProduct().setSellerUnitPrice(price);
  }

  get sellerGrossPrice() {
    const price = this._salesOrderItem.getProduct().getSellerUnitGrossPrice().toString();
    return price.replace(".", ",");
  }
  set sellerGrossPrice(value: string) {
    const price = this.stringToFloat(value);
    this._salesOrderItem.getProduct().setSellerUnitGrossPrice(price);
    
    const priceUnit = this._salesOrderItem.calculateDiscountedPrice();
    if(priceUnit < 0) {
      this.sellerPrice = priceUnit.toFixed(2).replace(".", ",");
    }
  }

  private stringToFloat(value: string) {
    return Number.parseFloat((value || "0").replace(/[^0-9,-]+/g, "").replace(",", "."));
  }
  get hasExternalDiscount(): boolean {
    return this._salesOrderItem.getProduct().getHasExternalAuthorizerBetterDiscount();
  }
}
