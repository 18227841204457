import { Component, Input, AfterViewInit, ElementRef, ChangeDetectorRef, ViewEncapsulation, ChangeDetectionStrategy } from "@angular/core";

@Component({
  moduleId: module.id,
  selector: "full-dialog",
  templateUrl: "./full-dialog.component.html",
  styleUrls: ["./full-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullDialogComponent implements AfterViewInit {
  @Input() color: string = "primary";

  constructor(public el: ElementRef, private ref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.setCustomClass();
    this.ref.detectChanges();
  }

  setCustomClass() {
    let header = (this.el.nativeElement.querySelectorAll("#dialog-header-identifier") as HTMLElement)[0];
    header.classList.add(this.color);
  }
}
