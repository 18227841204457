import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FilterOption } from "../../models/domains/filters/filter-option";
import { ComboBoxFilterOption } from "../../models/domains/filters/combobox-filter-option.model";
import { CheckBoxFilterOption } from "../../models/domains/filters/check-box-filter-option";
import { Item } from "../../models/domains/item/item";
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";

export const CUSTOM_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMM YYYY",
  },
};

@Component({
  selector: "filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS },
  ],
})
export class FilterComponent implements OnInit {
  @Input() options: Array<FilterOption>;
  @Input() hasCleanFilter: boolean = true;
  @Input() hasExport: boolean = true;
  @Output() onFiltering: EventEmitter<FilterOption[]> = new EventEmitter();
  @Output() onExporting: EventEmitter<FilterOption[]> = new EventEmitter();

  visibleFilters: boolean = true;
  totalFiltersInUse: number = 0;
  timeMask = [/[0-9]/, /[0-9]/, ":", /[0-9]/, /[0-9]/];
  constructor() {}

  ngOnInit() {}

  showFilters(visibleFilters: boolean): void {
    this.visibleFilters = visibleFilters;
    if (!this.visibleFilters) {
      this.totalFiltersInUse = this.calculateTotalFiltersInUse();
    }
  }

  calculateTotalFiltersInUse(): number {
    this.totalFiltersInUse = this.options.filter((f) => f.hasValue()).length;
    return this.totalFiltersInUse;
  }

  onNumberFilterKeyUp(event: any, filter: FilterOption) {
    if (filter.getValue()) {
      filter.setValue(
        filter
          .getValue()
          .toString()
          .replace(/[^0-9]*/g, ""),
      );
    }
  }

  onSelectChange(event: any, filter: FilterOption): void {
    var currentFilter = this.options.find((e) => e === filter);
    var comboBoxFilter = currentFilter as ComboBoxFilterOption;
    if (!!comboBoxFilter) {
      let selectedItem = new Item(event as number, "");
      comboBoxFilter.setValue(selectedItem.id);
    }
  }

  onCheckBoxChange(event: any, filter: FilterOption): void {
    let checkBoxFilter = filter as CheckBoxFilterOption;
    if (!!checkBoxFilter) {
      checkBoxFilter.setValue(event);
    }
  }

  search(): void {
    this.onFiltering.emit(this.options);
  }

  export(): void {
    this.onExporting.emit();
  }

  clearFilters(): void {
    this.options.forEach((f) => f.clear());
    this.onFiltering.emit(this.options);
  }
}
