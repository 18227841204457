import { Ean } from "./ean";
import { ProductKey } from "./product-key";

export class ProductSimple {
  private productKey: ProductKey;
  private ean: Ean;
  private productName: string;
  private presentation: string;
  private laboratory: string;
  private productSubType: string;

  setProductKey(value: ProductKey): void {
    this.productKey = value;
  }

  getProductKey(): ProductKey {
    return this.productKey;
  }

  setEan(value: Ean): void {
    this.ean = value;
  }

  getEan(): Ean {
    return this.ean;
  }

  setProductName(value: string): void {
    this.productName = value;
  }

  getProductName(): string {
    return this.productName;
  }

  setPresentation(value: string): void {
    this.presentation = value;
  }

  getDisplayName(): string {
    return this.productName + " " + this.presentation;
  }

  getPresentation(): string {
    return this.presentation;
  }

  setLaboratory(value: string): void {
    this.laboratory = value;
  }

  getLaboratory(): string {
    return this.laboratory;
  }

  setProductSubType(value: string): void {
    this.productSubType = value;
  }

  getProductSubType(): string {
    return this.productSubType;
  }
}
