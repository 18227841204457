import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import { UserCredentials } from "../models/domains/credentials/user-credentials.model";
import { ScsAuthorizationTokenParser } from "./parsers/scs-authorizatio-token.parser";
import { RemoteGatewayFactory } from "../gateways/remote-gateway-factory";
import { UserCredentialsParser } from "./parsers/user-credentials.parser";
import { AuthUrl } from "../models/domains/urls/auth.url";
import { AuthUserTokenValidationUrl } from "../models/domains/urls/auth-user-token-validation.url";
import { AuthServiceTokenValidationUrl } from "../models/domains/urls/auth-service-token-validation.utl";
import { PdvPassport } from "../models/domains/pdv/pdv-passport";
import { NetworkParser } from "./parsers/pdv/network.parser";
import { Network } from "../models/domains/pdv/network";
import { BackendUrl } from "../models/domains/urls/back-end.url";
import { Address } from "../models/domains/pdv/address";
import { FiscalNumber } from "../models/domains/pdv/fiscal-number";
import { NetworkAddressParser } from "./parsers/pdv/network-address.parser";

@Injectable()
export class AuthRepository {
  constructor(
    private remoteGatewayFactory: RemoteGatewayFactory,
    private scsAuthorizationTokenParser: ScsAuthorizationTokenParser,
    private userCredentialsParser: UserCredentialsParser,
    private networkParser: NetworkParser,
    private networkAddressParser: NetworkAddressParser,
  ) {}

  async authenticatePdv(pdvPassport: PdvPassport): Promise<Network> {
    const body = JSON.stringify({
      ClientId: pdvPassport.getClientId(),
      FiscalNumber: pdvPassport.getNetworkFiscalNumber(),
      OperatorUserName: pdvPassport.getOperatorUserName(),
      GrantType: pdvPassport.getGrantType(),
    });

    const headers = { "Content-Type": "application/json" };

    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
            const data: any = await remoteGateway.postWithHeaders(new BackendUrl('/api/pbm/drugstore/network/auth'), body, headers);
      return Promise.resolve(this.networkParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async getNetworkAddressByFiscalNumber(fiscalNumber: FiscalNumber): Promise<Address> {
    try {
      const headers = { "Content-Type": "application/json" };

      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
            const data: any = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/company/mdm/' + fiscalNumber.getValue()), headers);            
      return Promise.resolve(this.networkAddressParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async login(username: string, password: string): Promise<UserCredentials> {
    const body = new HttpParams().set("client_id", environment.authClientId.toString()).set("username", username).set("password", password).set("grant_type", "password");

    const headers = { "Content-Type": "application/x-www-form-urlencoded" };

    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const data: any = await remoteGateway.postWithHeaders(new AuthUrl("/token"), body.toString(), headers);
      return Promise.resolve(this.userCredentialsParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async validateUserToken(token: string): Promise<boolean> {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const data = await remoteGateway.getWithHeaders(new AuthUserTokenValidationUrl("/Authorization/test"), headers);
      return this.scsAuthorizationTokenParser.parse(data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  async validateServiceToken(token: string): Promise<boolean> {
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const data = await remoteGateway.getWithHeaders(new AuthServiceTokenValidationUrl("/Authorization/verify"), {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      });
      return this.scsAuthorizationTokenParser.parse(data);
    } catch (error) {
      return false;
    }
  }

  async switchContext(userId: string, refreshToken: string, sourceApplicationId: string): Promise<UserCredentials> {
    try {
      const body = {
        userId: userId,
        refreshToken: refreshToken,
        sourceApplicationId: sourceApplicationId,
        targetApplicationId: environment.authClientId,
      };
      const gateway = this.remoteGatewayFactory.createAuthRemoteGateway();
      const data = await gateway.post("/api/switchcontext", body);
      return Promise.resolve(this.userCredentialsParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
