import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AlertService } from "../../services/alert.service";
import { ToastService } from "../../services/toast.service";
import { ToastType } from "../../models/domains/toast/toast-type";
import { DialogRef } from "../dialog/dialog-ref";
import { LoadingService } from "../../services/loading.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-change-password-dialog",
  templateUrl: "./change-password-dialog.component.html",
  styleUrls: ["./change-password-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordDialogComponent implements OnInit {
  changePasswordFormGroup: FormGroup;
  passwordPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^ws]).{8,}$";
  alert: boolean = false;
  errorMessageColor: string = "red";

  constructor(
    private dialogRef: DialogRef<void>,
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private toast: ToastService,
    private loading: LoadingService,
  ) {}

  ngOnInit() {
    this.createChangePasswordFormGroup();
  }

  createChangePasswordFormGroup() {
    this.changePasswordFormGroup = this._formBuilder.group(
      {
        currentPassword: ["", [Validators.required]],
        newPassword: ["", [Validators.required, Validators.pattern(this.passwordPattern)]],
        confirmPassword: ["", Validators.required],
      },
      { validator: this.checkPasswords },
    );
  }

  checkPasswords(group: FormGroup) {
    let password = group.controls.newPassword;
    let confirmPassword = group.controls.confirmPassword;
    return password.value != confirmPassword.value ? { matchPassword: true } : null;
  }

  //condition show error
  currentPasswordInvalid(): boolean {
    return this.changePasswordFormGroup.get("currentPassword").invalid && this.changePasswordFormGroup.get("currentPassword").touched;
  }

  newPasswordInvalid(): boolean {
    return this.changePasswordFormGroup.get("newPassword").invalid && this.changePasswordFormGroup.get("newPassword").touched;
  }

  confirmPasswordInvalid(): boolean {
    return (
      (this.changePasswordFormGroup.get("confirmPassword").invalid || this.changePasswordFormGroup.hasError("matchPassword")) &&
      this.changePasswordFormGroup.get("confirmPassword").touched
    );
  }

  //message error
  getCurrentPasswordErrorMessage(): string {
    return this.changePasswordFormGroup.get("currentPassword").hasError("required") ? "Por favor, informe sua senha atual." : "";
  }

  getNewPasswordErrorMessage(): string {
    return this.changePasswordFormGroup.get("newPassword").hasError("required")
      ? "Por favor, informe sua nova senha."
      : this.changePasswordFormGroup.get("newPassword").hasError("pattern")
      ? "Senha inválida"
      : "";
  }

  getConfirmPasswordErrorMessage(): string {
    return this.changePasswordFormGroup.get("confirmPassword").hasError("required")
      ? "Por favor, confirme sua senha."
      : this.changePasswordFormGroup.hasError("matchPassword")
      ? "Confirmação de senha está diferente da nova senha informada."
      : "";
  }

  formGroupIsValid(): boolean {
    return this.changePasswordFormGroup.invalid;
  }

  changePassword(): void {
    const ref = this.loading.beginLoading("Alterando senha...");
    const currentPassword = this.changePasswordFormGroup.get("currentPassword").value;
    const newPassword = this.changePasswordFormGroup.get("newPassword").value;
    const confirmPassword = this.changePasswordFormGroup.get("confirmPassword").value;
    this.userService
      .changePassword(currentPassword, newPassword, confirmPassword)
      .then(() => {
        this.showSuccessMessage("Alteração de senha", "Senha alterada com sucesso!", "success");
        this.dialogRef.close();
      })
      .catch((error) => {
        let msgError = error.error === "Incorrect password." ? "Senha atual está inválida." : error.error;
        this.showAlert(msgError, true, "alert-change-password");
      })
      .finally(() => {
        this.loading.finishLoading(ref);
      });
  }

  showAlert(msgError: any, closeOptions: boolean, alertId: string) {
    this.alert = true;
    this.alertService.onlyOneError(msgError, closeOptions, alertId);
  }

  removeAlert() {
    this.alert = !this.alert;
  }

  showSuccessMessage(title: string, text: string, type: ToastType) {
    this.toast.show({
      title: title,
      text: text,
      type: type,
    });
  }
}
