import { Card } from "../pdv/card";
import { FiscalNumber } from "../pdv/fiscal-number";
import { SocialNumber } from "../pdv/social-number";
import { UniqueSequentialNumber } from "../pdv/unique-sequential-number";

export class ConsultSalesPagingRequest {
  private networkFiscalNumber: FiscalNumber;
  private startIndex = 1;
  private totalPerPages = 9999;
  private uniqueSequentialNumber: UniqueSequentialNumber;
  private socialNumber: SocialNumber;
  private card: Card;
  private transactionDate: string;

  public getNetworkFiscalNumber(): FiscalNumber {
    return this.networkFiscalNumber;
  }

  public setNetworkFiscalNumber(networkFiscalNumber: FiscalNumber): void {
    this.networkFiscalNumber = networkFiscalNumber;
  }

  public getUniqueSequentialNumber(): UniqueSequentialNumber {
    return this.uniqueSequentialNumber;
  }

  public setUniqueSequentialNumber(uniqueSequentialNumber: UniqueSequentialNumber): void {
    this.uniqueSequentialNumber = uniqueSequentialNumber;
  }

  public getSocialNumber(): SocialNumber {
    return this.socialNumber;
  }

  public setSocialNumber(socialNumber: SocialNumber): void {
    this.socialNumber = socialNumber;
  }

  public getCard(): Card {
    return this.card;
  }

  public setCard(card: Card): void {
    this.card = card;
  }

  public getTransactionDate(): string {
    return this.transactionDate;
  }

  public setTransactionDate(transactionDate: string): void {
    this.transactionDate = transactionDate;
  }
}
