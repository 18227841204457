import { Injectable } from "@angular/core";
import { BeneficiaryKey } from "src/app/ui/models/domains/pdv/beneficiary-key";
import { Company } from "src/app/ui/models/domains/pdv/company";
import { FiscalNumber } from "src/app/ui/models/domains/pdv/fiscal-number";
import { Shopper } from "src/app/ui/models/domains/pdv/shopper";
import { SocialNumber } from "src/app/ui/models/domains/pdv/social-number";
import { AbstractParser } from "../parser";

@Injectable()
export class SearchShopperByParser extends AbstractParser<Shopper> {
  parse(payload: any): Shopper {
    const shopper = new Shopper();
    payload.Active ? shopper.activate() : shopper.deactivate();
    shopper.setBeneficiaryKey(new BeneficiaryKey(payload.BeneficiaryKey.Value));
    shopper.setName(payload.Name);
    shopper.setSocialNumber(new SocialNumber(payload.SocialNumber));
    shopper.setVirtualCardNumber(payload.Card.CardNumber);
    shopper.setIsBlockedCard(payload.Card.IsBlocked);
    shopper.setCompany(this.getCompany(payload.Company));
    shopper.setHaveTransactionalPassword(payload.HaveTransactionalPassword);
    shopper.setTransactionalPassword(payload.TransactionalPassword);
    return shopper;
  }

  private getCompany(payload: any): Company {
    let company = new Company();
    company.setFiscalNumber(new FiscalNumber(payload.FiscalNumber));
    company.setCorporateName(payload.CorporateName);
    return company;
  }
}
