import { Injectable } from "@angular/core";
import { AppContext } from "../../contexts/app-context";
import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { FiscalNumber } from "../../models/domains/pdv/fiscal-number";
import { MedicalPrescriptionDocumentKey } from "../../models/domains/pdv/medical-prescription-document-key";
import { Product } from "../../models/domains/pdv/product";
import { Shopper } from "../../models/domains/pdv/shopper";
import { SocialNumber } from "../../models/domains/pdv/social-number";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { DoesProductRequireScanParser, IConfigMedicalPrescription } from "../parsers/pdv/does-product-require-scan.parser";
import { MedicalPrescriptionDocumentKeyParser } from "../parsers/pdv/medical-prescription-document-key-parser";

@Injectable()
export class MedicalPrescriptionRepository {
  constructor(
    private remoteGatewayFactory: RemoteGatewayFactory,
    private medicalPrescriptionDocumentKeyParser: MedicalPrescriptionDocumentKeyParser,
    private doesProductRequireScanParser: DoesProductRequireScanParser,
  ) {}

  public async doesProductRequireScan(shopper: Shopper, product: Product): Promise<IConfigMedicalPrescription> {
    const body = JSON.stringify({
      Product: {
        Name: product.getName(),
        Ean: product.getEan().getValue(),
        Laboratory: product.getLaboratory(),
        Presentation: product.getPresentation(),
        Substance: product.getSubstance(),
        Type: product.getType(),
        SubType: product.getSubType(),
      },
      FiscalNumber: {
        Value: shopper.getCompany().getFiscalNumber().getValue(),
      },
    });
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
            const payload: any = await remoteGateway.postWithHeaders(new BackendUrl('/api/pbm/drugstore/medical-prescription/'+ shopper.getVirtualCardNumber()), body, headers);
      return this.doesProductRequireScanParser.parse(payload);
    } catch (error) {
      return error;
    }
  }

  public async uploadMedicalPrescription(shopper: Shopper, data: FormData): Promise<MedicalPrescriptionDocumentKey> {
    const body = data;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
            const payload: any = await remoteGateway.post('/api/pbm/drugstore/medical-prescription/'+shopper.getVirtualCardNumber()+'/import', body);
      return this.medicalPrescriptionDocumentKeyParser.parse(payload);
    } catch (error) {
      return error;
    }
  }
}
