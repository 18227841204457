import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
  moduleId: module.id,
  selector: "menu-bar",
  templateUrl: "menu-bar.html",
  styleUrls: ["./menu-bar.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuBarComponent {
  constructor() {}
}
