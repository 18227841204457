import { FiscalNumber } from "./fiscal-number";

export class Company {
  private corporateName: string;
  private fiscalNumber: FiscalNumber;

  setCorporateName(value: string): void {
    this.corporateName = value;
  }

  getCorporateName(): string {
    return this.corporateName;
  }

  setFiscalNumber(value: FiscalNumber): void {
    this.fiscalNumber = value;
  }

  getFiscalNumber(): FiscalNumber {
    return this.fiscalNumber;
  }
}
