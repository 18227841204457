import { Injectable } from "@angular/core";
import { PageableResult } from "src/app/ui/models/domains/paginator/pageable-result";
import { PdvFinancialStatement } from "src/app/ui/models/domains/pdv/pdv-financial-statement";
import { AbstractParser } from "../parser";

import { parseNetwork, parsePdvCycleItemList, parsePdvFinancialStatementList } from "./network-parse-functions";

@Injectable()
export class SearchFinancialStatementsParse extends AbstractParser<PageableResult> {
  parse(payload: any): PageableResult {
    const pageableResult = new PageableResult();
    pageableResult.setStart(payload.Start);
    pageableResult.setEnd(payload.TotalPerPages);
    pageableResult.setTotalItems(payload.Total);
    pageableResult.setItems(parsePdvFinancialStatementList(payload.Data));
    return pageableResult;
  }

  parsePdvFinancialStatementList(payload: any): Array<PdvFinancialStatement> {
    return payload.map((item) => {
      return new PdvFinancialStatement(parseNetwork(item.Network), parsePdvCycleItemList(item.Items));
    });
  }
}
