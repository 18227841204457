import { GridColumnActionType } from "./grid-column-action-type";
import { ModalInfo } from "../modal/modal-info";
import { NavigateInfo } from "../navigate-info";
import { FormTemplate } from "./form-template.model";

export class GridColumn {
  binding: string;
  display: string;
  hide: boolean = false;
  orderBy: boolean;
  iconUrl: string;
  iconSvg: string;
  iconToolTip: string;
  icon: boolean;
  draw: boolean = false;
  form: boolean = false;
  gridColumnActionaType: string;
  sortAsc: boolean = true;
  modalInfo: ModalInfo;
  gridColumnActionType: GridColumnActionType;
  formTemplate: FormTemplate;
  private size: string;
  private navigateInfo: NavigateInfo;

  evaluate(item: any): string {
    return item[this.binding];
  }

  isIcon(): boolean {
    return this.icon;
  }

  isHidden(): boolean {
    return this.hide;
  }

  isDrawColumn(): boolean {
    return this.draw;
  }

  isIconUrl(): boolean {
    return !!this.iconUrl;
  }

  isIconSvg(): boolean {
    return !!this.iconSvg;
  }

  isForm(): boolean {
    return this.form;
  }

  getIconSvg(): string {
    return this.iconSvg;
  }

  getIconUrl(): string {
    return this.iconUrl;
  }

  getIconTooltip(): string {
    return this.iconToolTip;
  }

  renderByMaterialTable(): Object {
    return { columnDef: this.display, header: this.display, isIcon: this.icon, isDrawColumn: false, customColumn: this, cell: (row: Object) => `${row[this.binding]}` };
  }

  isModalgridColumnActionType(): boolean {
    return this.gridColumnActionType == GridColumnActionType.Modal ? true : false;
  }

  setNavigateRoute(route: string): void {
    this.navigateInfo = new NavigateInfo(route);
  }

  setModalInfo(modalInfo: ModalInfo): void {
    this.modalInfo = modalInfo;
  }

  getNavigateRoute(): string {
    return this.navigateInfo.getRoute();
  }

  getModalInfo(): ModalInfo {
    return this.modalInfo;
  }

  setSize(size: string): void {
    this.size = size;
  }

  getSize(): string {
    return this.size;
  }
}
