export class ResponseError {
  private errorCode: string;
  private errorMessage: string;

  setErrorCode(value: string): void {
    this.errorCode = value;
  }

  getErrorCode(): string {
    return this.errorCode;
  }

  setErrorMessage(value: string): void {
    this.errorMessage = value;
  }

  getErrorMessage(): string {
    return this.errorMessage;
  }
}
