import { Component, OnInit } from "@angular/core";
import { NewsService } from "../../services/news.service";
import { ReleaseNoteType } from "../../models/domains/news/release-note-type.model";
import { ReleaseNote } from "../../models/domains/news/release-note.model";
import { ReleaseNoteItem } from "../../models/domains/news/release-note-item.model";
import { release } from "os";
import { ToastService } from "../../services/toast.service";

@Component({
  selector: "news-panel",
  templateUrl: "./news-panel.component.html",
  styleUrls: ["./news-panel.component.scss"],
})
export class NewsPanelComponent implements OnInit {
  releaseNote: ReleaseNote;
  visible: boolean = true;
  showGiftIcon: boolean = true;

  constructor(private newsService: NewsService, private toast: ToastService) {}

  ngOnInit() {
    this.newsService
      .getNews()
      .then((releaseNote) => {
        this.releaseNote = releaseNote;
      })
      .catch((error) => {
        this.showGiftIcon = false;
        console.log("Error retrieving release notes: " + error);
      });
  }

  hasNewReleaseNote(): boolean {
    return !!this.releaseNote && !this.newsService.isEqualsToLastReleaseNotes(this.releaseNote);
  }

  isBug(item: ReleaseNoteItem): boolean {
    return item.getType() == ReleaseNoteType.Bug;
  }

  getFeatureStyle(): object {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) {
      return { width: "25px", height: "20px", color: "rgb(0,74,143)", "padding-right": "10px" };
    } else {
      return { width: "25px", height: "23px", color: "rgb(0,74,143)", "padding-right": "10px" };
    }
  }

  getBugStyle(): object {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) {
      return { "vertical-align": "text-bottom", "padding-right": "10px", widht: "25px", height: "20px" };
    } else {
      return { "vertical-align": "text-bottom", "padding-right": "10px", widht: "25px", height: "23px" };
    }
  }

  markAsRead(): void {
    this.newsService.markAsRead(this.releaseNote);
  }
}
