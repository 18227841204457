import { Component } from "@angular/core";

@Component({
  selector: "input-error",
  templateUrl: "./input-error.component.html",
  styleUrls: ["./input-error.component.scss"],
})
export class InputErrorComponent {
  constructor() {}
}
