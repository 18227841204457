import { Injectable } from "@angular/core";
import { StateAcronymModel } from "src/app/ui/models/domains/pdv/state-acronym-model";
import { AbstractParser } from "../parser";

@Injectable()
export class StateAcronymParser extends AbstractParser<StateAcronymModel[]> {
  parse(payload: any): StateAcronymModel[] {
    const stateAcronymModel: Array<StateAcronymModel> = [];
    if (payload) {
      payload.forEach((uf) => {
        const state = new StateAcronymModel();
        state.setValue(uf.Value);
        stateAcronymModel.push(state);
      });
    }
    return stateAcronymModel;
  }
}
