export class RuleEngineConfiguration {
  public static readonly defaultPreSalesValidityHours = 24;

  constructor(private readonly salesAction: string, private readonly minSalesHours: number) {}

  getSalesAction(): string {
    return this.salesAction;
  }

  getMinSalesHours(): number {
    return this.minSalesHours;
  }
}
