import {
  Component,
  OnInit,
  ViewContainerRef,
  Type,
  ComponentFactoryResolver,
  ComponentRef,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  AfterContentInit,
} from "@angular/core";
import { IDynamicService } from "../../models/domains/dynamic-service/dynamic-service-interface";

@Component({
  selector: "app-dynamic-component",
  templateUrl: "./dynamic-component.component.html",
  styleUrls: ["./dynamic-component.component.scss"],
})
export class DynamicComponentComponent {
  public containerRef: ViewContainerRef;

  public showHeader: boolean = false;
  public index: number;
  public dynamicService: IDynamicService<any>;
  public headerText: string;
  private removeButton: boolean;

  @ViewChild("containerRef", { read: ViewContainerRef, static: true }) set content(content: ViewContainerRef) {
    this.containerRef = content;
  }

  constructor(public cdr: ChangeDetectorRef) {}

  showRemoveButton(): boolean {
    if (this.dynamicService) return this.removeButton && this.index >= this.dynamicService.config.getShowRemoveButtonFromIndex();

    return false;
  }

  removeMe(index) {
    this.dynamicService.remove(index);
  }

  setHeaderText(text: string) {
    this.headerText = text;
  }

  setRemoveButton(value: boolean): void {
    this.removeButton = value;
  }

  setShowHeader(value: boolean) {
    this.showHeader = value;
  }
}
