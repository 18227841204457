export class RowUpdatingEventArgs {
  private newValue: any;

  constructor(newValue: any) {
    this.newValue = newValue;
  }

  getNewValue(): any {
    return this.newValue;
  }
}
