import { AuthGrantType } from "./enum/auth-grant-type.enum";
import { FiscalNumber } from "./fiscal-number";

export class PdvPassport {
  private clientId: string;
  private networkFiscalNumber: FiscalNumber;
  private operatorUserName: string;
  private token: string;
  private grantType: string;

  setClientId(value: string): void {
    this.clientId = value;
  }

  getClientId(): string {
    return this.clientId;
  }

  setNetworkFiscalNumber(value: FiscalNumber): void {
    this.networkFiscalNumber = value;
  }

  getNetworkFiscalNumber(): FiscalNumber {
    return this.networkFiscalNumber;
  }

  setOperatorUserName(value: string): void {
    this.operatorUserName = value;
  }

  getOperatorUserName(): string {
    return this.operatorUserName;
  }

  setToken(value: string): void {
    this.token = value;
  }

  getToken(): string {
    return this.token;
  }

  setGrantType(value: string): void {
    this.grantType = value;
  }

  getGrantType(): string {
    return this.grantType;
  }

  isGrantTypeAcceptTerm(): boolean {
    return this.grantType === AuthGrantType.AcceptTerm;
  }

  isGrantTypeClientCredentials(): boolean {
    return this.grantType === AuthGrantType.ClientCredentials;
  }

  isUnknowPdvPassport(): boolean {
    return false;
  }

  getUrlParameters(): string {
    return `t=${this.token}&f=${this.networkFiscalNumber}&c=${this.clientId}&u=${this.operatorUserName}`;
  }
}

export class UnkownPdvPassport extends PdvPassport {
  isUnknowPdvPassport(): boolean {
    return true;
  }
}
