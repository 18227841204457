import { Toast } from "src/app/ui/components/toast/toast";
import { FileExportService } from "src/app/ui/services/pdv/file-export.service";
import { NetworkService } from "src/app/ui/services/pdv/network.service";
import { ExportOrderKey } from "./exports/export-order-key";
import { IFileExportOrderRequest } from "./exports/file-export-order-request";
import { XLSXFileExportResult } from "./exports/file-export-result";

export class FileExportOrderRequest implements IFileExportOrderRequest {
  constructor(
    private networkService: NetworkService,
    private toast: Toast,
    private fileExportService: FileExportService,
    private networkFiscalNumber: string,
    private startAt: string,
    private endAt: string,
  ) {}

  getFileExportName(): string {
    return `ExtratoPDV-${this.networkFiscalNumber}`;
  }

  showError(error: any): void {
    this.toast.showWarningToast(error);
  }

  async downloadFile(orderKey: ExportOrderKey): Promise<XLSXFileExportResult> {
    return await this.fileExportService.exportFileAsXLSX(orderKey);
  }

  async isFileExportReady(orderKey: ExportOrderKey): Promise<boolean> {
    return await this.fileExportService.getFileExportStatus(orderKey);
  }

  async orderExport(): Promise<ExportOrderKey> {
    const exportOrderKey = await this.networkService.findExportOrderKey(this.networkFiscalNumber, this.startAt, this.endAt);
    return exportOrderKey;
  }
}
