import { Injectable, TemplateRef, ViewContainerRef, ElementRef } from "@angular/core";
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy, OriginConnectionPosition, OverlayConnectionPosition } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";

@Injectable({
  providedIn: "root",
})
export class OverlayService {
  constructor(private overlay: Overlay) {}

  createOverlay(config: AppOverlayConfig): OverlayRef {
    return this.overlay.create(config);
  }
  attachTemplatePortal(overlayRef: OverlayRef, templateRef: TemplateRef<any>, vcRef: ViewContainerRef) {
    let templatePortal = new TemplatePortal(templateRef, vcRef);
    overlayRef.attach(templatePortal);
  }
  positionGloballyCenter(): PositionStrategy {
    return this.overlay.position().global().centerHorizontally().centerVertically();
  }

  getPosition(elementToConnectTo: ElementRef) {
    let origin = {
      topLeft: { originX: "start", originY: "top" } as OriginConnectionPosition,
      topRight: { originX: "end", originY: "top" } as OriginConnectionPosition,
      bottomLeft: { originX: "start", originY: "bottom" } as OriginConnectionPosition,
      bottomRight: { originX: "end", originY: "bottom" } as OriginConnectionPosition,
      topCenter: { originX: "center", originY: "top" } as OriginConnectionPosition,
      bottomCenter: { originX: "center", originY: "bottom" } as OriginConnectionPosition,
    };
    let overlay = {
      topLeft: { overlayX: "start", overlayY: "top" } as OverlayConnectionPosition,
      topRight: { overlayX: "end", overlayY: "top" } as OverlayConnectionPosition,
      bottomLeft: { overlayX: "start", overlayY: "bottom" } as OverlayConnectionPosition,
      bottomRight: { overlayX: "end", overlayY: "bottom" } as OverlayConnectionPosition,
      topCenter: { overlayX: "center", overlayY: "top" } as OverlayConnectionPosition,
      bottomCenter: { overlayX: "center", overlayY: "bottom" } as OverlayConnectionPosition,
    };
    return this.overlay
      .position()
      .connectedTo(elementToConnectTo, origin.bottomLeft, overlay.topLeft)
      .withOffsetY(10)
      .withDirection("ltr")
      .withFallbackPosition(origin.bottomRight, overlay.topRight)
      .withFallbackPosition(origin.topLeft, overlay.bottomLeft)
      .withFallbackPosition(origin.topRight, overlay.bottomRight)
      .withFallbackPosition(origin.topCenter, overlay.bottomCenter)
      .withFallbackPosition(origin.bottomCenter, overlay.topCenter);
  }
}

export interface AppOverlayConfig extends OverlayConfig {}
