import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MatInputModule } from "@angular/material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";

import { AppComponent } from "./app.component";
import { ContextModule } from "./ui/contexts/context.module";
import { AppRoutingModule } from "./ui/routing/routing.module";
import { ServiceModule } from "./ui/services/service.module";
import { CoreModule } from "./core/core.module";
import { ProviderModule } from "./ui/providers/providers.module";
import { PipeModule } from "./ui/pipes/pipe.module";
import { ComponentModule } from "./ui/components/component.module";

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    ComponentModule,
    ContextModule.forRoot(),
    ServiceModule.forRoot(),
    ProviderModule.forRoot(),
    PipeModule.forRoot(),
    AppRoutingModule,
  ],
  schemas: [MatInputModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
