import { Address } from "./address";
import { FiscalNumber } from "./fiscal-number";
import { NetworkAcceptTerm } from "./network-accept-term";

export class Network {
  private acceptedTerm: NetworkAcceptTerm;
  private address: Address;
  private networkInfoKey: string;
  private fiscalNumber: FiscalNumber;
  private corporateName: string;
  private tradingName: string;
  private headOfficeExists: boolean;
  private headOffice: boolean;
  private headOfficeAcceptedTerm: boolean;
  private skuControl: boolean;

  setAddress(value: Address): void {
    this.address = value;
  }

  getAddress(): Address {
    return this.address;
  }

  setNetworkInfoKey(value: string): void {
    this.networkInfoKey = value;
  }

  getNetworkInfoKey(): string {
    return this.networkInfoKey;
  }

  setFiscalNumber(value: FiscalNumber): void {
    this.fiscalNumber = value;
  }

  getFiscalNumber(): FiscalNumber {
    return this.fiscalNumber;
  }

  setCorporateName(value: string): void {
    this.corporateName = value;
  }

  getCorporateName(): string {
    return this.corporateName;
  }

  setTradingName(value: string): void {
    this.tradingName = value;
  }

  getTradingName(): string {
    return this.tradingName;
  }

  setAcceptedTerm(value: NetworkAcceptTerm): void {
    this.acceptedTerm = value;
  }

  getAcceptedTerm(): NetworkAcceptTerm {
    return this.acceptedTerm;
  }

  isTermAccepted(): boolean {
    return !!this.acceptedTerm;
  }

  isUnknowNetwork(): boolean {
    return false;
  }

  hasHeadOffice(): boolean {
    return this.headOfficeExists;
  }

  setHeadOfficeExists(value: boolean): void {
    this.headOfficeExists = value;
  }

  isHeadOffice(): boolean {
    return this.headOffice;
  }

  setHeadOffice(value: boolean): void {
    this.headOffice = value;
  }

  isHeadOfficeAcceptedTerm(): boolean {
    return this.headOfficeAcceptedTerm;
  }

  setHeadOfficeAcceptedTerm(value: boolean): void {
    this.headOfficeAcceptedTerm = value;
  }

  isSkuControl(): boolean {
    return this.skuControl;
  }

  setSkuControl(value: boolean): void {
    this.skuControl = value;
  }
}

export class UnknowNetwork extends Network {
  isUnknowNetwork(): boolean {
    return true;
  }
  getAddress(): Address {
    return new Address();
  }
  getNetworkInfoKey(): string {
    return "";
  }
  getFiscalNumber(): FiscalNumber {
    return new FiscalNumber(null);
  }
  getCorporateName(): string {
    return "";
  }
  getTradingName(): string {
    return "";
  }
}
