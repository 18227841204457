import { NgModule } from "@angular/core";

import { CoreModule } from "../../core/core.module";
import { DirectiveModule } from "../../directives/directive.module";
import { DetailComponent } from "../components/detail/detail.component";
import { FilterComponent } from "../components/filter/filter.component";
import { GridComponent } from "../components/grid/grid.component";
import { LoadingPanelComponent } from "../components/loading-panel/loading-panel.component";
import { ModalComponent } from "../components/modal/modal.component";
import { CompleteTermDialogComponent } from "../pages/acceptance-term/complete-term-dialog/complete-term-dialog.component";
import { PipeModule } from "../pipes/pipe.module";
import { AlertComponent } from "./alert/alert.component";
import { ChangePasswordDialogComponent } from "./change-password-dialog/change-password-dialog.component";
import { ConfirmDialog } from "./dialog/confirm-dialog/confirm-dialog.component";
import { DialogModule } from "./dialog/dialog.module";
import { InputDialogComponent } from "./dialog/input-dialog/input-dialog.component";
import { MessageDialog } from "./dialog/message-dialog/message-dialog.component";
import { DynamicComponentComponent } from "./dynamic-component/dynamic-component.component";
import { GridExpandableComponent } from "./grid-expandable/grid-expandable.component";
import { HeaderComponent } from "./header/header.component";
import { NavBarModule } from "./header/nav-bar/navbar.module";
import { InputErrorComponent } from "./input-error/input-error.component";
import { InputMessageComponent } from "./input-message/input-message.component";
import { NewsPanelComponent } from "./news-panel/news-panel.component";
import { BeneficiaryHeaderComponent } from "./pdv/beneficiary-header/beneficiary-header.component";
import { BeneficiarySummaryComponent } from "./pdv/beneficiary-header/beneficiary-summary/beneficiary-summary.component";
import { SearchBeneficiaryBarComponent } from "./pdv/beneficiary-header/search-beneficiary-bar/search-beneficiary-bar.component";
import { SelectorCompanyBenefitDialog } from "./pdv/beneficiary-header/selector-company-benefit-dialog/selector-company-benefit-dialog.component";
import { FinancialStatementResultComponent } from "./pdv/financial-statement/financial-statement-result/financial-statement-result.component";
import { FinancialStatementSearchBarComponent } from "./pdv/financial-statement/financial-statement-search-bar/financial-statement-search-bar.component";
import { FinancialStatementComponent } from "./pdv/financial-statement/financial-statement.component";
import { FooterComponent } from "./pdv/footer/footer.component";
import { HeaderPdvComponent } from "./pdv/header/header.component";
import { LoadingComponent } from "./pdv/loading/loading.component";
import { PreSalesContentComponent } from "./pdv/pre-sales-content/pre-sales-content.component";
import { AddFooterComponent } from "./pdv/purchase-content/add-footer/add-footer.component";
import { ProductBasketComponent } from "./pdv/purchase-content/product-basket/product-basket.component";
import { ProductListComponent } from "./pdv/purchase-content/product-basket/product-list/product-list.component";
import { PurchaseContentComponent } from "./pdv/purchase-content/purchase-content.component";
import { SearchProductBar } from "./pdv/purchase-content/search-product-bar/search-product-bar.component";
import { MedicalPrescriptionDialogComponent } from "./pdv/purchase-preview-content/medical-prescription-dialog/medical-prescription-dialog.component";
import { PurchasePreviewContentComponent } from "./pdv/purchase-preview-content/purchase-preview-content.component";
import { RefundedDialog } from "./pdv/sales-consult/refund-dialog/refunded-dialog.component";
import { SaleDetailsComponent } from "./pdv/sales-consult/sale-details/sale-details.component";
import { SalesConsultComponent } from "./pdv/sales-consult/sales-consult.component";
import { SalesResultComponent } from "./pdv/sales-consult/sales-result/sales-result.component";
import { SalesSearchBarComponent } from "./pdv/sales-consult/sales-search-bar/sales-search-bar.component";
import { AcceptanceTermFormComponent } from "./pdv/terms/acceptance-term-form/acceptance-term-form.component";
import { EditTermModalComponent } from "./pdv/terms/edit-term-modal/edit-term-modal.component";
import { TermFileInputComponent } from "./pdv/terms/term-file-input/term-file-input.component";
import { ToastModule } from "./toast/toast.module";
import { FlickityModule } from 'ngx-flickity';

@NgModule({
  declarations: [
    LoadingPanelComponent,
    GridComponent,
    FilterComponent,
    ModalComponent,
    DetailComponent,
    GridExpandableComponent,
    AlertComponent,
    InputErrorComponent,
    InputMessageComponent,
    ChangePasswordDialogComponent,
    InputMessageComponent,
    EditTermModalComponent,
    AcceptanceTermFormComponent,
    TermFileInputComponent,
    BeneficiaryHeaderComponent,
    BeneficiarySummaryComponent,
    SearchBeneficiaryBarComponent,
    PurchaseContentComponent,
    ProductBasketComponent,
    ProductListComponent,
    PurchasePreviewContentComponent,
    AddFooterComponent,
    SearchProductBar,
    MedicalPrescriptionDialogComponent,
    CompleteTermDialogComponent,
    PreSalesContentComponent,
    HeaderPdvComponent,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    DynamicComponentComponent,
    ConfirmDialog,
    SalesSearchBarComponent,
    SalesConsultComponent,
    SalesResultComponent,
    SaleDetailsComponent,
    RefundedDialog,
    SelectorCompanyBenefitDialog,
    InputDialogComponent,
    MessageDialog,
    FinancialStatementComponent,
    FinancialStatementResultComponent,
    FinancialStatementSearchBarComponent,
  ],
  imports: [CoreModule, PipeModule.forRoot(), DirectiveModule.forRoot(), DialogModule, ToastModule, NavBarModule, FlickityModule],
  exports: [
    LoadingPanelComponent,
    GridComponent,
    FilterComponent,
    ModalComponent,
    DetailComponent,
    NewsPanelComponent,
    GridExpandableComponent,
    DialogModule,
    AlertComponent,
    InputErrorComponent,
    InputMessageComponent,
    EditTermModalComponent,
    AcceptanceTermFormComponent,
    BeneficiaryHeaderComponent,
    BeneficiarySummaryComponent,
    SearchBeneficiaryBarComponent,
    PurchaseContentComponent,
    ProductBasketComponent,
    ProductListComponent,
    PurchasePreviewContentComponent,
    AddFooterComponent,
    SearchProductBar,
    MedicalPrescriptionDialogComponent,
    CompleteTermDialogComponent,
    PreSalesContentComponent,
    HeaderPdvComponent,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    ConfirmDialog,
    SalesSearchBarComponent,
    SalesConsultComponent,
    SalesResultComponent,
    SaleDetailsComponent,
    RefundedDialog,
    SelectorCompanyBenefitDialog,
    InputDialogComponent,
    MessageDialog,
    FinancialStatementComponent,
    FinancialStatementResultComponent,
    FinancialStatementSearchBarComponent,
  ],
  entryComponents: [
    LoadingPanelComponent,
    ModalComponent,
    ChangePasswordDialogComponent,
    EditTermModalComponent,
    MedicalPrescriptionDialogComponent,
    CompleteTermDialogComponent,
    DynamicComponentComponent,
    ConfirmDialog,
    RefundedDialog,
    SelectorCompanyBenefitDialog,
    InputDialogComponent,
    MessageDialog,
  ],
})
export class ComponentModule {}
