export class FinancialBalance {
  private available: number;
  private cycleClosedDay: string;
  private limit: number;
  private payroll: number;
  private sales: number;
  private subsidy: number;
  private unlimited: boolean;

  setAvailable(value: number): void {
    this.available = value;
  }

  getAvailable(): number {
    return this.available;
  }

  setCycleClosedDay(value: string): void {
    this.cycleClosedDay = value;
  }

  getCycleClosedDay(): string {
    return this.cycleClosedDay;
  }

  setLimit(value: number): void {
    this.limit = value;
  }

  getLimit(): number {
    return this.limit;
  }

  setPayroll(value: number): void {
    this.payroll = value;
  }

  getPayroll(): number {
    return this.payroll;
  }

  setSales(value: number): void {
    this.sales = value;
  }

  getSales(): number {
    return this.sales;
  }

  setSubsidy(value: number): void {
    this.subsidy = value;
  }

  getSubsidy(): number {
    return this.subsidy;
  }

  setUnlimited(value: boolean): void {
    this.unlimited = value;
  }

  getUnlimited(): boolean {
    return this.unlimited;
  }

  getFinancialBalanceView(): string {
    if (this.unlimited || this.available > 0) {
      return "Com Saldo";
    }
    return "Sem Saldo";
  }
}
