import { NetworkAcceptTerm } from "src/app/ui/models/domains/pdv/network-accept-term";
import { Term } from "src/app/ui/models/domains/pdv/term";
import { AbstractParser } from "../parser";

export class NetworkAcceptTermParser extends AbstractParser<NetworkAcceptTerm> {
  parse(payload: any): NetworkAcceptTerm {
    if (payload == null) {
      return null;
    }

    return new NetworkAcceptTerm(payload.ReferenceKey, new Date(payload.AcceptTermDate));
  }
}
