import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Url } from "../models/domains/urls/url";

export class RemoteGateway {
  constructor(private backendUrl: string, private httpClient: HttpClient) {}

  postWithHeaders(url: Url, payload: any, headers: any) {
    //apenas para dados mockados
    /*if (url.getUrl().includes("/adm/")) {
      headers = {
        ...headers,
        "pbm-tenant-key": "A23DD5D11CFF4D95934DCCF118FEDD36",
        loginInfo:
          "{'accessToken':'d5ag2pZBoCa2COM2K9s1vRZeO1Pn40806BZJbtceGS6eJBKoWXm2hpoMH3jwUkxMP0fvPblpQnzS8guQZwWvsPzxyd48RlKiXF0SApzg1VpAp-ZoMopANDCVvOKkurJpRvjL8llB65rIN5TMYUBmiiMxfln0BRHAkg_4vguyFZKmdBrs03fMbKHsi98TPszs3Y2tFm7R8OOStvpG1uyC41D5hCMuosMHMHG2KpX_irfEx3evHI_A18JviCBYD_XIL-16hp0dfJMIaamFjvc3EcDmgDQ9-KV3ACrBC5FgWN1-AYFWpfyL4E2Gj6t_sMiP5sKfy3X0xu6BBa3300gGWStxq3ySLYCuA-cXmsZ7ZP0','tokenType':'bearer','expiresIn':1799,'refreshToken':'2b24831e713c4049bfcb90e252dd8caa','userId':'538110e8-ee0f-42cb-b690-8d64b96fb2f5','userName':'pbm_dev@email.com'}",
      };
    }*/

    return new Promise((resolve, reject) => {
      this.httpClient
        .post(url.getUrl(), payload, { headers })
        .toPromise()
        .then((r) => {
          resolve(r);
        })
        .catch((response: HttpErrorResponse) => {
          reject(response);
        });
    });
  }

  download(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(this.buildUrl(url), { responseType: "blob" as "json" })
        .toPromise()
        .then((r) => {
          resolve(r);
        })
        .catch((response: HttpErrorResponse) => {
          reject(response);
        });
    });
  }

  downloadAsPost(url: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(this.buildUrl(url), payload, { responseType: "blob" })
        .toPromise()
        .then((r) => {
          resolve(r);
        })
        .catch((response: HttpErrorResponse) => {
          reject(response);
        });
    });
  }

  downloadAsGet(url: Url, headers: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(url.getUrl(), { headers, responseType: "blob" as "json" })
        .toPromise()
        .then((r) => {
          resolve(r);
        })
        .catch((response: HttpErrorResponse) => {
          reject(response);
        });
    });
  }

  get(url: Url): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(url.getUrl())
        .toPromise()
        .then((r) => {
          resolve(r);
        })
        .catch((response: HttpErrorResponse) => {
          reject(response);
        });
    });
  }

  getWithHeaders(url: Url, headers: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(url.getUrl(), { headers })
        .toPromise()
        .then((r) => {
          resolve(r);
        })
        .catch((response: HttpErrorResponse) => {
          reject(response);
        });
    });
  }

  post(url: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(this.buildUrl(url), payload)
        .toPromise()
        .then((r) => {
          resolve(r);
        })
        .catch((response: HttpErrorResponse) => {
          reject(response);
        });
    });
  }

  put(url: string, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .put(this.buildUrl(url), payload)
        .toPromise()
        .then((r) => {
          resolve(r);
        })
        .catch((response: HttpErrorResponse) => {
          reject(response);
        });
    });
  }

  delete(url: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .delete(this.buildUrl(url))
        .toPromise()
        .then((r) => {
          resolve(r);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }

  private buildUrl(action: string) {
    if (!!action && action.charAt(0) !== "/") {
      action = `/${action}`;
    }
    return `${this.backendUrl}${action}`;
  }
}
