import { Component, OnInit } from "@angular/core";
import { SalesOrderResult } from "src/app/ui/models/domains/pdv/sales-order-result";
@Component({
  selector: "pdv-sales-consult",
  templateUrl: "./sales-consult.component.html",
  styleUrls: ["./sales-consult.component.scss"],
})
export class SalesConsultComponent implements OnInit {
  selectedSale: SalesOrderResult;
  constructor() {}

  ngOnInit(): void {}
}
