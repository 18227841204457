import { Pipe, PipeTransform, LOCALE_ID, Inject } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({
  name: "currencyformat",
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  transform(value: number, currencyCode: string = "BRL", symbolDisplay?: boolean, digits?: string): string {
    if (!value) {
      return "";
    }
    let currencyPipe: CurrencyPipe = new CurrencyPipe(this.localeId);
    let newValue: string = currencyPipe.transform(value, currencyCode, symbolDisplay, digits);
    return newValue;
  }
}
