import { SummaryFooter } from "./summary-footer";

export class PageableResult {
  private start: number = 1;
  private end: number = 10;
  private totalItems: number;
  private items: Array<Object>;
  private summaryFooter: SummaryFooter;

  constructor() {
    this.items = [];
  }

  getItems(): Array<Object> {
    return this.items;
  }

  setItems(items: Array<Object>): void {
    this.items = items;
  }

  setEnd(end: number): void {
    this.end = end;
  }

  setStart(start: number): void {
    this.start = start;
  }

  setTotalItems(totalItems: number): void {
    this.totalItems = totalItems;
  }

  setSummaryFooter(summaryFooter: SummaryFooter): void {
    this.summaryFooter = summaryFooter;
  }

  filterByPageNumber(pageNumber: number) {
    var itemsPerPage = this.end - this.start + 1;
    this.start = pageNumber * itemsPerPage - (itemsPerPage - 1);
    this.end = pageNumber * itemsPerPage;
    this.items = this.items.slice(this.start - 1, this.end);
  }

  filterByItemsPerPage(itemsPerPage: number) {
    this.start = 1;
    this.end = itemsPerPage;
    this.items = this.items.slice(0, itemsPerPage);
  }

  getItemsPerPage(): number {
    return this.end - this.start + 1;
    //return (this.end - this.start);
  }

  getTotalPages(): number {
    return Math.ceil(this.totalItems / (this.end + 1 - this.start));
  }

  getPageNumbers(): Array<number> {
    let pageNumbers = new Array<number>();
    for (let i = 0; i < this.getTotalPages(); i++) {
      pageNumbers.push(i + 1);
    }
    return pageNumbers;
  }

  getTotalItems(): number {
    return this.totalItems;
  }

  getStart(): number {
    return this.start;
  }

  getEnd(): number {
    return this.end;
  }

  getSummaryFooter(): SummaryFooter {
    return this.summaryFooter;
  }
}
