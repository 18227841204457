import { FiscalNumber } from "../fiscal-number";
import { IBlockItem } from "../interfaces/iblock-list-item";
import { ProductClusterExceptInfo } from "./product-cluster-except-info";
import { ProductConfiguration } from "./product-configuration";

export class ProductConfigurationCluster {
  private key: string;
  private companyFiscalNumber: FiscalNumber;
  private productMaxCount: number;
  private productConfiguration: Array<ProductConfiguration>;
  private productClusterExceptInfo: ProductClusterExceptInfo;
  private blockListConfiguration: Array<IBlockItem>;

  setKey(key: string): void {
    this.key = key;
  }

  getKey(): string {
    return this.key;
  }

  setCompanyFiscalNumber(fiscalNumber: FiscalNumber): void {
    this.companyFiscalNumber = fiscalNumber;
  }

  getCompanyFiscalNumber(): FiscalNumber {
    return this.companyFiscalNumber;
  }

  setProductConfiguration(configuration: Array<ProductConfiguration>): void {
    this.productConfiguration = configuration;
  }

  getProductConfiguration(): Array<ProductConfiguration> {
    return this.productConfiguration;
  }

  setProductClusterExceptInfo(value: ProductClusterExceptInfo): void {
    this.productClusterExceptInfo = value;
  }

  getProductClusterExceptInfo(): ProductClusterExceptInfo {
    return this.productClusterExceptInfo;
  }

  setProductMaxCount(value: number): void {
    this.productMaxCount = value;
  }

  getProductMaxCount(): number {
    return this.productMaxCount;
  }

  setBlockListConfiguration(value: Array<IBlockItem>): void {
    this.blockListConfiguration = value;
  }

  getBlockListConfiguration(): Array<IBlockItem> {
    return this.blockListConfiguration;
  }

  isNew(): boolean {
    return !this.key;
  }
}
