export class ProductCategory {
  private key: string;
  private name: string;
  private productTypeKey: string;
  private productSubtypeKey: string;
  private discount: number;

  setKey(key: string): void {
    this.key = key;
  }

  getKey(): string {
    return this.key;
  }

  setName(name: string): void {
    this.name = name;
  }

  getName(): string {
    return this.name;
  }

  setProductTypeKey(typeKey: string): void {
    this.productTypeKey = typeKey;
  }

  getProductTypeKey(): string {
    return this.productTypeKey;
  }

  setProductSubtypeKey(subtypeKey: string): void {
    this.productSubtypeKey = subtypeKey;
  }

  getProductSubTypeKey(): string {
    return this.productSubtypeKey;
  }

  setDiscount(discount: number): void {
    this.discount = discount;
  }

  getDiscount(): number {
    return this.discount;
  }
}
