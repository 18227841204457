import { NgModule, LOCALE_ID, ErrorHandler } from "@angular/core";
import { ModuleWithProviders } from "@angular/compiler/src/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { GlobalHttpExceptionHandller } from "../gateways/global-http-exception-handller";
import { MatDialogRef, MatPaginatorIntl } from "@angular/material";
import { TOAST_CONFIG_TOKEN, ToastConfig, toastConfig } from "../models/domains/toast/toast-config";
import { RequestInterceptor } from "../gateways/request-http-interceptor";
import { CustomPaginator } from "../models/domains/grids/custom-paginator";
import { ErrorInterceptor } from "../services/interceptors/error/error.interceptor";
import { GlobalErrorHandler } from "../services/interceptors/error/global-error.handler";

@NgModule({})
export class ProviderModule {
  static forRoot(config: ToastConfig = toastConfig): ModuleWithProviders {
    return {
      ngModule: ProviderModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        { provide: MatDialogRef, useValue: {} },
        { provide: LOCALE_ID, useValue: "pt-BR" },
        { provide: TOAST_CONFIG_TOKEN, useValue: config },
        { provide: MatPaginatorIntl, useClass: CustomPaginator },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
          multi: true,
        },
        {
          provide: ErrorHandler,
          useClass: GlobalErrorHandler,
        },
      ],
    };
  }
}
