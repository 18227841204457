import { EventEmitter, Inject } from "@angular/core";
import { Component } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  templateUrl: "./refunded-dialog.component.html",
  styleUrls: ["./refunded-dialog.component.scss"],
})
export class RefundedDialog {
  public refundedDialogEvent = new EventEmitter();
  public cancelDialogEvent = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<RefundedDialog>, @Inject(MAT_DIALOG_DATA) public data: IRefundedDialogData) {}

  public getNsu(): string {
    return this.data.nsu;
  }

  public getRefundValue(): number {
    return this.data.refundValue;
  }

  public closeDialog(): void {
    this.cancelDialogEvent.emit();
    this.dialogRef.close();
  }

  public emitRefundedEvent() {
    this.refundedDialogEvent.emit();
    this.dialogRef.close();
  }

  getReuseItems(): boolean {
    return this.data.reuseItems;
  }
}

export interface IRefundedDialogData {
  nsu: string;
  refundValue: number;
  reuseItems: boolean;
}
