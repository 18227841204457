import { PdvPassport } from "./pdv-passport";

export class PdvPassportFactory {
  static createPdvPassport(payload: any): PdvPassport {
    const pdvPassport = new PdvPassport();
    pdvPassport.setClientId(payload.clientId);
    pdvPassport.setNetworkFiscalNumber(payload.networkFiscalNumber);
    pdvPassport.setOperatorUserName(payload.operatorUserName);
    pdvPassport.setToken(payload.token);
    return pdvPassport;
  }
}
