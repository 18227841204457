import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AppContext } from "src/app/ui/contexts/app-context";
import { RouteStrategyFactory } from "src/app/ui/gateways/route-strategy-factory";
import { ServiceCredentials } from "src/app/ui/models/domains/credentials/service-credentials.model";
import { AccessErrorCause } from "src/app/ui/models/domains/pdv/enum/access-error-type.enum";
import { PdvPassport } from "src/app/ui/models/domains/pdv/pdv-passport";
import { RoutePath } from "src/app/ui/models/domains/route-paths.model";
import { environment } from "src/environments/environment";
import { AuthService } from "../../auth.service";
import { IAuthProcessor } from "./auth-processor";

export class AcceptanceTermAuthProcessor implements IAuthProcessor {
  private grantType = environment.grantType;

  constructor(private authService: AuthService, private appContext: AppContext, private routeStrategyFactory: RouteStrategyFactory, private router: Router) {
    this.appContext.cleanAppContext();
  }

  async processAuthentication(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (route.queryParams) {
      const token = route.queryParams["t"];

      if (token) {
        const authServiceAuthenticated = await this.authService.isServiceTokenValid(token);

        if (authServiceAuthenticated) {
          this.appContext.setServiceCredential(new ServiceCredentials(token));

          const networkFiscalNumber = route.queryParams["f"];
          if (!networkFiscalNumber) {
            this.routeStrategyFactory.createStrategy(AccessErrorCause.MissingNetworkFiscalNumber).redirect();
            return false;
          }

          const clientId = route.queryParams["c"];
          if (!clientId) {
            this.routeStrategyFactory.createStrategy(AccessErrorCause.MissingClientId).redirect();
            return false;
          }

          const userName = route.queryParams["u"];
          if (!userName) {
            this.routeStrategyFactory.createStrategy(AccessErrorCause.MissingUserName).redirect();
            return false;
          }

          const pdvPassport = new PdvPassport();
          pdvPassport.setToken(token);
          pdvPassport.setNetworkFiscalNumber(networkFiscalNumber);
          pdvPassport.setClientId(clientId);
          pdvPassport.setOperatorUserName(userName);
          pdvPassport.setGrantType(this.grantType);

          try {
            const network = await this.authService.authenticatePdv(pdvPassport);
            if (pdvPassport.isGrantTypeClientCredentials()) {
              if (!network || !network.isHeadOfficeAcceptedTerm()) {
                this.router.navigate([RoutePath.NotAcceptedTerm]);
              } else {
                this.router.navigate([RoutePath.Home], {
                  queryParams: {
                    t: pdvPassport.getToken(),
                    f: pdvPassport.getNetworkFiscalNumber(),
                    c: pdvPassport.getClientId(),
                    u: pdvPassport.getOperatorUserName(),
                  },
                });
              }
              return false;
            } else if (pdvPassport.isGrantTypeAcceptTerm()) {
              if (network.isHeadOfficeAcceptedTerm()) {
                window.open(`${environment.redirectUrl}/${RoutePath.Home}?${pdvPassport.getUrlParameters()}`, "_self");
                return false;
              }
              if (!network.isHeadOffice() && !network.hasHeadOffice()) {
                this.router.navigate([RoutePath.BranchHasNoHeadOffice]);
                return false;
              }
              if (!network.isHeadOffice() && !network.isHeadOfficeAcceptedTerm()) {
                this.router.navigate([RoutePath.BranchHasNoHeadOfficeAcceptedTerm]);
                return false;
              }
            }
          } catch (reponseError) {
            if (this.isNetworkNotRegister(reponseError)) {
              this.router.navigate([RoutePath.NotRegistered]);
            } else {
              this.routeStrategyFactory.createStrategy(AccessErrorCause.AccessingNetworkTerm).redirect();
            }
            return false;
          }
        } else {
          this.routeStrategyFactory.createStrategy(AccessErrorCause.UnauthorizedToken).redirect();
          return false;
        }
        return authServiceAuthenticated;
      } else {
        this.routeStrategyFactory.createStrategy(AccessErrorCause.MissingToken).redirect();
        return false;
      }
    }
    if (!this.authService.isServiceLogged()) {
      this.routeStrategyFactory.createStrategy().redirect();
      return false;
    }
    return true;
  }

  isNetworkNotRegister(reponseError: any): boolean {
    return reponseError.error.Data.ErrorCode === "MDM.Network.NotRegistered";
  }
}
