import { Injectable } from "@angular/core";
import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { PageableResult } from "../../models/domains/paginator/pageable-result";
import { ExportOrderKey } from "../../models/domains/pdv/exports/export-order-key";
import { FinancialStatementPagingRequest } from "../../models/domains/pdv/financial-statement-paging-request";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { NetworkParser } from "../parsers/pdv/network.parser";
import { SearchFinancialStatementsParse } from "../parsers/pdv/search-financial-statement.parse";

@Injectable()
export class NetworkRepository {
  private headers: any;
  private fiscalNumberRootHeader: any;

  constructor(private remoteGatewayFactory: RemoteGatewayFactory, private networkParser: NetworkParser, private searchFinancialStatementsParse: SearchFinancialStatementsParse) {
    this.headers = {
      "Content-Type": "application/json",
    };
    this.fiscalNumberRootHeader = {
      "Content-Type": "application/json",
      //"X-NetworkFiscalNumberRoot-Filter": this.userRoleMediator.getCompanyFiscalNumberRoot(),
    };
  }

  public async findExportOrderKey(fiscalNumber: string, startAt: string, endAt: string): Promise<ExportOrderKey> {
    const body = JSON.stringify({
      FiscalNumber: {
        Value: fiscalNumber,
      },
      StartAt: startAt,
      EndAt: endAt,
    });
    try {
      const data = await this.remoteGatewayFactory
        .createDefaultRemoteGateway()
        .postWithHeaders(new BackendUrl("/api/pbm/drugstore/network/financial-statement"), body, this.headers);
      return new ExportOrderKey(data.toString());
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async searchFinancialStatements(pagingRequest: FinancialStatementPagingRequest): Promise<PageableResult> {
    const body = {
      FilterText: pagingRequest.getFiscalNumber(),
      ReferenceYear: pagingRequest.getReferenceYear(),
      ReferenceMonth: pagingRequest.getReferenceMonth(),
      StartIndex: pagingRequest.getStart(),
      TotalPerPages: pagingRequest.getEnd(),
    };
    try {
      const data = await this.remoteGatewayFactory
        .createDefaultRemoteGateway()
        .postWithHeaders(new BackendUrl("/api/pbm/drugstore/network/financial-statements"), body, this.fiscalNumberRootHeader);
      return this.searchFinancialStatementsParse.parse(data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
