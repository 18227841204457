import { Injectable } from "@angular/core";
import { LoadingOverlayRef } from "../../loading/loading-overlay-ref";
import { IFileExportOrderRequest } from "./file-export-order-request";
import { IFileFileExportResult } from "./file-export-result";

@Injectable()
export class FileExportProcessor {
  private static readonly DefaultIntervalInMillis: number = 5000;

  private readonly intervalInMillis: number = FileExportProcessor.DefaultIntervalInMillis;

  constructor() {}

  public async processFile(fileExportOrderRequest: IFileExportOrderRequest, loadingRef: LoadingOverlayRef): Promise<void> {
    if (!fileExportOrderRequest) {
      throw "File export order request cant be null";
    }
    const orderKey = await fileExportOrderRequest.orderExport();
    if (!!orderKey) {
      const intervalHandle = setInterval(async () => {
        try {
          if (await fileExportOrderRequest.isFileExportReady(orderKey)) {
            const fileExportResult: IFileFileExportResult = await fileExportOrderRequest.downloadFile(orderKey);
            if (!!fileExportResult) {
              const name = fileExportOrderRequest.getFileExportName();
              fileExportResult.notifyDownload(name);
              clearInterval(intervalHandle);
              loadingRef.closeLoading();
            }
          }
        } catch (error) {
          fileExportOrderRequest.showError(error);
          loadingRef.closeLoading();
        }
      }, this.intervalInMillis);
    }
  }
}
