import { Validators, FormGroup } from "@angular/forms";
import { FormValidator } from "../models/domains/validators/form-validator";

export class ValidationUtils {
  static bindValidations(validations: Array<FormValidator>) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach((valid) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });

    formGroup.markAsTouched({ onlySelf: true });
  }
}
