import { NgModule, ModuleWithProviders } from "@angular/core";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatIconModule } from "@angular/material/icon";

import { ToastComponent } from "./toast.component";

import { FlexLayoutModule } from "@angular/flex-layout";
import { MatTooltipModule } from "@angular/material";
import { CommonModule } from "@angular/common";
import { Toast } from "./toast";

@NgModule({
  imports: [OverlayModule, MatIconModule, MatTooltipModule, FlexLayoutModule, CommonModule],
  declarations: [ToastComponent],
  entryComponents: [ToastComponent],
  providers: [Toast],
})
export class ToastModule {}
