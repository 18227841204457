import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { CustomInput } from "../../models/domains/inputs/input";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ValidationUtils } from "../../utils/validation-utils";
import { coerceBooleanProperty } from "@angular/cdk/coercion";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.scss"],
})
export class DetailComponent implements OnInit {
  private _showSaveFormButton: boolean;
  @Input() inputs: Array<CustomInput<Object>>;
  @Input()
  get showSaveFormButton() {
    return this._showSaveFormButton;
  }
  set showSaveFormButton(value: any) {
    this._showSaveFormButton = coerceBooleanProperty(value);
  }

  @Output() inputsOut: EventEmitter<CustomInput<Object>[]> = new EventEmitter();

  form: FormGroup;

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.inputs && this.inputs.length > 0) this.form = this.createControl();
  }

  getInputSize(customInput: CustomInput<Object>): string {
    return customInput.getSize() || "100%";
  }

  isFormInvalid(customInput: CustomInput<Object>): boolean {
    return this.form.get(customInput.propertyName).invalid && this.form.get(customInput.propertyName).touched;
  }

  getErrorMessage(customInput: CustomInput<Object>): string {
    let errorMessage = "";
    customInput.validations.forEach((validation) => {
      if (this.showErrorField(customInput.propertyName, validation.name)) {
        errorMessage = validation.message;
        return;
      }
    });

    return errorMessage;
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      this.inputsOut.emit(this.createInputOut());
    } else {
      ValidationUtils.validateAllFormFields(this.form);
    }
  }

  hasSaveFormButton(): boolean {
    return this.showSaveFormButton == true;
  }

  private createControl(): FormGroup {
    const group = this.fb.group({});
    this.inputs.forEach((customInput) => {
      const control = this.fb.control({ value: customInput.value, disabled: customInput.isInputDisabled() }, ValidationUtils.bindValidations(customInput.validations || []));
      group.addControl(customInput.propertyName, control);
    });

    this.cdr.detectChanges();

    return group;
  }

  public createInputOut(): CustomInput<Object>[] {
    this.inputs.forEach((i, index) => {
      i.value = Object.entries(this.form.value)[index][1];
    });
    return this.inputs;
  }

  private showErrorField(fiedName: string, validationName: string): boolean {
    return this.form.get(fiedName).hasError(validationName);
  }
}
