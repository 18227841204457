import { Injectable } from "@angular/core";
import { BenefitConfiguration } from "src/app/ui/models/domains/pdv/Benefit/benefit-configuration";
import { FiscalNumber } from "src/app/ui/models/domains/pdv/fiscal-number";
import { AbstractParser } from "../parser";

@Injectable()
export class BenefitConfigurationParser extends AbstractParser<BenefitConfiguration> {
  parse(payload: any): BenefitConfiguration {
    const benefitConfiguration = new BenefitConfiguration();
    benefitConfiguration.setId(payload.Id);
    benefitConfiguration.setCompanyFiscalNumber(new FiscalNumber(payload.CompanyFiscalNumber));
    return benefitConfiguration;
  }
}
