import { Component, OnInit } from "@angular/core";
import { SalesOrderResult } from "src/app/ui/models/domains/pdv/sales-order-result";
import { SalesService } from "src/app/ui/services/pdv/sales.service";

@Component({
  selector: "pdv-sales-result",
  templateUrl: "./sales-result.component.html",
  styleUrls: ["./sales-result.component.scss"],
})
export class SalesResultComponent implements OnInit {
  constructor(private saleService: SalesService) {}
  ngOnInit(): void {}

  getSalesFoundList(): Array<SalesOrderResult> {
    return this.saleService.getSalesFoundList();
  }

  async selectSale(sale: SalesOrderResult) {
    if (this.isSelected(sale)) {
      this.saleService.setSelectedSale(undefined);
    } else {
      this.saleService.setSelectedSale(sale);
    }
  }

  isSelected(sale: SalesOrderResult): boolean {
    return sale == this.saleService.getSelectedSale();
  }
}
