import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, isDevMode } from '@angular/core';
import { ErrorService } from './error.service';
import { ToastService } from '../../toast.service';
import { LocationStrategy } from '@angular/common';
import { environment } from '../../../../../environments/environment';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // private logger: Logger;

  constructor(private injector: Injector,
    private errorService: ErrorService,
  ) {
  }

  handleError(error: Error | HttpErrorResponse) {
    let message: string;

    
    if (error instanceof HttpErrorResponse) {
      // Server error
      message = this.errorService.getServerMessage(error);
    } else {
      // Client Error
      message = this.errorService.getClientMessage(error);
    }

    if (isDevMode()) {
      this.stackTrace(message, error);
    }
  }

  stackTrace(errorMessage: string, error: Error | HttpErrorResponse) {    
    const location = this.injector.get(LocationStrategy);
   
    console.error(`Caught global error: [${errorMessage}], url: [${location.path()}]`, error);
  
  }
}