import { Injectable } from "@angular/core";
import { AppContext } from "../../contexts/app-context";
import { PageableResult } from "../../models/domains/paginator/pageable-result";
import { ExportOrderKey } from "../../models/domains/pdv/exports/export-order-key";
import { FinancialStatementPagingRequest } from "../../models/domains/pdv/financial-statement-paging-request";
import { Network } from "../../models/domains/pdv/network";
import { NetworkRepository } from "../../repositories/pdv/network.repository";

@Injectable({ providedIn: "root" })
export class NetworkService {
  private financialStatementsPageableResult: PageableResult = new PageableResult();

  constructor(private appContext: AppContext, private networkRepository: NetworkRepository) {}

  getNetwork(): Network {
    return this.appContext.getNetwork();
  }

  public async findExportOrderKey(fiscalNumber: string, startAt: string, endAt: string): Promise<ExportOrderKey> {
    return this.networkRepository.findExportOrderKey(fiscalNumber, startAt, endAt);
  }

  public async searchFinancialStatements(pagingRequest: FinancialStatementPagingRequest): Promise<PageableResult> {
    this.financialStatementsPageableResult = new PageableResult();
    try {
      this.financialStatementsPageableResult = await this.networkRepository.searchFinancialStatements(pagingRequest);
      Promise.resolve(true);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public getFinancialStatementsPageableResult(): PageableResult {
    return this.financialStatementsPageableResult;
  }

  public setFinancialStatementsPageableResult(FinancialStatementsPageableResult: PageableResult): void {
    this.financialStatementsPageableResult = FinancialStatementsPageableResult;
  }

  clearFinancialStatementsPageableResult() {
    this.financialStatementsPageableResult = new PageableResult();
  }
}
