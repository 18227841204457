import { BlockItemType } from "../enum/block-item-type.enum";
import { IBlockItem } from "../interfaces/iblock-list-item";

export class TherapeuticClassBlockItem implements IBlockItem {
  readonly blockListItemType: BlockItemType;
  private therapeuticClassKey: string;
  private description: string;
  private code: string;
  private displayValue: string;

  constructor() {
    this.blockListItemType = BlockItemType.TherapeuticClass;
  }

  hasBlockItemBy(blockItemType: BlockItemType): boolean {
    return this.blockListItemType === blockItemType;
  }

  setDisplayValue(value: string): void {
    this.displayValue = value;
  }

  getDisplayValue(): string {
    return this.displayValue;
  }

  getKey(): string {
    return this.getTherapeuticClassKey();
  }

  getBlockListItemType(): BlockItemType {
    return this.blockListItemType;
  }

  setTherapeuticClassKey(value: string): void {
    this.therapeuticClassKey = value;
  }

  getTherapeuticClassKey(): string {
    return this.therapeuticClassKey;
  }

  setDescription(value: string): void {
    this.description = value;
  }

  getDescription(): string {
    return this.description;
  }

  setCode(value: string): void {
    this.code = value;
  }

  getCode(): string {
    return this.code;
  }
}
