import { Component, OnInit, ElementRef, Input } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "../../services/auth.service";
import { AppContext } from "../../contexts/app-context";
import { RouteStrategyFactory } from "../../gateways/route-strategy-factory";
import { ChangePasswordDialogComponent } from "../change-password-dialog/change-password-dialog.component";
import { UserCredentials } from "../../models/domains/credentials/user-credentials.model";
import { DialogService } from "../../services/dialog.service";
import { Router } from "@angular/router";
import { RoutePath } from "../../models/domains/route-paths.model";

@Component({
  selector: "header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() headerTitle: string;
  userCredentials: UserCredentials;

  constructor(
    private dialog: DialogService,
    private authService: AuthService,
    public el: ElementRef,
    private context: AppContext,
    private routeStrategyFactory: RouteStrategyFactory,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.userCredentials = this.context.getUserCredential();
  }

  getAppName(): string {
    return environment.appName;
  }

  getTitle(): string {
    return this.headerTitle;
  }

  getVersion(): string {
    return environment.version;
  }

  displayLogoutOption(): Boolean {
    return this.authService.isUserLogged();
  }

  logOut(): void {
    this.authService.logout();
    this.routeStrategyFactory.createStrategy().redirect();
  }

  changePassword(): void {
    this.dialog.open(ChangePasswordDialogComponent, {
      width: "350px",
      disableClose: true,
    });
  }

  healthCheck(): void {
    this.router.navigate([RoutePath.HealthCheck]);
  }
}
