import { FiscalNumber } from "src/app/ui/models/domains/pdv/fiscal-number";
import { Network } from "src/app/ui/models/domains/pdv/network";
import { NetworkAcceptTerm } from "src/app/ui/models/domains/pdv/network-accept-term";
import { AbstractParser } from "../parser";

export class NetworkParser extends AbstractParser<Network> {
  parse(payload: any) {
    const network = new Network();
    network.setNetworkInfoKey(payload.NetworkInfo.NetworkInfoKey);
    network.setFiscalNumber(new FiscalNumber(payload.Company.FiscalNumber.Value));
    network.setCorporateName(payload.Company.CorporateName);
    network.setTradingName(payload.Company.TradingName);
    if (payload.AcceptTerm !== null) {
      network.setAcceptedTerm(this.acceptTermParser(payload.AcceptTerm));
    }
    network.setHeadOfficeExists(payload.Company.HasHeadOffice);
    network.setHeadOffice(payload.Company.HeadOffice);
    network.setHeadOfficeAcceptedTerm(payload.Company.HeadOfficeAcceptedTerm);
    return network;
  }

  private acceptTermParser(payload: any): NetworkAcceptTerm {
    return new NetworkAcceptTerm(payload.ReferenceKey, payload.AcceptTermDate);
  }
}
