import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "cardNumberPipe" })
export class CardNumberPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    if (value) {
      return value.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/g, "$1 **** **** $4");
    } else {
      return "";
    }
  }
}
