export class Card {
  private cardNumber: string;

  setCardNumber(value: string): void {
    this.cardNumber = value;
  }

  getCardNumber(): string {
    return this.cardNumber;
  }
}
