import { Injectable } from "@angular/core";
import { AbstractParser } from "../parser";

@Injectable()
export class GetFileExportStatusParser extends AbstractParser<boolean> {
  parse(payload: any): boolean {
    const isFileExportReady: boolean = payload.Exported;
    return isFileExportReady;
  }

  parseNumber(payload: any): number {
    const isFileExportReady: boolean = payload.Exported;
    const hasError: boolean = payload.HasError;
    if (isFileExportReady) {
      return 1;
    } else if (hasError) {
      return -1;
    }
    return 0;
  }
}
