import { ComponentType, Overlay } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector, TemplatePortal } from "@angular/cdk/portal";
import { Injectable, InjectionToken, Injector, TemplateRef } from "@angular/core";

import { DialogConfig } from "../models/domains/dialog/dialog-config";
import { DialogRef } from "../components/dialog/dialog-ref";
import { DialogComponent } from "../components/dialog/dialog/dialog.component";

export const DIALOG_DATA = new InjectionToken("dialog.data");

const defaultConfig: DialogConfig = {
  backdropClass: "",
  disableClose: false,
  panelClass: "",
  width: "auto",
};

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  open<D = any>(componentOrTemplate: ComponentType<any> | TemplateRef<any>, config: Partial<DialogConfig> = {}): DialogRef<D> {
    const dialogConfig: DialogConfig = Object.assign({}, defaultConfig, config);
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      width: config.width,
      positionStrategy: this.overlay.position().global().centerVertically().centerHorizontally(),
    });

    const dialogRef = new DialogRef(overlayRef, dialogConfig);

    const dialog = overlayRef.attach(new ComponentPortal(DialogComponent, null, new PortalInjector(this.injector, new WeakMap<any, any>([[DialogRef, dialogRef]])))).instance;

    if (componentOrTemplate instanceof TemplateRef) {
      dialog.attachTemplatePortal(
        new TemplatePortal(componentOrTemplate, null, {
          $implicit: config.data,
          dialog: dialogRef,
        }),
      );
    } else {
      dialog.attachComponentPortal(
        new ComponentPortal(
          componentOrTemplate,
          null,
          new PortalInjector(
            this.injector,
            new WeakMap<any, any>([
              [DIALOG_DATA, config.data],
              [DialogRef, dialogRef],
            ]),
          ),
        ),
      );
    }

    return dialogRef;
  }
}
