export enum RoutePath {
  Default = "",
  AcceptanceTerm = "acceptance-term",
  Home = "home",
  Consult = "consult",
  FinancialStatement = "financial-statement",
  NotAcceptedTerm = "not-accepted-term",
  AccessError = "access-error",
  NotRegistered = "not-registered",
  BranchHasNoHeadOffice = "branch-has-no-head-office",
  BranchHasNoHeadOfficeAcceptedTerm = "branch-has-no-head-office-accepted-term",
  Login = "login",
  NotAuthorized = "not-authorized",
  Logs = "logs",
  HealthCheck = "health",
  ActiveUser = "active-user",
  RegisterPassword = "register-password",
}
