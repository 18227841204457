import { Injectable } from "@angular/core";
import { XLSXFileExportResult } from "src/app/ui/models/domains/pdv/exports/file-export-result";
import { AbstractParser } from "../parser";

@Injectable()
export class ExportFileAsXLSXParser extends AbstractParser<XLSXFileExportResult> {
  parse(payload: any): XLSXFileExportResult {
    const fileExportResult = new XLSXFileExportResult(payload);
    return fileExportResult;
  }
}
