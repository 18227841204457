import { MedicalPrescriptionDocumentKey } from "./medical-prescription-document-key";
import { PractitionerRegistration } from "./practitioner-registration";

export class MedicalPrescription {
  private quantity: number = 0;
  private medicalPrescriptionDate: string = "";
  private practitionerRegistration = new PractitionerRegistration();
  private medicalPrescriptionDocumentKey = new MedicalPrescriptionDocumentKey();

  public setQuantity(value: number) {
    this.quantity = value;
  }

  public getQuantity(): number {
    return this.quantity;
  }

  public setMedicalPrescriptionDate(value: string) {
    this.medicalPrescriptionDate = value;
  }

  public getMedicalPrescriptionDate(): string {
    return this.medicalPrescriptionDate;
  }

  public setPractitionerRegistration(value: PractitionerRegistration) {
    this.practitionerRegistration = value;
  }

  public getPractitionerRegistration(): PractitionerRegistration {
    return this.practitionerRegistration;
  }

  public setMedicalPrescriptionDocumentKey(value: MedicalPrescriptionDocumentKey) {
    this.medicalPrescriptionDocumentKey = value;
  }

  public getMedicalPrescriptionDocumentKey(): MedicalPrescriptionDocumentKey {
    return this.medicalPrescriptionDocumentKey;
  }

  public hasDocumentKey(): boolean {
    return !!this.medicalPrescriptionDocumentKey && !!this.medicalPrescriptionDocumentKey.getValue();
  }
}
