import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "input-message",
  templateUrl: "./input-message.component.html",
  styleUrls: ["./input-message.component.scss"],
})
export class InputMessageComponent implements OnInit {
  constructor() {}

  @Input() message: string;
  @Input() color: string;
  ngOnInit() {}

  getStyle(): object {
    return { color: this.color, "padding-left": "5px", "font-size": "0.9em" };
  }
}
