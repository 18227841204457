import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class AuthLoadingService {
  private isAuthenticating = new BehaviorSubject<boolean>(false);

  getAuthStatus() {
    return this.isAuthenticating.asObservable();
  }

  startAuth() {
    this.isAuthenticating.next(true);
  }

  stopAuth() {
    this.isAuthenticating.next(false);
  }
}
