import { EventEmitter, Inject } from "@angular/core";
import { Component } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialog {
  public confirmDialogEvent = new EventEmitter();
  public cancelDialogEvent = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<ConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData) {}

  public getTitle(): string {
    return this.data.title;
  }

  public getText(): string {
    return this.data.text;
  }

  public getCancelText(): string {
    return this.data.cancelText ? this.data.cancelText : "CANCELAR";
  }

  public getHiddenConfirmBtn(): boolean {
    return this.data.hiddenConfirmBtn == true;
  }

  public closeDialog(): void {
    this.cancelDialogEvent.emit();
    this.dialogRef.close();
  }

  public emitConfirmEvent() {
    this.confirmDialogEvent.emit();
    this.dialogRef.close();
  }
}

export interface IConfirmDialogData {
  title: string;
  text: string;
  cancelText?: string;
  hiddenConfirmBtn: boolean;
}
