import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material";

@Component({
  selector: "img-svg",
  templateUrl: "./img-svg.component.html",
  styleUrls: ["./img-svg.component.scss"],
})
export class ImgSvgComponent implements OnInit {
  @Input() image: string;

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.matIconRegistry.addSvgIcon(this.image, this.domSanitizer.bypassSecurityTrustResourceUrl(this.image));
  }
}
