import { Directive } from "@angular/core";

@Directive({
  selector: "[dialog-header], dialog-header",
})
export class DialogHeader {}

@Directive({
  selector: `[dialog-content], dialog-content`,
})
export class DialogContent {}

@Directive({
  selector: `[dialog-actions], dialog-actions`,
})
export class DialogActions {}
