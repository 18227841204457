import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "dateFormat",
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  private defaultDateFormat: string = "MMM/dd/yyyy";

  transform(value: any, args?: any): any {
    let dateFormat = this.defaultDateFormat;
    if (args && args.length > 0) {
      dateFormat = args;
      if (!dateFormat) {
        dateFormat = this.defaultDateFormat;
      }
    }
    return super.transform(value, dateFormat);
  }
}
