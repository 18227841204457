export class TaxCoupon {
  private networkReceipt: string;
  private consumerReceipt: string;

  public setNetworkReceipt(value: string): void {
    this.networkReceipt = value;
  }

  public getNetworkReceipt(): string {
    return this.networkReceipt;
  }

  public setConsumerReceipt(value: string): void {
    this.consumerReceipt = value;
  }

  public getConsumerReceipt(): string {
    return this.consumerReceipt;
  }
}
