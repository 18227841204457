import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export class MaskInput {
  static getDateMask() {
    return [/[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  }

  static getTelephoneMask() {
    return ["(", /[0-9]/, /[0-9]/, ")", " ", /[2-8]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  }

  static getMobileMask() {
    return ["(", /[0-9]/, /[0-9]/, ")", " ", /[9]/, /[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  }

  static getFicalNumberMask() {
    return [/[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/];
  }

  static getZipCodeMask() {
    return [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/];
  }

  // RG
  static getFederalRegistrationMask() {
    return [/[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/];
  }
  // CPF
  static getSocialNumberMask() {
    return [/[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/];
  }

  static getTimeMask() {
    return [/[0-9]/, /[0-9]/, ":", /[0-9]/, /[0-9]/];
  }

  static getProductMaxCount() {
    return [/[1-2]/];
  }

  static getSubsidyMask() {
    // return [/[0-9]/, /[0-9]?/, /[.]/, /[0-9]/, /[0-9]/];
    return [/##0,00%/];
  }

  static getLimitMask() {
    return [/[0-9]/, /[\.]/, /[0-9]/, /[0-9]/];
  }

  static getCardNumberMask() {
    return [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  }

  static getUniqueSequentialNumberMask() {
    return [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  }

  static getNumericalPassword() {
    return [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  }
  static getCurrency() {
    return createNumberMask({
      allowDecimal: true,
      requireDecimal:false,
      decimalSymbol: ',',
      decimalLimit:2,
      allowNegative:false,
      prefix: '',
      thousandsSeparatorSymbol: '.'
    })
  }
}