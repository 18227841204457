import { Injectable } from "@angular/core";
import { FiscalNumber } from "../../models/domains/pdv/fiscal-number";
import { TermForm, TermFormGroup } from "../../models/domains/pdv/form-term";
import { NetworkAcceptTerm } from "../../models/domains/pdv/network-accept-term";
import { Term } from "../../models/domains/pdv/term";
import { AcceptTermRepository } from "../../repositories/pdv/accept-term.repository";

@Injectable()
export class AcceptTermService {
  constructor(private acceptTermRepository: AcceptTermRepository) {}

  public async acceptTerm(fiscalNumber: FiscalNumber, term: Term): Promise<void> {
    try {
      return Promise.resolve(this.acceptTermRepository.acceptTerm(fiscalNumber, term));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async getAcceptTermBySocialNumber(fiscalNumber: FiscalNumber): Promise<NetworkAcceptTerm> {
    try {
      return Promise.resolve(this.acceptTermRepository.getNetworkAcceptTermByFiscalNumber(fiscalNumber));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async getTerm(networkInfoKey: string): Promise<Term> {
    try {
      return Promise.resolve(this.acceptTermRepository.getTerm(networkInfoKey));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async getTermForm(fiscalNumber: FiscalNumber): Promise<TermForm> {
    try {
      const result = await this.acceptTermRepository.getTermForm(fiscalNumber);
      return Promise.resolve(result);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async sendTermForm(form: TermFormGroup): Promise<Term> {
    try {
      const result = await this.acceptTermRepository.sendTermForm(form);
      return Promise.resolve(result);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async downloadTerm(termId: string, networkInfoKey: string): Promise<Blob> {
    try {
      const result = await this.acceptTermRepository.downloadTerm(termId, networkInfoKey);
      return Promise.resolve(result);
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
