import { Network } from "./network";
import { PdvCycleItem } from "./pdv-cycle-item";

export class PdvFinancialStatement {
  constructor(private readonly network: Network, private readonly pdvCycleItemList: Array<PdvCycleItem>) {}

  public getNetwork(): Network {
    return this.network;
  }

  public getPdvCycleItemList(): Array<PdvCycleItem> {
    return this.pdvCycleItemList;
  }
}
