import { Ean } from "./ean";
import { ErrorCause } from "./error-cause";
import { MedicalPrescription } from "./medical-prescription";
import { MedicalPrescriptionViewItem } from "./medical-prescription-view-item";
import { PractitionerRegistration } from "./practitioner-registration";
import { SalesOrderItem } from "./sales-order-item";
import { Shopper } from "./shopper";

export class SalesOrder {
  private salesOrderItemList: Array<SalesOrderItem> = [];
  private shopper: Shopper;
  private errorCauseList: ErrorCause[] = [];
  private status: string;
  private totalPrice: number;

  constructor(private readonly source = "DrugstoreDesk") {}

  public getStatus(): string {
    return this.status;
  }

  public setStatus(value: string): void {
    this.status = value;
  }

  public getSource(): string {
    return this.source;
  }

  public setSalesOrderItemList(value: Array<SalesOrderItem>): void {
    this.salesOrderItemList = value;
  }

  public getSalesOrderItemList(): Array<SalesOrderItem> {
    return this.salesOrderItemList;
  }

  public setShopper(value: Shopper): void {
    this.shopper = value;
  }

  public getShopper(): Shopper {
    return this.shopper;
  }

  public setErrorCauseList(value: ErrorCause[]): void {
    this.errorCauseList = value;
  }

  public getErrorCauseList(): ErrorCause[] {
    return this.errorCauseList;
  }

  public setTotalPrice(value: number): void {
    this.totalPrice = value;
  }

  public getTotalPrice(): number {
    return this.totalPrice;
  }
  
  public addSalesOrderItem(salesOrderItem: SalesOrderItem): void {
    this.salesOrderItemList.push(salesOrderItem);
  }

  public removeSalesOrderItem(salesOrderItem: SalesOrderItem): void {
    this.salesOrderItemList = this.salesOrderItemList.filter((item) => {
      return item.getProduct().getEan().getValue() !== salesOrderItem.getProduct().getEan().getValue();
    });
  }

  public addSalesOrderItemQuantity(salesOrderItem: SalesOrderItem): void {
    this.salesOrderItemList.forEach((item) => {
      if (item.getProduct().getEan().getValue() === salesOrderItem.getProduct().getEan().getValue()) {
        item.addQuantity();
      }
    });
  }

  public removeSalesOrderItemQuantity(salesOrderItem: SalesOrderItem): void {
    if (salesOrderItem.getProductQuantity() > 1) {
      this.salesOrderItemList.forEach((item) => {
        if (item.getProduct().getEan().getValue() === salesOrderItem.getProduct().getEan().getValue()) {
          item.removeQuantity();
        }
      });
    }
  }

  public updateMedicalPrescription(salesOrderItem: SalesOrderItem, practitionerRegistration: PractitionerRegistration, medicalPrescriptionDate: string): void {
    this.salesOrderItemList.forEach((item) => {
      if (item.getProduct().getEan().getValue() === salesOrderItem.getProduct().getEan().getValue()) {
        if (!item.getMedicalPrescription()) {
          item.setMedicalPrescription(new MedicalPrescription());
        }
        item.getMedicalPrescription().setPractitionerRegistration(practitionerRegistration);
        item.getMedicalPrescription().setMedicalPrescriptionDate(medicalPrescriptionDate);
      }
    });
  }

  public updateMedicalPrescriptionDocumentKey(medicalPrescriptionViewItem: MedicalPrescriptionViewItem) {
    this.salesOrderItemList.forEach((item) => {
      if (item.getProduct().getEan().getValue() === medicalPrescriptionViewItem.getEan().getValue()) {
        if (!item.getMedicalPrescription()) {
          item.setMedicalPrescription(new MedicalPrescription());
        }
        item.getMedicalPrescription().setMedicalPrescriptionDocumentKey(medicalPrescriptionViewItem.getMedicalPrescriptionDocumentKey());
      }
    });
  }

  public isValid() {
    return this.salesOrderItemList.every((salesOrderItem) => {
      const stateValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getState().getValue();
      const registrationValue = salesOrderItem.getMedicalPrescription().getPractitionerRegistration().getRegistration();
      const dateValue = salesOrderItem.getMedicalPrescription().getMedicalPrescriptionDate();
      if (salesOrderItem.getRequireMedicalPrescription()) {
        if (stateValue && registrationValue && dateValue) {
          return true;
        }
        return false;
      } else {
        if (stateValue && registrationValue && dateValue) {
          return true;
        } else if (!stateValue && !registrationValue && !dateValue) {
          return true;
        }
        return false;
      }
    });
  }

  /**
   *
   * @returns 0=Não obrigatório; 1=Pode escanear depois pelo APP; 2=Obrigatório escanear no ato da compra.
   */
  public requiredScanLevel(): number {
    if (this.salesOrderItemList.some((item) => item.getRequireScan())) {
      return 1;
    } else if (this.salesOrderItemList.some((item) => item.getPurchaseRequireScan())) {
      return 2;
    } else {
      return 0;
    }
  }

  /**
   * Verifica se há algum item que exige escanemaneto de receita mas não foi feito.
   * @returns true=Se todos itens que exigem receita estiverem OK.
   */
  public allItemsScanIsValid(): boolean {
    return !this.salesOrderItemList.some(
      (item) =>
        item.getPurchaseRequireScan() &&
        (!item.getMedicalPrescription().getMedicalPrescriptionDocumentKey() || item.getMedicalPrescription().getMedicalPrescriptionDocumentKey().getValue() == ""),
    );
  }

  /**
   * Verifica se há algum item que o escanemaneto de receita mas não foi feito.
   * @returns false=Se todos itens que exigem receita estiverem OK.
   */
  public scanIsValid(): boolean {
    return this.salesOrderItemList.some(
      (item) =>
        (item.getPurchaseRequireScan()||item.getRequireScan()) &&
        (!item.getMedicalPrescription().getMedicalPrescriptionDocumentKey() || item.getMedicalPrescription().getMedicalPrescriptionDocumentKey().getValue() == ""),
    );
  }

  public findItemByEan(productEan: Ean): SalesOrderItem {
    return this.salesOrderItemList.find((i) => i.getProduct().getEan().getValue() === productEan.getValue());
  }
}
