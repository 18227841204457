import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";

import { SalesOrder } from "src/app/ui/models/domains/pdv/sales-order";
import { Shopper } from "src/app/ui/models/domains/pdv/shopper";
import { LoadingService } from "src/app/ui/services/loading.service";

import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";
import { ShopperService } from "src/app/ui/services/pdv/shopper.service";

@Component({
  selector: "pdv-beneficiary-summary",
  templateUrl: "./beneficiary-summary.component.html",
  styleUrls: ["./beneficiary-summary.component.scss"],
})
export class BeneficiarySummaryComponent implements OnInit {
  private salesOrder$: Observable<SalesOrder>;
  shopper: Shopper;
  openBeneficiaryRules: boolean;
  loadRules: boolean = false;

  constructor(private purchaseService: PurchaseService, private shopperService: ShopperService, private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.salesOrder$ = this.purchaseService.getSalesOrder();
    this.salesOrder$.subscribe((salesOrder) => {
      if (salesOrder) {
        this.shopper = salesOrder.getShopper();
      }
    });
  }

  hasShopper(): boolean {
    return this.shopper ? true : false;
  }

  public async showBeneficiaryRules() {
    if(!this.loadRules){
      let ref = this.loadingService.beginLoading();
      await this.shopperService.loadProductConfiguration(this.shopper)
      .then((salesOrder) => {
        if (salesOrder) {
          this.shopper = salesOrder;
          this.openBeneficiaryRules = true;
          this.loadRules = true;
        }
      }).finally(()=>{
        this.loadingService.finishLoading(ref);
      });
    }
    else{
      this.openBeneficiaryRules = true;
    }
  }

  hideBeneficiaryRules(): void {
    this.openBeneficiaryRules = false;
  }

  isBlockedCard(): boolean {
    if (!this.hasShopper()) {
      return false;
    }
    return this.shopper.getIsBlockedCard();
  }

  getShopperFinancialBalanceView(): string {
    if (this.hasShopper()) {
      return this.shopper.getFinancialBalance().getFinancialBalanceView();
    }
    return "--";
  }
}
