import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "firstChar",
})
export class GetFirstCharPipe implements PipeTransform {
  transform(value: string, args?: any[]): string | boolean {
    if (value == null) {
      return value;
    }
    const words = value.split(" ");
    return words.length > 0 ? words[0].substr(0, 1) : value.substr(0, 1);
  }
}
