import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { environment } from "src/environments/environment";
import { PipRouteStrategy } from "./pip-route-strategy";
import { IRouteStrategy } from "./route-strategy";
import { AccessErrorStrategy } from "./access-error-strategy";
import { AccessErrorCause } from "../models/domains/pdv/enum/access-error-type.enum";

@Injectable()
export class RouteStrategyFactory {
  constructor(private router: Router) {}

  createStrategy(accessErrorCause = AccessErrorCause.Undefined): IRouteStrategy {
    const redirectOnExitingApp = environment.redirectOnExitingApp;
    if (redirectOnExitingApp === "pip") {
      return new PipRouteStrategy();
    }
    return new AccessErrorStrategy(this.router, accessErrorCause);
  }
}
