import { Injectable } from "@angular/core";
import { MedicalPrescriptionDocumentKey } from "src/app/ui/models/domains/pdv/medical-prescription-document-key";
import { AbstractParser } from "../parser";

@Injectable()
export class MedicalPrescriptionDocumentKeyParser extends AbstractParser<MedicalPrescriptionDocumentKey> {
  parse(payload: any): MedicalPrescriptionDocumentKey {
    const medicalPrescriptionDocumentKey = new MedicalPrescriptionDocumentKey();
    medicalPrescriptionDocumentKey.setValue(payload[0].Key);
    return medicalPrescriptionDocumentKey;
  }
}
