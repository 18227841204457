import { Item } from "../item/item";
import { FilterOptionType } from "./filter-option-type.enum";

export interface IFilterOption {
  getDefaultValues(): Array<Item>;
  getName(): string;
  getType(): FilterOptionType;
  getPropertyName(): string;
  getValue(): Object;
  setValue(value: Object): void;
  hasValue(): boolean;
  validateRequired(): boolean;
}

export class FilterOption implements IFilterOption {
  private filterOptionType: FilterOptionType;
  private name: string;
  private required: boolean = false;
  protected value: object;
  protected propertyName: string;

  constructor(filterOptionType: FilterOptionType, name: string, propertyName: string) {
    this.filterOptionType = filterOptionType;
    this.name = name;
    this.propertyName = propertyName;
  }

  getName(): string {
    return this.name;
  }

  getType(): FilterOptionType {
    return this.filterOptionType;
  }

  getDefaultValues(): Array<Item> {
    return new Array<Item>();
  }

  getPropertyName(): string {
    return this.propertyName;
  }

  getValue(): Object {
    return this.value;
  }

  setValue(value: Object): void {
    this.value = value;
  }

  isComboBoxFilter(): boolean {
    return this.getType() === FilterOptionType.ComboBox;
  }

  isDateFilter(): boolean {
    return this.getType() === FilterOptionType.Date;
  }

  isTextFilter(): boolean {
    return this.getType() === FilterOptionType.Text;
  }

  isCheckFilter(): boolean {
    return this.getType() === FilterOptionType.CheckBox;
  }

  isNumberFilter(): boolean {
    return this.getType() === FilterOptionType.Number;
  }

  hasValue(): boolean {
    return !!this.getValue();
  }

  applyFilter(items: Object[]): Object[] {
    return items.filter((i) => i[this.propertyName] === this.value);
  }

  clear() {
    this.value = null;
  }

  validateRequired(): boolean {
    return this.required && this.hasValue();
  }
}
