import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material";
import { Observable } from "rxjs";
import { AuthLoadingService } from "./ui/services/pdv/auth-loading.service";
import { environment } from "src/environments/environment";

declare const gtag: Function;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "idp-vpi-app";
  isAuthenticating: Observable<boolean>;

  private customIcons: Array<{ name: string; fileName: string }> = [
    { name: "consult", fileName: "refund-icon.svg" },
    { name: "home", fileName: "home-icon.svg" },
    { name: "register", fileName: "register-icon.svg" },
    { name: "extract", fileName: "extract-icon.svg" },
    { name: "support", fileName: "support-icon.svg" },
    { name: "exit-app", fileName: "exit-app-icon.svg" },
    { name: "excel", fileName: "excel-icon.svg" },
  ];

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private authLoadingService: AuthLoadingService) {
    this.addGAScript();
    this.addClarityScript();
    this.addCustomIcons();
    this.isAuthenticating = this.authLoadingService.getAuthStatus();
  }

  private addCustomIcons() {
    this.customIcons.forEach((customIcon) => {
      const defaultIconsPath = "../assets/icons/";
      this.matIconRegistry.addSvgIcon(customIcon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(defaultIconsPath + customIcon.fileName));
    });
  }

  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement("script");
    gtagScript.async = true;
    gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=" + environment.tagAnalytics;
    document.head.prepend(gtagScript);
    gtag("config", environment.tagAnalytics, { send_page_view: false });
  }

  addClarityScript() {
    var head = document.getElementsByTagName("head")[0];
    let clarityScript: HTMLScriptElement = document.createElement("script");
    clarityScript.innerHTML = `(function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "${environment.clarityId}");`;
    head.appendChild(clarityScript);
  }
}
