import { Injectable } from "@angular/core";

import { BlockItemType } from "src/app/ui/models/domains/pdv/enum/block-item-type.enum";
import { IBlockItem } from "src/app/ui/models/domains/pdv/interfaces/iblock-list-item";
import { ProductBlockItem } from "src/app/ui/models/domains/pdv/Benefit/product-block-item";
import { ProductClusterExceptInfo } from "src/app/ui/models/domains/pdv/Benefit/product-cluster-except-info";
import { ProductConfiguration } from "src/app/ui/models/domains/pdv/Benefit/product-configuration";
import { ProductConfigurationCluster } from "src/app/ui/models/domains/pdv/Benefit/product-configuration-cluster";
import { ProductSubtype } from "src/app/ui/models/domains/pdv/Benefit/product-subtype";
import { ProductType } from "src/app/ui/models/domains/pdv/Benefit/product-type";
import { TherapeuticClassBlockItem } from "src/app/ui/models/domains/pdv/Benefit/therapeutic-class-block-item";
import { FiscalNumber } from "src/app/ui/models/domains/pdv/fiscal-number";
import { AbstractParser } from "../parser";

@Injectable()
export class ProductConfigurationParser extends AbstractParser<ProductConfigurationCluster> {
  parse(payload: any): ProductConfigurationCluster {
    const productConfigurationCluster = new ProductConfigurationCluster();
    productConfigurationCluster.setKey(payload.Key);
    productConfigurationCluster.setCompanyFiscalNumber(new FiscalNumber(payload.CompanyFiscalNumber));
    productConfigurationCluster.setProductConfiguration(this.addProductConfiguration(payload.ProductConfiguration));
    productConfigurationCluster.setProductClusterExceptInfo(this.addProductClusterExceptInfo(payload.ProductClusterExceptInfo));
    productConfigurationCluster.setProductMaxCount(payload.ProductMaxCount);
    productConfigurationCluster.setBlockListConfiguration(this.parseBlockListConfiguration(payload.BlockListConfiguration));
    return productConfigurationCluster;
  }

  private addProductConfiguration(payloadProductConfiguration: any): Array<ProductConfiguration> {
    const productConfigurationList: Array<ProductConfiguration> = [];
    payloadProductConfiguration.forEach((configuration) => {
      const productConfiguration = new ProductConfiguration();
      productConfiguration.setProductType(this.addProductType(configuration.ProductTypeKey));
      productConfiguration.setProductSubType(this.addProductSubType(configuration.ProductSubTypeKey));
      productConfiguration.setPurchaseEnabled(configuration.PurchaseEnabled);
      productConfiguration.setSubsidyEnabled(configuration.SubsidyEnabled);
      productConfiguration.setSubsidy(configuration.Subsidy);
      productConfigurationList.push(productConfiguration);
    });
    return productConfigurationList;
  }

  private addProductType(key: string): ProductType {
    const productType = new ProductType();
    productType.setKey(key);
    productType.setValue("");
    return productType;
  }

  private addProductSubType(key: string): ProductSubtype {
    const productSubType = new ProductSubtype();
    productSubType.setKey(key);
    productSubType.setValue("");
    return productSubType;
  }

  private addProductClusterExceptInfo(payload: any): ProductClusterExceptInfo {
    const productClusterExceptInfo = new ProductClusterExceptInfo();
    productClusterExceptInfo.setAllowedPurchase(payload.AllowedPurchase);
    productClusterExceptInfo.setAllowedCheckoutOnline(payload.AllowedCheckoutOnline);
    productClusterExceptInfo.setAllowedFullEmploymentPayroll(payload.AllowedFullEmploymentPayroll);
    return productClusterExceptInfo;
  }

  private parseBlockListConfiguration(payload: any): IBlockItem[] {
    const blockListConfiguration: IBlockItem[] = payload.BlockListItems.map((item) => {
      if (item.BlockListItemType === BlockItemType.Product) {
        const product = new ProductBlockItem();
        product.setProductName(item.ProductName);
        product.setEan(item.Ean);
        product.setProductKey(item.ProductKey);
        return product;
      } else if (item.BlockListItemType === BlockItemType.TherapeuticClass) {
        const therapeuticClass = new TherapeuticClassBlockItem();
        therapeuticClass.setDisplayValue(item.TherapeuticClass);
        therapeuticClass.setCode(item.TherapeuticClassCode);
        therapeuticClass.setDescription(item.TherapeuticClass);
        therapeuticClass.setTherapeuticClassKey(item.TherapeuticClassKey);
        return therapeuticClass;
      }
    });
    return blockListConfiguration;
  }
}
