import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { SalesOrder } from "src/app/ui/models/domains/pdv/sales-order";
import { SalesOrderItem } from "src/app/ui/models/domains/pdv/sales-order-item";
import { SalesOrderResult } from "src/app/ui/models/domains/pdv/sales-order-result";
import { LoadingService } from "src/app/ui/services/loading.service";
import { HomeService } from "src/app/ui/services/pdv/home.service";
import { NavigatorService } from "src/app/ui/services/pdv/navigator.service";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";
import { ConfirmDialog } from "../../dialog/confirm-dialog/confirm-dialog.component";
import { InputDialogComponent } from "../../dialog/input-dialog/input-dialog.component";
import { MessageDialog } from "../../dialog/message-dialog/message-dialog.component";
import { Toast } from "../../toast/toast";
import { MedicalPrescriptionDialogComponent } from "./medical-prescription-dialog/medical-prescription-dialog.component";
import { TypeSalesOrderMessage } from "src/app/ui/models/domains/pdv/error-cause";

@Component({
  selector: "pdv-purchase-preview-content",
  templateUrl: "./purchase-preview-content.component.html",
  styleUrls: ["./purchase-preview-content.component.scss"],
})
export class PurchasePreviewContentComponent implements OnInit {
  salesOrder: SalesOrder;
  private confirmDialogRef: MatDialogRef<ConfirmDialog>;
  private inputDialogRef: MatDialogRef<InputDialogComponent>;
  private alertMedicalPrescriptionShowed: boolean;
  public requiredScan: boolean;
  public purchaseRequiredScan: boolean;
  displayedColumns = ["product", "start-price", "final-price", "discount", "quantity", "total-price", "messages", "messages-attendance", "status", "actions"];
  typeMessage = TypeSalesOrderMessage;

  constructor(
    private purchaseService: PurchaseService,
    private homeService: HomeService,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private toast: Toast,
    private navigatorService: NavigatorService,
  ) { }

  ngOnInit() {
    this.purchaseService.getSalesOrder().subscribe((salesOrder) => {
      this.salesOrder = salesOrder;
      this.requiredScan = this.salesOrder.requiredScanLevel() == 1 && !this.alertMedicalPrescriptionShowed;
      this.purchaseRequiredScan = this.salesOrder.requiredScanLevel() == 2 && !this.salesOrder.allItemsScanIsValid();
    });
  }

  public async removeSalesOrderItem(salesOrderItem: SalesOrderItem): Promise<void> {
    this.purchaseService.removeSalesOrderItem(salesOrderItem);
    if (this.salesOrder.getSalesOrderItemList().length === 0) {
      this.initializePurchase();
    } else {
      await this.emitNewPreviewPreSalesOrder();
    }
  }

  public initializePurchase(): void {
    this.homeService.initializePurchase();
  }

  public finalizePurchase(): void {
    this.homeService.finalizePurchase();
  }

  public openMedicalPrescriptionDialog(): void {
    const medicalDialog = this.dialog.open(MedicalPrescriptionDialogComponent, {
      height: "500px",
      width: "1000px",
    });
    medicalDialog.afterClosed().subscribe(async (val) => {
      if (val) {
        await this.emitNewPreviewPreSalesOrder();
      }
    });
  }

  async checkRequiredScan() {
    let title = "Digitalização de Receita Obrigatória";
    let text = "Escaneie agora ou avise ao beneficiário que ele precisa realizar o upload da receita pelo app para não perder o subsídio.";
    let hiddenConfirmBtn = false;

    if (this.purchaseRequiredScan) {
      title = "Digitalização obrigatória no ATO DA COMPRA";
      text = "Obrigatório o Upload da(s) Receita(s) para este cliente antes de finalizar.";
      hiddenConfirmBtn = true;
    }

    if (this.requiredScan || this.purchaseRequiredScan) {
      this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
        disableClose: true,
        hasBackdrop: true,
        height: "250px",
        width: "500px",
        data: {
          title: title,
          text: text,
          cancelText: "Voltar e Digitalizar",
          hiddenConfirmBtn: hiddenConfirmBtn,
        },
      });
      this.alertMedicalPrescriptionShowed = true;

      this.confirmDialogRef.componentInstance.confirmDialogEvent.subscribe(async () => {
        this.checkIfHaveTransactionalPassword();
      });
      this.confirmDialogRef.componentInstance.cancelDialogEvent.subscribe(async () => {
        this.openMedicalPrescriptionDialog();
      });
    } else {
      this.checkIfHaveTransactionalPassword();
    }
  }

  checkIfHaveTransactionalPassword() {
    const password = this.salesOrder.getShopper().getHaveTransactionalPassword();

    if (!!password) {
      this.inputDialogRef = this.dialog.open(InputDialogComponent, {
        disableClose: true,
        hasBackdrop: true,
        data: {
          title: "Senha Transacional",
          text: "Informe a senha Transacional de 6 dígitos do cliente",
        },
      });
      this.alertMedicalPrescriptionShowed = true;

      this.inputDialogRef.componentInstance.inputDialogEvent.subscribe(async (data) => {
        this.purchaseService.setTransactionalPassword(data);
        this.emitPreSalesOrder();
      });
    } else {
      this.emitPreSalesOrder();
    }
  }
  
  productDetail(salesOrderItem: SalesOrderItem): string {
    return `
      ${salesOrderItem.getProduct().getName()}
      \n${salesOrderItem.getProduct().getPresentation() ? salesOrderItem.getProduct().getPresentation() : ""}
      Substânica: ${salesOrderItem.getProduct().getSubstance() ? salesOrderItem.getProduct().getSubstance() : "-"}
      EAN: ${salesOrderItem.getProduct().getEan().getValue() ? salesOrderItem.getProduct().getEan().getValue() : "-"}
      Lab: ${salesOrderItem.getProduct().getLaboratory() ? salesOrderItem.getProduct().getLaboratory() : "-"}
      Tipo: ${salesOrderItem.getProduct().getType() ? salesOrderItem.getProduct().getType() : "-"} 
    `;
  }

  public async emitNewPreviewPreSalesOrder(): Promise<void> {
    const ref = this.loadingService.beginLoading();
    try {
      const salesOrderResult = await this.purchaseService.emitPreviewPreSalesOrder();
      if (salesOrderResult.getErrorCauseList().length > 0) {
        const errorMessage = salesOrderResult.getErrorCauseList()[0].getErrorMessage();
        this.toast.showWarningToast(errorMessage);
      }
    } catch (erroMessage) {
      this.toast.showWarningToast(erroMessage);
    } finally {
      this.loadingService.finishLoading(ref);
    }
  }

  public async emitPreSalesOrder() {
    const ref = this.loadingService.beginLoading();
    try {
      const salesOrderResult = await this.purchaseService.emitPreSalesOrder();

      if (!!salesOrderResult && salesOrderResult.getUniqueSequentialNumber().getValue()) {
        this.homeService.finalizePurchase();
      } else if (salesOrderResult.getErrorCauseList().length > 0) {
        this.toast.showWarningToast(salesOrderResult.getErrorCauseList()[0].getErrorMessage());
      } else {
        this.checkItemsErrors(salesOrderResult);
      }
    } catch (erroMessage) {
      this.toast.showWarningToast(erroMessage);
    } finally {
      this.loadingService.finishLoading(ref);
    }
  }

  private async checkItemsErrors(salesOrderResult: SalesOrderResult) {
    let allItemsHaveError = true;
    let allItemsHaveLimitError = true;
    salesOrderResult.getSalesOrderItemResultList().map((item) => {
      if (item.getErrorCauseList().length > 0) {
        const errorCode = item.getErrorCauseList()[0].getErrorCode();
        const errorMessage = item.getErrorCauseList()[0].getErrorMessage();
        if (!errorMessage || errorMessage == undefined || errorMessage == null) {
          allItemsHaveError = false;
        }
        if (errorCode !== "PurchasePermissionPhase.Company.Limit.ReachMaxAllowed") {
          allItemsHaveLimitError = false;
        }
        this.toast.showWarningToast(errorMessage);
      }
    });

    if (allItemsHaveError) {
      this.dialog.open(MessageDialog, {
        disableClose: true,
        hasBackdrop: true,
        data: {
          title: "Atenção",
          text: "Nenhum item aceito para esta venda! " + (allItemsHaveLimitError ? "Limite da EMPRESA esgotado." : ""),
          okText: "OK",
          type: "error",
        },
      });
    }
  }

  public getItemStatus(salesOrderItem: SalesOrderItem, type: number): string {
    const typeSales = TypeSalesOrderMessage[type] as unknown as TypeSalesOrderMessage;
    if (salesOrderItem.getErrorCauseList().length > 0) {
      const errorMessageList: string[] = [];
      salesOrderItem.getErrorCauseList().forEach((error) => {
        if (error.getTypeSalesOrderMessage() === typeSales) {
          errorMessageList.push(error.getErrorMessage());
        }
      });
      return errorMessageList.join("\n- ");
    }
    return "";
  }

  public isInternetExplorer(): boolean {
    return this.navigatorService.isInternetExplorer();
  }
}
