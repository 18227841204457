import { Injectable, Injector, Inject } from "@angular/core";
import { Overlay, GlobalPositionStrategy } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { ToastRef } from "../components/toast/toast-ref";
import { ToastComponent } from "../components/toast/toast.component";
import { TOAST_CONFIG_TOKEN, ToastConfig } from "../models/domains/toast/toast-config";
import { ToastData } from "../models/domains/toast/toast-data";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  private lastToast: ToastRef;

  constructor(private overlay: Overlay, private parentInjector: Injector, @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig) {}

  show(data: ToastData): ToastRef {
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy });

    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;

    const injector = this.getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);

    overlayRef.attach(toastPortal);

    return toastRef;
  }

  getPositionStrategy(): GlobalPositionStrategy {
    return this.overlay
      .position()
      .global()
      .top(this.getPosition())
      .right(this.toastConfig.position.right + "px");
  }

  getPosition(): string {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    const position = lastToastIsVisible ? this.lastToast.getPosition().bottom : this.toastConfig.position.top;

    return position + "px";
  }

  getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector): PortalInjector {
    const tokens = new WeakMap();

    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }
}
