import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AcceptanceTermGuardService } from "../services/guards/acceptance-term-guard.service";
import { AuthenticationGuardService } from "../services/guards/authentication-guard.service";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "home",
  },
  {
    path: "acceptance-term",
    canActivate: [AcceptanceTermGuardService],
    loadChildren: () => import("../pages/acceptance-term/acceptance-term.module").then((m) => m.AcceptanceTermModule),
  },
  {
    path: "home",
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import("../pages/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "consult",
    loadChildren: () => import("../pages/consult/consult.module").then((m) => m.ConsultModule),
  },
  {
    path: "financial-statement",
    loadChildren: () => import("../pages/financial-statement-page/financial-statement-page.module").then((m) => m.FinancialStatementPageModule),
  },
  {
    path: "not-accepted-term",
    loadChildren: () => import("../pages/not-accepted-term/not-accepted-term.module").then((m) => m.NotAcceptedTermModule),
  },
  {
    path: "access-error",
    loadChildren: () => import("../pages/access-error/access-error.module").then((m) => m.AccessErrorModule),
  },
  {
    path: "not-registered",
    loadChildren: () => import("../pages/not-registered/not-registered.module").then((m) => m.NotRegisteredModule),
  },
  {
    path: "branch-has-no-head-office",
    loadChildren: () => import("../pages/branch-has-no-head-office/branch-has-no-head-office.module").then((m) => m.BranchHasNoHeadOfficeModule),
  },
  {
    path: "branch-has-no-head-office-accepted-term",
    loadChildren: () =>
      import("../pages/branch-has-no-head-office-accepted-term/branch-has-no-head-office-accepted-term.module").then((m) => m.BranchHasNoHeadOfficeAcceptedTermModule),
  },
  {
    path: "**",
    redirectTo: "access-error",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthenticationGuardService, AcceptanceTermGuardService],
})
export class AppRoutingModule {}
