import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";

@Injectable()
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccess(message: string): void {
    this.snackBar.open(message, "Ok", {
      duration: 3000,
      panelClass: "success",
    });
  }

  showError(message: string): void {
    this.snackBar.open(message, "Ok", {
      duration: 3000,
      panelClass: "danger",
    });
  }
}
