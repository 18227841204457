import { Injectable } from "@angular/core";
import { ToastService } from "../../services/toast.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorService } from "../../services/interceptors/error/error.service";

@Injectable()
export class Toast {
  constructor(private toastService: ToastService, private errorService: ErrorService) {}

  showWarningToast(message: any, title: string = "Atenção", secondsDurantion: number = 5) {
    const type = "warning";
    let text = this.handlerError(message);
    this.toastService.show({ type, title, text, secondsDurantion });
  }

  showSuccessToast(text: string, title: string = "", secondsDurantion: number = 5) {
    const type = "success";
    this.toastService.show({ type, title, text, secondsDurantion });
  }

  handlerError(error: any) {
    let message: string;

    if (error instanceof HttpErrorResponse) {
      // Server error
      message = `S${new Date().getTime()} : ${this.errorService.getServerMessage(error)}`;
    } else if (error instanceof Error) {
      // Client Error
      message = `C${new Date().getTime()} : ${this.errorService.getClientMessage(error)}`;
    } else {
      message = error;
    }

    return message;
  }
}
