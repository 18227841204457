import { Injectable } from "@angular/core";
import { Ean } from "src/app/ui/models/domains/pdv/ean";
import { Product } from "src/app/ui/models/domains/pdv/product";
import { ProgressiveDiscount } from "src/app/ui/models/domains/pdv/progressive-discount";
import { AbstractParser } from "../parser";

@Injectable()
export class FindProductByEanParser extends AbstractParser<Product> {
  parse(payload: any): Product {
    if(!payload.Products || payload.Products.length === 0)
      throw new Error("Não foi possível encontrar o produto");

    const productData = payload.Products[0];
    const product = new Product();
    product.setName(productData.Product.Name);
    product.setEan(new Ean(productData.Product.Ean));
    product.setLaboratory(productData.Product.Laboratory);
    product.setPresentation(productData.Product.Presentation);
    product.setSubstance(productData.Product.Substance);
    product.setGrossPrice(productData.GrossPrice);
    product.setType(productData.Product.Type);
    product.setSubType(productData.Product.SubType);
    product.setPriceRegime(productData.Product.PriceRegime);
    product.setNetPrice(productData.NetPrice);
    product.setNetworkPrice(productData.NetworkPrice);
    product.setPmcPrice(productData.PmcPrice);
    product.setPrice(productData.Price);
    product.setUnitGrossPrice(productData.GrossPrice);
    product.setUnitPrice(productData.Price);
    product.setProductSubsidy(productData.ProductSubsidy);
    product.setDiscount(productData.Discount);
    product.setProgressiveDiscountList(this.parseProgressiveDiscount(productData.ProgressiveDiscounts));
    product.setExternalAuthorizerDiscount(productData.ExternalAuthorizerDiscount);
    product.setHasExternalAuthorizerBetterDiscount(productData.HasExternalAuthorizerBetterDiscount);
    product.setHasMedicalPrescription(productData.HasMedicalPrescription);
    product.setIndustryProgramAdhered(productData.IndustryProgramAdhered);
    product.setUsedNetworkPrice(productData.UsedNetworkPrice);
    product.setUsedNetworkNetPrice(productData.UsedNetworkNetPrice);
    product.setUsedNetworkGrossPrice(productData.UsedNetworkGrossPrice);
    product.setSubsidyAllowed(productData.SubsidyAllowed);
    product.setAuthorizedQuantity(productData.AuthorizedQuantity);
    product.setCanInputPrice(productData.CanInputPrice);

    if (product.getCanInputPrice()) {
      if (product.getUsedNetworkPrice()) {
        product.setSellerUnitPrice(productData.NetPrice);
        product.setSellerUnitGrossPrice(productData.GrossPrice);
      } else {
        product.setSellerUnitPrice(productData.PmcPrice);
        product.setSellerUnitGrossPrice(productData.PmcPrice);
      }
    }

    return product;
  }

  private parseProgressiveDiscount(payload: any): ProgressiveDiscount[] {
    const discountList: ProgressiveDiscount[] = payload.map((item) => {
      return new ProgressiveDiscount(item.ProductQuantity, item.Discount);
    });
    return discountList;
  }
}
