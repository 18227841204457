import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class RouterService {
  private path = new BehaviorSubject<string>(null);

  updatePath(value: string) {
    this.path.next(value);
  }

  getPath(): Observable<string> {
    return this.path.asObservable();
  }
}
