import { GridColumn } from "./grid-column";
import { MatSvgIconTemplate } from "./mat-svg-icon-template.model";

export class MultiIconGridColumn extends GridColumn {
  private matSvgIconRegistred: Array<MatSvgIconTemplate>;
  constructor() {
    super();
    this.matSvgIconRegistred = new Array<MatSvgIconTemplate>();
  }

  renderByMaterialTable(): Object {
    return { columnDef: this.binding, header: this.display, customColumn: this, isIcon: true, cell: (row: Object) => this };
  }

  addMatIconSvg(matIconSvgTemplate: MatSvgIconTemplate): void {
    this.matSvgIconRegistred.push(matIconSvgTemplate);
  }

  getMatSvgIconsRegistred(): Array<MatSvgIconTemplate> {
    return this.matSvgIconRegistred;
  }
}
