import { Injectable } from "@angular/core";
import { debug } from "console";
import { BehaviorSubject, Observable, throwError } from "rxjs";

import { AppContext } from "../../contexts/app-context";
import { MedicalPrescriptionViewItem } from "../../models/domains/pdv/medical-prescription-view-item";
import { PractitionerRegistration } from "../../models/domains/pdv/practitioner-registration";
import { SalesOrder } from "../../models/domains/pdv/sales-order";
import { SalesOrderItem } from "../../models/domains/pdv/sales-order-item";
import { SalesOrderResult } from "../../models/domains/pdv/sales-order-result";
import { Shopper } from "../../models/domains/pdv/shopper";
import { StateAcronymModel } from "../../models/domains/pdv/state-acronym-model";
import { PurchaseRepository } from "../../repositories/pdv/purchase.repository";
import { Network } from "../../models/domains/pdv/network";

@Injectable({ providedIn: "root" })
export class PurchaseService {
  private salesOrderSubject = new BehaviorSubject<SalesOrder>(null);
  private salesOrderResultSubject = new BehaviorSubject<SalesOrderResult>(null);
  private transactionalPassword: string;
  private medicalPrescriptionViewItemList: MedicalPrescriptionViewItem[] = [];
  private hasChanges: boolean = false;

  constructor(private appContext: AppContext, private purchaseRepository: PurchaseRepository) {}

  public setSalesOrder(salesOrder: SalesOrder): void {
    this.appContext.setSalesOrder(salesOrder);
    this.salesOrderSubject.next(salesOrder);
  }

  public getSalesOrder(): Observable<SalesOrder> {
    return this.salesOrderSubject.asObservable();
  }

  public hasPurchaseChanges(): boolean {
    return this.hasChanges;
  }

  public resetHasChanges() {
    this.hasChanges = false;
  }

  public getNetwork(): Network {
    return this.appContext.getNetwork();
  }

  public clearSalesOrder(): void {
    this.appContext.cleanSalesOrder();
    this.medicalPrescriptionViewItemList = [];
    this.hasChanges = true;
    this.salesOrderSubject.next(null);
  }

  public addShopper(shopper: Shopper): void {
    const salesOrder = new SalesOrder();
    this.medicalPrescriptionViewItemList = [];
    salesOrder.setShopper(shopper);
    this.hasChanges = true;
    this.appContext.setSalesOrder(salesOrder);
    this.salesOrderSubject.next(salesOrder);
  }

  public addSalesOrderItem(salesOrderItem: SalesOrderItem): void {
    const salesOrder = this.appContext.getSalesOrder();
    salesOrder.addSalesOrderItem(salesOrderItem);
    this.hasChanges = true;
    this.appContext.setSalesOrder(salesOrder);
    this.salesOrderSubject.next(salesOrder);
  }

  public removeSalesOrderItem(salesOrderItem: SalesOrderItem): void {
    const salesOrder = this.appContext.getSalesOrder();
    salesOrder.removeSalesOrderItem(salesOrderItem);
    this.hasChanges = true;
    this.appContext.setSalesOrder(salesOrder);
    this.salesOrderSubject.next(salesOrder);
  }

  public addSalesOrderItemQuantity(salesOrderItem: SalesOrderItem): void {
    const salesOrder = this.appContext.getSalesOrder();
    salesOrder.addSalesOrderItemQuantity(salesOrderItem);
    this.hasChanges = true;
    this.appContext.setSalesOrder(salesOrder);
    this.salesOrderSubject.next(salesOrder);
  }

  public removeSalesOrderItemQuantity(salesOrderItem: SalesOrderItem): void {
    const salesOrder = this.appContext.getSalesOrder();
    salesOrder.removeSalesOrderItemQuantity(salesOrderItem);
    this.hasChanges = true;
    this.appContext.setSalesOrder(salesOrder);
    this.salesOrderSubject.next(salesOrder);
  }

  public updateMedicalPrescription(salesOrderItem: SalesOrderItem, state: string, registration: number, medicalPrescriptionDate: string): void {
    const stateAcronymModel = new StateAcronymModel();
    stateAcronymModel.setValue(state);
    const practitionerRegistration = new PractitionerRegistration();
    practitionerRegistration.setState(stateAcronymModel);
    practitionerRegistration.setRegistration(registration);
    const salesOrder = this.appContext.getSalesOrder();
    salesOrder.updateMedicalPrescription(salesOrderItem, practitionerRegistration, medicalPrescriptionDate);
    this.hasChanges = true;
    this.appContext.setSalesOrder(salesOrder);
    this.salesOrderSubject.next(salesOrder);
  }

  public updateMedicalPrescriptionDocumentKey(medicalPrescriptionViewItem: MedicalPrescriptionViewItem) {
    const salesOrder = this.appContext.getSalesOrder();
    salesOrder.updateMedicalPrescriptionDocumentKey(medicalPrescriptionViewItem);
    this.hasChanges = true;
    this.appContext.setSalesOrder(salesOrder);
    this.salesOrderSubject.next(salesOrder);
  }

  public async emitPreviewPreSalesOrder(): Promise<SalesOrderResult> {
    const salesOrder = this.appContext.getSalesOrder();

    if (salesOrder.isValid()) {
      const shopper = salesOrder.getShopper();
      const network = this.appContext.getNetwork();
      const salesOrderResult = await this.purchaseRepository.emitPreviewPreSalesOrder(salesOrder, shopper, network);
      salesOrder.setTotalPrice(salesOrderResult.getTotalPrice());
      salesOrder.setErrorCauseList(salesOrderResult.getErrorCauseList());
      salesOrder.setStatus(salesOrderResult.getStatus());
      const resultItems = salesOrderResult.getSalesOrderItemResultList();
      for (let index = 0; index < resultItems.length; index++) {
        const resultItem = resultItems[index];
        const salesOrderItem = salesOrder.findItemByEan(resultItem.getProductResult().getEan());
        if (!!salesOrderItem) {
          salesOrderItem.copyFromResultItem(resultItem);
        }
      }
      this.hasChanges = true;
      this.appContext.setSalesOrder(salesOrder);
      this.salesOrderSubject.next(salesOrder);
      return salesOrderResult;
    } else {
      throw "Campos da prescrição médica inválidos.";
    }
  }

  public async emitPreSalesOrder(): Promise<SalesOrderResult> {
    const salesOrder = this.appContext.getSalesOrder();
    const shopper = salesOrder.getShopper();
    shopper.setTransactionalPassword(this.transactionalPassword);
    const network = this.appContext.getNetwork();
    const salesOrderResult = await this.purchaseRepository.emitPreSalesOrder(salesOrder, shopper, network);
    if (!!salesOrderResult && salesOrderResult.getUniqueSequentialNumber().getValue()) {
      this.hasChanges = true;
      this.appContext.setSalesOrderResult(salesOrderResult);
      this.salesOrderResultSubject.next(salesOrderResult);
    }
    return salesOrderResult;
  }

  public getSalesOrderResult(): Observable<SalesOrderResult> {
    return this.salesOrderResultSubject.asObservable();
  }

  public newPurchase(): void {
    const shopper = this.appContext.getSalesOrder().getShopper();
    const salesOrder = new SalesOrder();
    this.medicalPrescriptionViewItemList = [];
    salesOrder.setShopper(shopper);
    this.hasChanges = true;
    this.appContext.setSalesOrder(salesOrder);
    this.salesOrderSubject.next(salesOrder);
  }

  public endAttendance(): void {
    const salesOrder = new SalesOrder();
    this.medicalPrescriptionViewItemList = [];
    this.hasChanges = true;
    this.appContext.setSalesOrder(salesOrder);
    this.salesOrderSubject.next(salesOrder);
  }

  public getTransactionalPassword(): string {
    return this.transactionalPassword;
  }

  public setTransactionalPassword(transactionalPassword: string): void {
    this.transactionalPassword = transactionalPassword;
  }

  public getMedicalPrescriptionViewItemList(): MedicalPrescriptionViewItem[] {
    return this.medicalPrescriptionViewItemList;
  }

  public setMedicalPrescriptionViewItemList(medicalPrescriptionViewItemList: MedicalPrescriptionViewItem[]): void {
    this.medicalPrescriptionViewItemList = medicalPrescriptionViewItemList;
  }
}
