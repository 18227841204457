import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from "@angular/core";
import { UserCredentials } from "../../../../models/domains/credentials/user-credentials.model";

@Component({
  selector: "app-menu-account",
  templateUrl: "./menu-account.component.html",
  styleUrls: ["./menu-account.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MenuAccountComponent {
  @Input() userCredentials: UserCredentials = new UserCredentials();
  @Output() logOutAccount = new EventEmitter();
  @Output() changePasswordAccount = new EventEmitter();
  @Output() healthCheck = new EventEmitter();
  constructor() {}

  getUserName(): string {
    return this.userCredentials.userName;
  }

  logOut(): void {
    this.logOutAccount.emit();
  }

  changePassword(): void {
    this.changePasswordAccount.emit();
  }

  health(): void {
    this.healthCheck.emit();
  }
}
