import { Injectable } from "@angular/core";
import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { RuleEngineConfiguration } from "../../models/domains/pdv/rule-engine-configuration";
import { Shopper } from "../../models/domains/pdv/shopper";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { RuleEngineConfigurationLitsParser } from "../parsers/pdv/rule-engine-configuration-list.parser";

@Injectable()
export class RuleEngineConfigurationRepository {
  constructor(private remoteGatewayFactory: RemoteGatewayFactory, private ruleEngineConfigurationLitsParser: RuleEngineConfigurationLitsParser) {}

  public async getRuleEngineConfigurationList(shopper: Shopper): Promise<RuleEngineConfiguration[]> {
    const headers = { "Content-Type": "application/json" };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
            const payload = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/sales-order/'+ shopper.getVirtualCardNumber()+'/rule-engine-configuration'), headers);
      return await Promise.resolve(this.ruleEngineConfigurationLitsParser.parse(payload));
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
