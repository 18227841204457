import { EventEmitter, Inject } from "@angular/core";
import { Component } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  templateUrl: "./message-dialog.component.html",
  styleUrls: ["./message-dialog.component.scss"],
})
export class MessageDialog {
  public confirmDialogEvent = new EventEmitter();
  public cancelDialogEvent = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<MessageDialog>, @Inject(MAT_DIALOG_DATA) public data: IMessageDialogData) {}

  public getTitle(): string {
    return this.data.title;
  }

  public getText(): string {
    return this.data.text;
  }

  public getOkText(): string {
    return this.data.okText ? this.data.okText : "OK";
  }

  public closeDialog(): void {
    this.cancelDialogEvent.emit();
    this.dialogRef.close();
  }

  public emitConfirmEvent() {
    this.confirmDialogEvent.emit();
    this.dialogRef.close();
  }
}

export interface IMessageDialogData {
  title: string;
  text: string;
  type: "alert" | "error";
  okText?: string;
}
