import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { TermForm } from "src/app/ui/models/domains/pdv/form-term";
import { Network } from "src/app/ui/models/domains/pdv/network";
import { LoadingService } from "src/app/ui/services/loading.service";
import { AcceptTermService } from "src/app/ui/services/pdv/accept-term.service";
import { NetworkService } from "src/app/ui/services/pdv/network.service";
import { SnackbarService } from "src/app/ui/services/snackbar.service";

@Component({
  selector: "app-edit-term-modal",
  templateUrl: "./edit-term-modal.component.html",
  styleUrls: ["./edit-term-modal.component.scss"],
})
export class EditTermModalComponent implements OnInit {
  network: Network;
  termForm: TermForm;

  constructor(
    private snackbarServ: SnackbarService,
    private termService: AcceptTermService,
    private loadingService: LoadingService,
    private networkService: NetworkService,
    public dialogRef: MatDialogRef<EditTermModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.network = this.networkService.getNetwork();
  }

  async ngOnInit() {
    const load = this.loadingService.beginLoading();
    try {
      const fiscalNumber = this.networkService.getNetwork().getFiscalNumber();
      this.termForm = await this.termService.getTermForm(fiscalNumber);
    } catch (error) {
      this.snackbarServ.showError("Não foi possível buscar as informações.");
      this.close();
    } finally {
      this.loadingService.finishLoading(load);
    }
  }

  successSubmit(): void {
    this.snackbarServ.showSuccess("Atualizado com sucesso.");
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  isHeadOfficeNetwork(): boolean {
    return this.network && this.network.isHeadOffice();
  }
}
