import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class DateService {
  private currentDate = new Date();

  getCurrentDay(): string {
    return this.currentDate.getDate().toString();
  }

  getCurrentMonth(): string {
    switch (this.currentDate.getMonth()) {
      case 0:
        return "Janeiro";
      case 1:
        return "Fevereiro";
      case 2:
        return "Março";
      case 3:
        return "Abril";
      case 4:
        return "Maio";
      case 5:
        return "Junho";
      case 6:
        return "Julho";
      case 7:
        return "Agosto";
      case 8:
        return "Setembro";
      case 9:
        return "Outubro";
      case 10:
        return "Novembro";
      case 11:
        return "Dezembro";
      default:
        return "";
    }
  }

  getCurrentYear(): string {
    return this.currentDate.getFullYear().toString();
  }

  getCurrentWeekDay(): string {
    switch (this.currentDate.getDay()) {
      case 0:
        return "Domingo";
      case 1:
        return "Segunda-Feira";
      case 2:
        return "Terça-Feira";
      case 3:
        return "Quarta-Feira";
      case 4:
        return "Quinta-Feira";
      case 5:
        return "Sexta-Feira";
      case 6:
        return "Sábado";
    }
  }
}
