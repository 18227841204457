export class Term {
  constructor(private termKey: string, private contentTerm: string) {}

  getTermKey(): string {
    return this.termKey;
  }

  getContentTerm(): string {
    return this.contentTerm;
  }
}
