import { ModuleWithProviders, NgModule } from "@angular/core";
import { AuthRepository } from "./auth.repository";
import { ParserModule } from "./parsers/parser.module";
import { GatewayModule } from "../gateways/gateway.module";
import { UserRepository } from "./user.repository";
import { ResetPasswordRepository } from "./reset-password.repository";
import { HealthRepository } from "./health.repository";
import { NewsRepository } from "./news.repository";
import { ShopperRepository } from "./pdv/shopper.repository";
import { AcceptTermRepository } from "./pdv/accept-term.repository";
import { ProductRepository } from "./pdv/product.repository";
import { BenefitRepository } from "./pdv/benefit.repository";
import { StateRepository } from "./pdv/state.repository";
import { MedicalPrescriptionRepository } from "./pdv/medical-prescription.repository";
import { PurchaseRepository } from "./pdv/purchase.repository";
import { RuleEngineConfigurationRepository } from "./pdv/rule-engine-configuration.repository";
import { SalesRepository } from "./pdv/sales.repository";
import { NetworkRepository } from "./pdv/network.repository";
import { SearchFinancialStatementsParse } from "./parsers/pdv/search-financial-statement.parse";
import { FileExportRepository } from "./pdv/file-export.repository";
import { GetFileExportStatusParser } from "./parsers/pdv/get-file-export-status.parser";
import { ExportFileParser } from "./parsers/pdv/export-file.parser";
import { ExportFileAsXLSXParser } from "./parsers/pdv/export-file-as-xlsx.parser";
import { IdpNetworkRepository } from "./idp-network.repository";
import { IndustryProgramsRepository } from "./pdv/industry-programs.repository";

@NgModule({
  imports: [ParserModule.forRoot(), GatewayModule.forRoot()],
})
export class RepositoryModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RepositoryModule,
      providers: [
        AuthRepository,
        UserRepository,
        ResetPasswordRepository,
        HealthRepository,
        IdpNetworkRepository,
        NewsRepository,
        AcceptTermRepository,
        ShopperRepository,
        ProductRepository,
        BenefitRepository,
        StateRepository,
        MedicalPrescriptionRepository,
        PurchaseRepository,
        RuleEngineConfigurationRepository,
        SalesRepository,
        NetworkRepository,
        FileExportRepository,
        GetFileExportStatusParser,
        ExportFileAsXLSXParser,
        ExportFileParser,
        SearchFinancialStatementsParse,
        IndustryProgramsRepository
      ],
    };
  }
}
