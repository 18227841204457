import { Router } from "@angular/router";
import { AccessErrorCause } from "../models/domains/pdv/enum/access-error-type.enum";
import { RoutePath } from "../models/domains/route-paths.model";
import { IRouteStrategy } from "./route-strategy";

export class AccessErrorStrategy implements IRouteStrategy {
  constructor(private router: Router, private accessErrorCause: AccessErrorCause) {}

  redirect(): void {
    this.router.navigate([RoutePath.AccessError], {
      state: {
        error: this.accessErrorCause,
      },
    });
  }
}
