export class SelectedRowOnPage {
  private selected: Array<Object>;

  constructor() {
    this.selected = new Array<Object>();
  }

  getSelected(): Array<Object> {
    return this.selected;
  }

  select(value: Object) {
    this.selected.push(value);
  }

  selectAny(values: Array<Object>) {
    this.selected.concat(values);
  }

  deselect(value: Object) {
    this.selected.splice(this.getSelectedIndex(value), 1);
  }

  deselectAny(values: Array<Object>) {
    values.forEach((value) => {
      this.deselect(value);
    });
  }

  getValues(): Array<Object> {
    return this.selected;
  }

  clear(): void {
    this.selected = new Array<Object>();
  }

  hasValue(): boolean {
    return this.selected.length > 0;
  }

  private getSelectedIndex(value: Object): number {
    return this.selected.findIndex((select) => JSON.stringify(select) === JSON.stringify(value));
  }
}
