import { Injectable } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material";
import { ModalComponent } from "../components/modal/modal.component";
import { EventEmitter } from "events";
import { EditTermModalComponent } from "../components/pdv/terms/edit-term-modal/edit-term-modal.component";
import { NavigatorService } from "./pdv/navigator.service";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private modals: any[] = [];
  private dialog: MatDialog;
  private modalComponentDialogRef: MatDialogRef<ModalComponent>;

  add(modal: any): void {
    this.modals.push(modal);
  }

  remove(id: string): void {
    this.modals = this.modals.filter((x) => x.modalInfo.ModalId !== id);
  }

  open(options: any): void {
    this.modalComponentDialogRef = this.dialog.open(ModalComponent, options);
  }

  getModalComponentDialogRef(): MatDialogRef<ModalComponent> {
    return this.modalComponentDialogRef;
  }

  setDialog(dialog: MatDialog): void {
    this.dialog = dialog;
  }

  setModalComponenteDialogRef(modalComponentDialogRef: MatDialogRef<ModalComponent>): void {
    this.modalComponentDialogRef = modalComponentDialogRef;
  }

  close(): void {
    this.modalComponentDialogRef.close();
    this.modalComponentDialogRef = null;
  }

  public openTermFormModal(dialog: MatDialog, isInternetExplorer: boolean): void {
    try {
      if (isInternetExplorer) {
        dialog.open(EditTermModalComponent, { maxHeight: "90vh", width: "auto", maxWidth: "95vw" });
      } else {
        dialog.open(EditTermModalComponent, { maxHeight: "90vh", maxWidth: "95vw" });
      }
    } catch (error) {}
  }
}
