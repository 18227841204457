import { ModuleWithProviders, NgModule } from "@angular/core";

import { ScsAuthorizationTokenParser } from "./scs-authorizatio-token.parser";
import { UserCredentialsParser } from "./user-credentials.parser";
import { HealthParser } from "./health.parser";
import { UserCredentialsActivationParser } from "./user-credentials-activation.parser";
import { PowerBiReportParser } from "./powerbi-report-parser";
import { NewsParser } from "./news.parser";
import { NetworkParser } from "./pdv/network.parser";
import { SearchShopperByParser } from "./pdv/search-shopper-by.parser";
import { SearchFinancialBalanceBySocialNumberParser } from "./pdv/search-financial-balance-by-social-number.parser";
import { NetworkAddressParser } from "./pdv/network-address.parser";
import { TermParser } from "./pdv/term.parser";
import { NetworkAcceptTermParser } from "./pdv/network.accept.term.parser";
import { BenefitConfigurationParser } from "./pdv/benefit-configuration.parser";
import { ProductConfigurationParser } from "./pdv/product-configuration.parser";
import { FindProductSimpleListParser } from "./pdv/find-product-simple-list.parser";
import { FindProductByEanParser } from "./pdv/find-product-by-ean.parser";
import { ProductTypeParser } from "./pdv/product-type.parser";
import { ProductSubtypeParser } from "./pdv/product-subtype.parser";
import { ProductCategoryParser } from "./pdv/product-category.parser";
import { ResponseErrorParser } from "./pdv/response-error.parser";
import { StateAcronymParser } from "./pdv/state-acronym-parser";
import { MedicalPrescriptionDocumentKeyParser } from "./pdv/medical-prescription-document-key-parser";
import { DoesProductRequireScanParser } from "./pdv/does-product-require-scan.parser";
import { EmitPreSalesOrderParser } from "./pdv/emit-pre-sales-order.parser";
import { TermFormParser } from "./pdv/term-form.parser";
import { SalesOrderParser } from "./pdv/sales-order.parser";
import { RuleEngineConfigurationLitsParser } from "./pdv/rule-engine-configuration-list.parser";
import { SalesParser } from "./pdv/sales.parser";

@NgModule({})
export class ParserModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ParserModule,
      providers: [
        ScsAuthorizationTokenParser,
        UserCredentialsParser,
        HealthParser,
        UserCredentialsActivationParser,
        PowerBiReportParser,
        NewsParser,
        NetworkParser,
        NetworkAddressParser,
        TermParser,
        NetworkAcceptTermParser,
        SearchShopperByParser,
        SearchFinancialBalanceBySocialNumberParser,
        BenefitConfigurationParser,
        ProductConfigurationParser,
        FindProductSimpleListParser,
        FindProductByEanParser,
        ProductTypeParser,
        ProductSubtypeParser,
        ProductCategoryParser,
        ResponseErrorParser,
        StateAcronymParser,
        MedicalPrescriptionDocumentKeyParser,
        DoesProductRequireScanParser,
        SalesOrderParser,
        EmitPreSalesOrderParser,
        TermFormParser,
        RuleEngineConfigurationLitsParser,
        SalesParser,
      ],
    };
  }
}
