import { NgModule } from "@angular/core";
import { CoreModule } from "../core/core.module";
import { MaskDirective } from "./mask.directive";

@NgModule({
  imports: [CoreModule],
  declarations: [MaskDirective],
  exports: [MaskDirective],
})
export class DirectiveModule {
  static forRoot() {
    return {
      ngModule: DirectiveModule,
      providers: [],
    };
  }
}
