export class MatSvgIconTemplate {
  constructor(id: string, matSvgIconName: string, iconToolTip: string) {
    this.id = id;
    this.matSvgIconName = matSvgIconName;
    this.iconToolTip = iconToolTip;
  }

  private id: string;
  private matSvgIconName: string;
  private iconToolTip: string;

  getId(): string {
    return this.id;
  }

  getMatSvgIconName(): string {
    return this.matSvgIconName;
  }

  getIconTooltip(): string {
    return this.iconToolTip;
  }
}
