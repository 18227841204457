import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from "@angular/cdk/portal";
import { Component, ComponentRef, EmbeddedViewRef, ViewChild } from "@angular/core";

@Component({
  selector: "full-dialog",
  template: "<ng-container cdkPortalOutlet></ng-container>",
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent extends BasePortalOutlet {
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;

  constructor() {
    super();
  }

  attachComponentPortal<T>(componentPortal: ComponentPortal<any>): ComponentRef<T> {
    return this.portalOutlet.attachComponentPortal(componentPortal);
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    return this.portalOutlet.attachTemplatePortal(portal);
  }
}
