import { BeneficiaryKey } from "./beneficiary-key";
import { BenefitConfiguration } from "./Benefit/benefit-configuration";
import { IBlockItem } from "./interfaces/iblock-list-item";
import { ProductBlockItem } from "./Benefit/product-block-item";
import { ProductClusterExceptInfo } from "./Benefit/product-cluster-except-info";
import { ProductConfiguration } from "./Benefit/product-configuration";
import { ProductConfigurationCluster } from "./Benefit/product-configuration-cluster";
import { ProductSubtype } from "./Benefit/product-subtype";
import { ProductType } from "./Benefit/product-type";
import { TherapeuticClassBlockItem } from "./Benefit/therapeutic-class-block-item";
import { Company } from "./company";
import { Ean } from "./ean";
import { FinancialBalance } from "./financial-balance";
import { FiscalNumber } from "./fiscal-number";
import { MedicalPrescription } from "./medical-prescription";
import { MedicalPrescriptionDocumentKey } from "./medical-prescription-document-key";
import { PractitionerRegistration } from "./practitioner-registration";
import { Product } from "./product";
import { SalesOrder } from "./sales-order";
import { SalesOrderItem } from "./sales-order-item";
import { Shopper } from "./shopper";
import { SocialNumber } from "./social-number";
import { StateAcronymModel } from "./state-acronym-model";
import { ErrorCause } from "./error-cause";
import { ProgressiveDiscount } from "./progressive-discount";
import { IConfigMedicalPrescription } from "src/app/ui/repositories/parsers/pdv/does-product-require-scan.parser";

export class SalesOrderFactory {
  static createSalesOrder(payload: any): SalesOrder {
    const salesOrder = new SalesOrder();
    if (payload.salesOrderItemList.length > 0) {
      salesOrder.setSalesOrderItemList(this.buildSalesOrderItemList(payload.salesOrderItemList));
    } else {
      salesOrder.setSalesOrderItemList([]);
    }
    if (payload.shopper) {
      salesOrder.setShopper(this.buildShopper(payload.shopper));
    } else {
      salesOrder.setShopper(null);
    }
    salesOrder.setErrorCauseList(this.buildErrorCauseList(payload.errorCauseList));
    salesOrder.setStatus(payload.status);
    return salesOrder;
  }

  static buildSalesOrderItemList(payload: any): Array<SalesOrderItem> {
    const salesOrderItemList: Array<SalesOrderItem> = payload.map((item) => {
      const configMedicalPrescription: IConfigMedicalPrescription = {
        enabled: item.configMedicalPrescription.enabled,
        requiredScan: item.configMedicalPrescription.requiredScan,
        purchaseRequiredScan: item.configMedicalPrescription.purchaseRequiredScan,
      };
      const salesOrderItem = new SalesOrderItem(this.buildProduct(item.product), configMedicalPrescription);
      salesOrderItem.setProductQuantity(item.productQuantity);
      salesOrderItem.setNetworkPrice(item.networkPrice);
      if (item.medicalPrescription) {
        salesOrderItem.setMedicalPrescription(this.buildMedicalPrescription(item.medicalPrescription));
      } else {
        salesOrderItem.setMedicalPrescription(null);
      }
      salesOrderItem.setErrorCauseList(this.buildErrorCauseList(item.errorCauseList));
      salesOrderItem.setStatus(item.status);
      return salesOrderItem;
    });
    return salesOrderItemList;
  }

  static buildProduct(payload: any): Product {
    const product = new Product();
    product.setName(payload.name);
    product.setEan(new Ean(payload.ean.value));
    product.setLaboratory(payload.laboratory);
    product.setPresentation(payload.presentation);
    product.setSubstance(payload.substance);
    product.setType(payload.type);
    product.setSubType(payload.subType);
    product.setNetPrice(payload.netPrice);
    product.setGrossPrice(payload.grossPrice);
    product.setNetworkPrice(payload.networkPrice);
    product.setPriceRegime(payload.priceRegime);
    product.setPmcPrice(payload.pmcPrice);
    product.setPrice(payload.price);
    product.setUnitGrossPrice(payload.unitGrossPrice);
    product.setUnitPrice(payload.unitPrice);
    product.setProductSubsidy(payload.productSubsidy);
    product.setDiscount(payload.discount);
    product.setProgressiveDiscountList(this.buildProgressiveDiscount(payload.progressiveDiscountList));
    product.setExternalAuthorizerDiscount(payload.externalAuthorizerDiscount);
    product.setHasExternalAuthorizerBetterDiscount(payload.hasExternalAuthorizerBetterDiscount);
    product.setHasMedicalPrescription(payload.hasMedicalPrescription);
    product.setIndustryProgramAdhered(payload.industryProgramAdhered);
    product.setUsedNetworkPrice(payload.usedNetworkPrice);
    product.setUsedNetworkNetPrice(payload.usedNetworkNetPrice);
    product.setUsedNetworkGrossPrice(payload.usedNetworkGrossPrice);
    product.setSubsidyAllowed(payload.subsidyAllowed);
    
    if(payload.usedNetworkSellerPrice){
      product.setSellerUnitPrice(payload.sellerUnitPrice);
      product.setSellerUnitGrossPrice(payload.sellerUnitGrossPrice);
    }
    product.setAuthorizedQuantity(payload.authorizedQuantity);
    product.setCanInputPrice(payload.canInputPrice);

    return product;
  }

  static buildProgressiveDiscount(payload: any): ProgressiveDiscount[] {
    const discountList: ProgressiveDiscount[] = payload.map((item) => {
      return new ProgressiveDiscount(item.productQuantity, item.discount);
    });
    return discountList;
  }

  static buildMedicalPrescription(payload: any): MedicalPrescription {
    const medicalPrescription = new MedicalPrescription();
    medicalPrescription.setQuantity(payload.quantity);
    medicalPrescription.setMedicalPrescriptionDate(payload.medicalPrescriptionDate);
    medicalPrescription.setPractitionerRegistration(this.buildPractitionerRegistration(payload.practitionerRegistration));
    medicalPrescription.setMedicalPrescriptionDocumentKey(this.buildMedicalPrescriptionDocumentKey(payload.medicalPrescriptionDocumentKey));
    return medicalPrescription;
  }

  static buildPractitionerRegistration(payload: any): PractitionerRegistration {
    const practitionerRegistration = new PractitionerRegistration();
    practitionerRegistration.setRegistration(payload ? payload.registration : null);
    practitionerRegistration.setState(this.buildState(payload ? payload.state : null));
    return practitionerRegistration;
  }

  static buildState(payload: any): StateAcronymModel {
    const state = new StateAcronymModel();
    state.setValue(payload ? payload.value : null);
    return state;
  }

  static buildMedicalPrescriptionDocumentKey(payload: any): MedicalPrescriptionDocumentKey {
    const medicalPrescriptionDocumentKey = new MedicalPrescriptionDocumentKey();
    medicalPrescriptionDocumentKey.setValue(payload ? payload.value : null);
    return medicalPrescriptionDocumentKey;
  }

  static buildShopper(payload: any): Shopper {
    const shopper = new Shopper();
    payload.active ? shopper.activate() : shopper.deactivate();
    shopper.setCompany(this.buildCompany(payload.company));
    shopper.setBeneficiaryKey(new BeneficiaryKey(payload.beneficiaryKey.value));
    shopper.setFinancialBalance(this.buildFinancialBalance(payload.financialBalance));
    shopper.setName(payload.name);
    shopper.setSocialNumber(new SocialNumber(payload.socialNumber.value));
    shopper.setVirtualCardNumber(payload.virtualCardNumber);
    shopper.setHaveTransactionalPassword(payload.haveTransactionalPassword);
    shopper.setTransactionalPassword(payload.transactionalPassword);
    shopper.setBenefitConfiguration(this.buildBenefitConfiguration(payload.benefitConfiguration));
    return shopper;
  }

  static buildCompany(payload: any): Company {
    const company = new Company();
    if (payload) {
      company.setCorporateName(payload ? payload.corporateName : null);
      company.setFiscalNumber(payload ? new FiscalNumber(payload.fiscalNumber.value) : null);
    }
    return company;
  }

  static buildFinancialBalance(payload: any): FinancialBalance {
    const financialBalance = new FinancialBalance();
    if (payload) {
      financialBalance.setAvailable(payload.available);
      financialBalance.setCycleClosedDay(payload.cycleClosedDay);
      financialBalance.setLimit(payload.limit);
      financialBalance.setPayroll(payload.payroll);
      financialBalance.setSales(payload.sales);
      financialBalance.setSubsidy(payload.subsidy);
    }
    return financialBalance;
  }

  static buildBenefitConfiguration(payload: any): BenefitConfiguration {
    const benefitConfiguration = new BenefitConfiguration();
    if (payload) {
      benefitConfiguration.setId(payload.id);
      benefitConfiguration.setCompanyFiscalNumber(new FiscalNumber(payload.companyFiscalNumber.value));
      benefitConfiguration.setProductConfiguration(this.buildProductConfigurationCluster(payload.productConfiguration));
    }
    return benefitConfiguration;
  }

  static buildProductConfigurationCluster(payload: any): ProductConfigurationCluster {
    const productConfigurationCluster = new ProductConfigurationCluster();
    if (payload) {
      productConfigurationCluster.setKey(payload.key);
      productConfigurationCluster.setCompanyFiscalNumber(new FiscalNumber(payload.companyFiscalNumber.value));
      productConfigurationCluster.setProductMaxCount(payload.productMaxCount);
      productConfigurationCluster.setProductConfiguration(this.buildProductConfigurationList(payload.productConfiguration));
      productConfigurationCluster.setProductClusterExceptInfo(this.buildProductClusterExceptInfo(payload.productClusterExceptInfo));
      productConfigurationCluster.setBlockListConfiguration(this.buildBlockListConfiguration(payload.blockListConfiguration));
    }
    return productConfigurationCluster;
  }

  static buildProductConfigurationList(payload: any): ProductConfiguration[] {
    let productConfigurationList = new Array<ProductConfiguration>();
    if (payload) {
      productConfigurationList = payload.map((item) => {
        const productConfigurationList = new ProductConfiguration();
        productConfigurationList.setProductType(this.buildProductType(item.productType));
        productConfigurationList.setProductSubType(this.buildProductSubtype(item.productSubType));
        productConfigurationList.setPurchaseEnabled(item.purchaseEnabled);
        productConfigurationList.setSubsidyEnabled(item.subsidyEnabled);
        productConfigurationList.setSubsidy(item.subsidy);
        return productConfigurationList;
      });
    }
    return productConfigurationList;
  }

  static buildProductType(payload: any): ProductType {
    const productType = new ProductType();
    productType.setKey(payload.key);
    productType.setValue(payload.value);
    return productType;
  }

  static buildProductSubtype(payload: any): ProductSubtype {
    const productSubtype = new ProductSubtype();
    productSubtype.setKey(payload.Key);
    productSubtype.setValue(payload.Value);
    return productSubtype;
  }

  static buildProductClusterExceptInfo(payload: any): ProductClusterExceptInfo {
    const productClusterExpectInfo = new ProductClusterExceptInfo();
    productClusterExpectInfo.setAllowedPurchase(payload.allowedPurchase);
    productClusterExpectInfo.setAllowedCheckoutOnline(payload.allowedCheckoutOnline);
    productClusterExpectInfo.setAllowedFullEmploymentPayroll(payload.allowedFullEmploymentPayroll);
    return productClusterExpectInfo;
  }

  static buildBlockListConfiguration(payload: any): IBlockItem[] {
    const blockItemList: IBlockItem[] = payload.map((item) => {
      if (item.blockListItemType === 1) {
        const productBlockItem = new ProductBlockItem();
        productBlockItem.setEan(item.ean);
        productBlockItem.setProductKey(item.productKey);
        productBlockItem.setProductName(item.productName);
        return productBlockItem;
      } else if (item.blockListItemType === 2) {
        const therapeuticClassBlockItem = new TherapeuticClassBlockItem();
        therapeuticClassBlockItem.setCode(item.code);
        therapeuticClassBlockItem.setDescription(item.description);
        therapeuticClassBlockItem.setTherapeuticClassKey(item.therapeuticClassKey);
        return therapeuticClassBlockItem;
      }
    });
    return blockItemList;
  }

  static buildErrorCauseList(payload: any): ErrorCause[] {
    const errorCauseList: ErrorCause[] = [];
    payload.map((item) => {
      const errorCause = new ErrorCause();
      errorCause.setErrorCode(item.errorCode);
      errorCause.setErrorException(item.errorException);
      errorCause.setErrorMessage(item.errorMessage);
      errorCause.setTypeSalesOrderMessage(item.typeSalesOrderMessage);
      errorCauseList.push(errorCause);
    });
    return errorCauseList;
  }
}
