export class ProgressiveDiscount {
  private readonly productQuantity: number;
  private readonly discount: number;

  constructor(productQuantity: number, discount: number) {
    this.productQuantity = productQuantity;
    this.discount = discount;
  }

  getProductQuantity(): number {
    return this.productQuantity;
  }

  getDiscount(): number {
    return this.discount;
  }
}
