import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { PageableResult } from "../../models/domains/paginator/pageable-result";
import { GridRow } from "../../models/domains/grids/grid-row.model";
import { Router } from "@angular/router";
import { GridService } from "../../services/grid.service";
import { MatPaginator, MatSort } from "@angular/material";
import { tap } from "rxjs/operators";
import { PagingInfo } from "../../models/domains/paginator/paging-info.model";
import { Direction } from "../../models/domains/paginator/direction.enum";
import { Sort as CustomSort } from "../../models/domains/sort/sort";
import { GridColumn } from "../../models/domains/grids/grid-column";
import { IGridColumnDisplayable } from "../../models/domains/grids/displayable-grid-column";
import { DomSanitizer } from "@angular/platform-browser";
import { MultiIconGridColumn } from "../../models/domains/grids/multi-icon-grid-column.model.model";
import { MatSvgIconTemplate } from "../../models/domains/grids/mat-svg-icon-template.model";

@Component({
  selector: "grid",
  templateUrl: "./grid.component.html",
  styleUrls: ["./grid.component.scss"],
})
export class GridComponent implements OnInit {
  private gridRows: Array<GridRow>;
  private element: HTMLElement;

  @Input() dataSource: PageableResult;
  @Input() columns: Array<GridColumn>;
  @Input() paginationEnabled: boolean = true;
  @Input() numberOptions: Array<number> = [10, 25, 50];
  @Input() pageSize: number = 10;

  @Output() onPaging: EventEmitter<PagingInfo> = new EventEmitter();
  @Output() onSorting: EventEmitter<CustomSort> = new EventEmitter();
  @Output() onModalOpennig: EventEmitter<any> = new EventEmitter();

  dataSourceEmpty: any = [];
  displayedColumns: Array<object> = new Array<Object>();
  columnsMatTable: Array<Object> = [];
  currentPage: number = 1;
  maxPageLinkNumber: number = 5;
  initialPage: number = 1;
  pageLinks = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private router: Router, private gridService: GridService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.gridRows = new Array<GridRow>();

    for (let c = 0; c < this.columns.length; c++) {
      this.columnsMatTable.push(this.columns[c].renderByMaterialTable());
    }

    this.displayedColumns = this.columnsMatTable.map((x) => x["columnDef"]);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.onPageChange())).subscribe();

    this.sort.sortChange.pipe(tap(() => this.onSortChange())).subscribe();

    this.gridService.setPaginator(this.paginator);
  }

  ngOnChanges() {
    if (this.paginator) {
      this.paginator.pageIndex = this.gridService.calculatePageIndex(this.dataSource);
      this.gridService.setPaginator(this.paginator);
    }
  }

  fireAction(column: object, item: any, matSvgIconTemplate?: MatSvgIconTemplate): void {
    let gridColumn = column["customColumn"] as GridColumn;
    if (gridColumn && gridColumn.isIcon()) {
      if (!gridColumn.isModalgridColumnActionType()) {
        this.router.navigate([gridColumn.getNavigateRoute()], { queryParams: { p: gridColumn.evaluate(item) } });
      } else if (matSvgIconTemplate) {
        let result = {};
        result["row"] = item;
        result["iconTemplate"] = matSvgIconTemplate;
        this.onModalOpennig.emit(JSON.stringify(result));
      } else {
        this.onModalOpennig.emit(JSON.stringify(item));
      }
    }
  }

  getIconGridColumnUrl(column: Object): string {
    let gridColumn = column["customColumn"] as GridColumn;
    if (gridColumn && gridColumn.isIcon()) {
      return gridColumn.getIconUrl();
    }
    return "";
  }

  hideIconRow(rowIndex: number, binding: string, matSvgIconTemplate?: MatSvgIconTemplate): void {
    matSvgIconTemplate
      ? (this.element = document.getElementById(`button_icon_${binding}_${matSvgIconTemplate.getId()}_${rowIndex}`))
      : (this.element = document.getElementById(`button_icon_${binding}_${rowIndex}`));

    this.element.style.display = "none";
  }

  changeRowColor(rowIndex: number, color: string): void {
    this.element = document.getElementById(`row_${rowIndex}`);
    this.element.style.backgroundColor = color;
  }

  getSvgIconGridColumn(column: Object): string {
    let gridColumn = column["customColumn"] as GridColumn;
    if (gridColumn && gridColumn.isIcon()) {
      return gridColumn.getIconSvg();
    }
    return "";
  }

  getSizeColumn(column: object): string {
    let gridColumn = column["customColumn"] as GridColumn;
    return gridColumn.getSize();
  }

  getIconGridColumnToolTip(column: Object): string {
    let gridColumn = column["customColumn"] as GridColumn;
    if (gridColumn && gridColumn.isIcon()) {
      return gridColumn.getIconTooltip();
    }
    return "";
  }

  isHidden(column: Object): boolean {
    let gridColumn = column["customColumn"] as GridColumn;
    return gridColumn && gridColumn.isHidden();
  }

  isIconUrl(column: Object): boolean {
    let gridColumn = column["customColumn"] as GridColumn;
    return gridColumn && gridColumn.isIcon() && gridColumn.isIconUrl();
  }

  isIconSvg(column: Object): boolean {
    let gridColumn = column["customColumn"] as GridColumn;
    return gridColumn && gridColumn.isIcon() && gridColumn.isIconSvg();
  }

  isMultiIconGridColumn(column: GridColumn): boolean {
    if (column instanceof MultiIconGridColumn) {
      let gridColumn = column as MultiIconGridColumn;
      return gridColumn.getMatSvgIconsRegistred() && gridColumn.getMatSvgIconsRegistred().length > 0;
    }

    return false;
  }

  getFormattedColumnText(column: object, columnData: Object): string {
    let gridColumn = column["customColumn"] as GridColumn;
    if (column && columnData) {
      return gridColumn.evaluate(columnData);
    }
    return "";
  }

  getFooterValue(column: object): string {
    let gridColumn = column["customColumn"] as GridColumn;
    const value = this.dataSource
      .getSummaryFooter()
      .getItems()
      .find((s) => s.getName() === gridColumn.binding);
    return value ? value.getValue() : "";
  }

  hasSummaryFooter(): boolean {
    let summaryFooter = this.dataSource.getSummaryFooter();
    if (summaryFooter) {
      let gridColumnsBindings = this.columnsMatTable.filter((c) => !this.isHidden(c)).map((x) => x["customColumn"].binding as string);
      let summaryFooterNames = summaryFooter.getItems().map((s) => s.getName());

      return summaryFooterNames.some((name) => gridColumnsBindings.indexOf(name) > -1);
    }

    return false;
  }

  onPageChange(): void {
    let pagingInfo = new PagingInfo();
    let itemsPerPage = this.dataSource.getItemsPerPage();
    if (this.paginator.pageSize != itemsPerPage) {
      this.dataSource.setStart(1);
      this.gridService.setCurrentPage(1);
      this.dataSource.setEnd(this.paginator.pageSize);
      pagingInfo.start = 1;
      pagingInfo.end = this.paginator.pageSize;
      pagingInfo.pageIndex = this.paginator.pageIndex + 1;
    } else {
      let page = this.paginator.pageIndex + 1;
      let start = page * this.paginator.pageSize - (this.paginator.pageSize - 1);
      let end = start + (this.paginator.pageSize - 1);
      pagingInfo.start = start;
      pagingInfo.end = end;
      pagingInfo.pageIndex = page;
    }
    this.onPaging.emit(pagingInfo);
  }

  onSortChange(): void {
    let gridColumn = this.columns.find((element) => {
      return element.display.includes(this.sort.active);
    });
    if (gridColumn) {
      let propertyName = gridColumn.binding;
      if (this.sort.direction.includes("asc")) {
        this.onSorting.emit(new CustomSort(propertyName, Direction.Asc));
      } else {
        this.onSorting.emit(new CustomSort(propertyName, Direction.Desc));
      }
    }
  }

  isSortingDisabled(column: object): boolean {
    if (column) {
      let gridColumn: GridColumn = column["customColumn"];
      if (gridColumn) {
        return !gridColumn.orderBy;
      }
    }
    return false;
  }

  canDisplayIcon(row: object): boolean {
    try {
      let item: IGridColumnDisplayable = row as IGridColumnDisplayable;
      return item && item.canDisplay();
    } catch (error) {
      return true;
    }
  }
}
