import { Url } from "./url";
import { environment } from "../../../../../environments/environment";

export class NetworkServiceUrl extends Url {
  constructor(private action: string) {
    super(action);
  }

  getUrl(): string {
    return environment.networkServiceUrl + this.action;
  }
}
