import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material";
import * as moment from "moment";
import { Observable } from "rxjs";
import { AppContext } from "src/app/ui/contexts/app-context";
import { ConsultSalesPagingRequest } from "src/app/ui/models/domains/paginator/consult-sales-paging-request";
import { Card } from "src/app/ui/models/domains/pdv/card";
import { FiscalNumber } from "src/app/ui/models/domains/pdv/fiscal-number";
import { MaskInput } from "src/app/ui/models/domains/pdv/mask-input";
import { SalesOrder } from "src/app/ui/models/domains/pdv/sales-order";
import { Shopper } from "src/app/ui/models/domains/pdv/shopper";
import { SocialNumber } from "src/app/ui/models/domains/pdv/social-number";
import { UniqueSequentialNumber } from "src/app/ui/models/domains/pdv/unique-sequential-number";
import { LoadingService } from "src/app/ui/services/loading.service";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";
import { SalesService } from "src/app/ui/services/pdv/sales.service";
import { ShopperService } from "src/app/ui/services/pdv/shopper.service";
import { ConfirmDialog } from "../../../dialog/confirm-dialog/confirm-dialog.component";
import { Toast } from "../../../toast/toast";

@Component({
  selector: "pdv-sales-search-bar",
  templateUrl: "./sales-search-bar.component.html",
  styleUrls: ["./sales-search-bar.component.scss"],
})
export class SalesSearchBarComponent implements OnInit {
  searchFormGroup: FormGroup = new FormGroup({
    socialNumber: new FormControl(""),
    uniqueSequentialNumber: new FormControl(""),
    cardNumber: new FormControl(""),
    transactionDate: new FormControl(""),
  });

  socialNumberMask = MaskInput.getSocialNumberMask();
  uniqueSequentialNumberMask = MaskInput.getUniqueSequentialNumberMask();
  cardNumberMask = MaskInput.getCardNumberMask();
  dateMask = MaskInput.getDateMask();

  constructor(private loadingService: LoadingService, private toast: Toast, private salesService: SalesService, private appContext: AppContext) {}

  ngOnInit(): void {
    this.salesService.clearResults();
  }

  async searchSales() {
    if (this.validForm()) {
      const loading = this.loadingService.beginLoading();
      try {
        const consultSalesPagingRequest = new ConsultSalesPagingRequest();

        consultSalesPagingRequest.setNetworkFiscalNumber(this.appContext.getNetwork().getFiscalNumber());

        this.checkSequentialNumber();

        const uniqueSequentialNumber = new UniqueSequentialNumber();
        uniqueSequentialNumber.setValue(this.searchFormGroup.get("uniqueSequentialNumber").value.replace(/[^\d]+/g, "") || null);
        consultSalesPagingRequest.setUniqueSequentialNumber(uniqueSequentialNumber);

        const socialNumber = new SocialNumber(this.searchFormGroup.get("socialNumber").value.replace(/[^\d]+/g, "") || null);
        consultSalesPagingRequest.setSocialNumber(socialNumber);

        const card = new Card();
        card.setCardNumber(this.searchFormGroup.get("cardNumber").value.replace(/[^\d]+/g, "") || null);
        consultSalesPagingRequest.setCard(card);

        let transactionDate = null;
        if (this.searchFormGroup.get("transactionDate").value) {
          transactionDate = moment(this.searchFormGroup.get("transactionDate").value, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        consultSalesPagingRequest.setTransactionDate(transactionDate);
        await this.salesService.searchSales(consultSalesPagingRequest);
      } catch (error) {
        this.toast.showWarningToast(error);
      } finally {
        this.loadingService.finishLoading(loading);
      }
    }
  }

  /**
   * Se foi informado o uniqueSequentialNumber, não precisa de mais nenhum filtro.
   */
  checkSequentialNumber(): void {
    if (this.searchFormGroup.get("uniqueSequentialNumber").value) {
      this.searchFormGroup.get("socialNumber").patchValue("");
      this.searchFormGroup.get("cardNumber").patchValue("");
      this.searchFormGroup.get("transactionDate").patchValue("");
    }
  }

  validForm(): boolean {
    const socialNumber = this.searchFormGroup.get("socialNumber").value.replace(/[^\d]+/g, "");
    const uniqueSequentialNumber = this.searchFormGroup.get("uniqueSequentialNumber").value;
    const cardNumber = this.searchFormGroup.get("cardNumber").value.replace(/[^\d]+/g, "");
    const transactionDate = this.searchFormGroup.get("transactionDate").value;

    if (!socialNumber && !uniqueSequentialNumber && !cardNumber && !transactionDate) {
      this.toast.showWarningToast("Informe algum filtro antes de pesquisar");
      return false;
    }

    if (socialNumber && socialNumber.length !== 0 && socialNumber.length < 11) {
      this.toast.showWarningToast("CPF inválido");
      return false;
    }

    if (cardNumber && cardNumber.length !== 0 && cardNumber.length < 16) {
      this.toast.showWarningToast("Número do Cartão é inválido");
      return false;
    }

    if (transactionDate) {
      const validDate = moment(transactionDate, "DD/MM/YYYY");
      if (!validDate.isValid()) {
        this.toast.showWarningToast("Data inválida");
        return false;
      }
    }

    return true;
  }
}
