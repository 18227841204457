import { Injectable } from "@angular/core";
import { AppContext } from "../../contexts/app-context";
import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { BenefitConfiguration } from "../../models/domains/pdv/Benefit/benefit-configuration";
import { ProductConfigurationCluster } from "../../models/domains/pdv/Benefit/product-configuration-cluster";
import { Card } from "../../models/domains/pdv/card";
import { FiscalNumber } from "../../models/domains/pdv/fiscal-number";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { BenefitConfigurationParser } from "../parsers/pdv/benefit-configuration.parser";
import { ProductConfigurationParser } from "../parsers/pdv/product-configuration.parser";

@Injectable()
export class BenefitRepository {
  constructor(
    private remoteGatewayFactory: RemoteGatewayFactory,
    private benefitConfigurationParser: BenefitConfigurationParser,
    private productConfigurationParser: ProductConfigurationParser,
  ) {}

  public async findBenefitConfiguration(card: Card): Promise<BenefitConfiguration> {
    const headers = { "Content-Type": "application/json" };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const data = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/benefit/'+card.getCardNumber()+'/configurations'), headers);
      return await Promise.resolve(this.benefitConfigurationParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async findProductConfiguration(card: Card): Promise<ProductConfigurationCluster> {
    const headers = { "Content-Type": "application/json" };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();

        const data = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/benefit/'+card.getCardNumber()+'/configurations/product'), headers);
      return await Promise.resolve(this.productConfigurationParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
