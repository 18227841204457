import { NgModule } from "@angular/core";

import { EscapeHtmlPipe } from "./keep-html.pipe";
import { DateFormatPipe } from "./date-format.pipe";
import { GetFirstCharPipe } from "./get-first-char.pipe";
import { SVGFallback } from "./svg-fallback.pipe";
import { CurrencyFormatPipe } from "./currency-format-pipe";
import { CompanyFederalNumberFormatPipe } from "./company-federal-number-format.pipe";
import { SocialNumberPipe } from "./pdv/social-number.pipe";
import { CardNumberPipe } from "./pdv/card-number.pipe";
import { FiscalNumberPipe } from "./pdv/fiscal-number.pipe";
import { CurrencyPdvPipe } from "./pdv/currency.pipe";

@NgModule({
  declarations: [
    EscapeHtmlPipe,
    DateFormatPipe,
    GetFirstCharPipe,
    SVGFallback,
    CompanyFederalNumberFormatPipe,
    CurrencyFormatPipe,
    SocialNumberPipe,
    CardNumberPipe,
    FiscalNumberPipe,
    CurrencyPdvPipe,
  ],
  exports: [
    EscapeHtmlPipe,
    DateFormatPipe,
    GetFirstCharPipe,
    SVGFallback,
    CompanyFederalNumberFormatPipe,
    CurrencyFormatPipe,
    SocialNumberPipe,
    CardNumberPipe,
    FiscalNumberPipe,
    CurrencyPdvPipe,
  ],
})
export class PipeModule {
  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
}
