import { Directive, HostListener, Input, Optional } from "@angular/core";

import { DialogRef } from "./dialog-ref";

@Directive({
  selector: "[dialogClose]",
})
export class DialogCloseDirective<T = any> {
  @Input("dialogClose") dialogResult: T;

  constructor(@Optional() private dialogRef: DialogRef<T>) {}

  @HostListener("click") onClick(): void {
    if (!this.dialogRef) {
      console.error("dialogClose is not supported within a template");

      return;
    }

    this.dialogRef.close(this.dialogResult);
  }
}
