import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PurchaseStage } from "../../models/domains/pdv/enum/purchase-stage.enum";

@Injectable({ providedIn: "root" })
export class HomeService {
  private purchaseStage = new BehaviorSubject<PurchaseStage>(PurchaseStage.StandByStage);

  getPurchaseStage() {
    return this.purchaseStage.asObservable();
  }

  standByStage() {
    this.purchaseStage.next(PurchaseStage.StandByStage);
  }

  initializePurchase() {
    this.purchaseStage.next(PurchaseStage.InitializeStage);
  }

  previewPurchase() {
    this.purchaseStage.next(PurchaseStage.PreviewStage);
  }

  finalizePurchase() {
    this.purchaseStage.next(PurchaseStage.FinalizeStage);
  }
}
