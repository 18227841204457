import { NgModule } from "@angular/core";
import { MatDividerModule } from "@angular/material";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { DialogContent, DialogActions, DialogHeader } from "./full-dialog.directives";
import { FullDialogComponent } from "./full-dialog.component";

@NgModule({
  declarations: [FullDialogComponent, DialogContent, DialogActions, DialogHeader],
  imports: [CommonModule, FlexLayoutModule, MatDividerModule],
  exports: [FullDialogComponent, DialogContent, DialogActions, DialogHeader],
})
export class FullDialogModule {}
