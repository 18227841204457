import { Component, Input, OnInit } from "@angular/core";
import { TermFormGroup, TermDocument } from "src/app/ui/models/domains/pdv/form-term";
import { NavigatorService } from "src/app/ui/services/pdv/navigator.service";

@Component({
  selector: "term-file-input",
  templateUrl: "./term-file-input.component.html",
  styleUrls: ["./term-file-input.component.scss"],
})
export class TermFileInputComponent implements OnInit {
  @Input("termFormGroup") termFormGroup: TermFormGroup;
  @Input("text") text: string;
  @Input("height") height: string = "100%";
  @Input("termDocument") termDocument: TermDocument;

  constructor(private navigatorService: NavigatorService) {}

  ngOnInit() {}

  fileChange(event, docName: string): void {
    const file = event.target.files[0];
    this.termFormGroup.get("Files").get(docName).setValue(file);
  }

  resetFile(docName: string): void {
    this.termFormGroup.get("Files").get(docName).setValue(null);
    this.termFormGroup.get("Documents").get(docName).setValue(null);
    this.termFormGroup.get("NetworkDocumentation").get(docName).get("DocumentUrl").setValue(null);
  }

  updateFilesValidation(): void {
    const areaList = document.querySelectorAll(".file-area");
    areaList.forEach((area) => {
      const input = area.querySelector("input.file");
      if (input.classList.contains("ng-invalid") && input.classList.contains("ng-touched")) {
        area.classList.add("error");
      } else {
        area.classList.remove("error");
      }
    });
  }

  documentUrl(): string {
    return this.termFormGroup.get("NetworkDocumentation").get(this.termDocument).get("DocumentUrl").value;
  }

  documentFilename(): string {
    return this.termFormGroup.get("NetworkDocumentation").get(this.termDocument).get("Filename").value;
  }

  selectedFilename(): string | null {
    return this.termFormGroup.get("Files").get(this.termDocument).value ? this.termFormGroup.get("Files").get(this.termDocument).value.name : null;
  }

  emptyFile(): boolean {
    return !this.termFormGroup.get("Documents").get(this.termDocument).value && !this.termFormGroup.get("NetworkDocumentation").get(this.termDocument).value.DocumentUrl;
  }

  isInterExplorer(): boolean {
    return this.navigatorService.isInternetExplorer();
  }
}
