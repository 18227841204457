import { NgModule, ModuleWithProviders } from "@angular/core";

import { LoadingService } from "./loading.service";
import { ModalService } from "./modal.service";
import { AuthService } from "./auth.service";
import { AlertService } from "./alert.service";
import { RepositoryModule } from "../repositories/repository.module";
import { GridService } from "./grid.service";
import { UserService } from "./user.service";
import { DefaultAuthProcessorFactory } from "./guards/processors/auth-processor-factory";
import { OverlayService } from "./overlay.service";
import { DialogService } from "./dialog.service";
import { ToastService } from "./toast.service";
import { NewsService } from "./news.service";
import { SnackbarService } from "./snackbar.service";
import { AcceptTermService } from "./pdv/accept-term.service";

@NgModule({
  imports: [RepositoryModule.forRoot()],
})
export class ServiceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ServiceModule,
      providers: [
        AlertService,
        AuthService,
        GridService,
        LoadingService,
        ModalService,
        UserService,
        DefaultAuthProcessorFactory,
        OverlayService,
        DialogService,
        ToastService,
        NewsService,
        SnackbarService,
        AcceptTermService,
      ],
    };
  }
}
