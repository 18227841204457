import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { AppContext } from "../contexts/app-context";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(public appContext: AppContext) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const serviceCredential = this.appContext.getServiceCredential();

    if (serviceCredential && serviceCredential.token) {
      const token = serviceCredential.token;

      request = this.requestClone({ Authorization: `Bearer ${token}` }, request);
    }

    return next.handle(request);
  }

  private requestClone(headers: { [name: string]: string | string[] }, request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({ setHeaders: headers });
  }
}
