import { Component, Inject, ViewEncapsulation, OnInit, OnDestroy, Input, ElementRef, ViewChild, Output, EventEmitter } from "@angular/core";
import { VERSION, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { ModalInfo } from "../../models/domains/modal/modal-info";
import { AlertService } from "../../services/alert.service";
import { SearchableTextArea } from "../../models/domains/inputs/searchable-text-area";
import { SVGFallback } from "../../pipes/svg-fallback.pipe";
@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
  ngOnInit(): void {}
  modalInfo: ModalInfo;
  private element: any;
  private textAreaInnerHTML: string;
  private query: string = null;
  private textCopied: boolean = false;
  private searchTextVisible: boolean = false;
  private highlightCount: number = 0;
  @ViewChild("searchInput", { static: false }) searchInput: ElementRef;
  @ViewChild("textArea", { static: false }) textArea: ElementRef;
  @Output() send: EventEmitter<any> = new EventEmitter();

  constructor(private dialogRef: MatDialogRef<ModalComponent>, private el: ElementRef, private alertService: AlertService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.modalInfo = data;
    this.element = el.nativeElement;
  }

  close(): void {
    this.dialogRef.close(true);
  }

  getHeaderImage(): string {
    let svgFallBack: SVGFallback = new SVGFallback();
    return svgFallBack.transform(this.modalInfo.HeaderImage);
  }

  setSearchTextVisible(content: SearchableTextArea): void {
    this.searchTextVisible = content.getSearchTextVisible();
    content.setSearchTextVisible(!content.getSearchTextVisible());
    this.query = "";
    this.highlight(content);
  }

  isSearchTextVisible(content: SearchableTextArea): boolean {
    return content.getSearchTextVisible();
  }

  isSearchTextVisibleOnFocus(content: SearchableTextArea): boolean {
    this.setFocusOnTextSearch();
    return content.getSearchTextVisible();
  }

  setFocusOnTextSearch(): void {
    if (this.searchInput && !this.searchTextVisible) {
      this.searchTextVisible = true;
      this.searchInput.nativeElement.focus();
    }
  }

  copyToClipBoard(content: object): void {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = content["value"].toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.textCopied = true;
    setTimeout(function () {
      this.textCopied = false;
    }, 2000);
    var modal = this;
    this.timeout(modal, 2000);
  }

  timeout(modal, timeout: number = 1000): void {
    setTimeout(function () {
      modal.textCopied = false;
    }, timeout);
  }

  isTextCopied(): boolean {
    return this.textCopied;
  }

  onSearch(event: any, content: object): void {
    if (event.code === "Enter") {
      this.highlightCount++;
      this.focushighlight();
    } else {
      this.highlightCount = 0;
    }

    this.query = event.target.value;
  }

  highlight(content: object): string {
    this.textAreaInnerHTML = content["value"].toString();
    this.textAreaInnerHTML = this.textAreaInnerHTML.replace(/</g, "&lt;").replace(/>/g, "&gt;");

    if (!this.query) {
      return this.textAreaInnerHTML;
    }

    let search = this.query.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    let highlightText = this.textAreaInnerHTML.replace(/(\r\n|\n|\r)/gm, "");
    highlightText = highlightText.replace(/\s{2,}/g, " ");
    let lastIndex = 0;
    let index = 0;
    let replace = '<span class="highlightText">' + search + "</span>";
    let replaceFocus = '<span class="highlightText highlightFocusText">' + search + "</span>";

    while (highlightText.indexOf(search, lastIndex) != -1) {
      const replaceText = index === this.highlightCount ? replaceFocus : replace;
      let start = highlightText.indexOf(search, lastIndex);
      let end = start + search.length;
      lastIndex = start + replaceText.length;
      highlightText = this.replaceAt(highlightText, search, replaceText, start, end);
      index++;
    }

    this.focushighlight();

    return highlightText;
  }

  private focushighlight(): void {
    let newHighlight = this.textArea.nativeElement.getElementsByClassName("highlightText")[this.highlightCount];

    if (!newHighlight) {
      this.highlightCount = 0;
      newHighlight = this.textArea.nativeElement.getElementsByClassName("highlightText")[this.highlightCount];
    }

    if (newHighlight) {
      newHighlight.scrollIntoView();
    } else {
      this.textArea.nativeElement.scrollIntoView();
    }
  }

  replaceAt(input, search, replace, start, end): string {
    return input.slice(0, start) + input.slice(start, end).replace(search, replace) + input.slice(end);
  }

  getTextAreaInnerHTML(): string {
    return this.textAreaInnerHTML;
  }

  public getNumberOfColumns(): number {
    if (this.modalInfo.Content && this.modalInfo.Content.length == 1) {
      return this.modalInfo.Content.length;
    } else if (this.modalInfo.Content && this.modalInfo.Content.length > 1) {
      return 2;
    } else {
      return 1;
    }
  }

  public shouldGridListBeVisible(): boolean {
    if (!this.modalInfo.Content) {
      return false;
    }
    if (this.modalInfo.Content.length > 1) {
      return true;
    } else if (this.modalInfo.Content.length == 1 && this.modalInfo.Content.find((item) => item.isSearchableTextArea())) {
      return false;
    } else {
      return true;
    }
  }

  onSend(): void {
    if (!this.validateInputs()) {
      let inputsWithError = this.modalInfo.Content.filter((i) => i.hasError());
      let firstInputWithError = inputsWithError[0];
      this.alertService.onlyOneError(`Input ${firstInputWithError.getName()} is required`, true, "alert-modal");
    } else {
      this.send.emit(this.modalInfo);
    }
  }

  validateInputs(): boolean {
    let hasError = true;
    this.modalInfo.Content.forEach((input) => {
      hasError = hasError && input.validate();
    });
    return hasError;
  }
}
