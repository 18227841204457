import { Injectable } from "@angular/core";

import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { Network } from "../../models/domains/pdv/network";
import { RuleEngineConfiguration } from "../../models/domains/pdv/rule-engine-configuration";
import { SalesOrder } from "../../models/domains/pdv/sales-order";
import { SalesOrderResult } from "../../models/domains/pdv/sales-order-result";
import { Shopper } from "../../models/domains/pdv/shopper";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { EmitPreSalesOrderParser } from "../parsers/pdv/emit-pre-sales-order.parser";
import { SalesOrderParser } from "../parsers/pdv/sales-order.parser";
import { RuleEngineConfigurationLitsParser } from "../parsers/pdv/rule-engine-configuration-list.parser";
import { AppContext } from "../../contexts/app-context";

@Injectable()
export class PurchaseRepository {
  constructor(
    private remoteGatewayFactory: RemoteGatewayFactory,
    private emitSalesOrderParser: SalesOrderParser,
    private emitPreSalesOrderParser: EmitPreSalesOrderParser,
    private appContext:AppContext,
    private ruleEngineConfigurationLitsParser: RuleEngineConfigurationLitsParser,
  ) {}

  public async emitPreviewPreSalesOrder(salesOrder: SalesOrder, shopper: Shopper, network: Network): Promise<SalesOrderResult> {
    const salesOrderItemList = salesOrder.getSalesOrderItemList().map((item) => {
      const medicalPrescription = item.hasMedicalPrescriptionInitialized()
        ? {
            Quantity: item.getMedicalPrescription().getQuantity(),
            MedicalPrescriptionDate: item.getMedicalPrescription().getMedicalPrescriptionDate().replace(/\D/g, ""),
            PractitionerRegistration: {
              Registration: item.getMedicalPrescription().getPractitionerRegistration().getRegistration(),
              State: {
                Value: item.getMedicalPrescription().getPractitionerRegistration().getState().getValue(),
              },
            },
            MedicalPrescriptionDocumentKey: item.getMedicalPrescription().hasDocumentKey()
              ? {
                  Value: item.getMedicalPrescription().getMedicalPrescriptionDocumentKey().getValue(),
                }
              : null,
          }
        : null;
      const model = {
        EAN: item.getProduct().getEan().getValue(),
        ProductQuantity: item.getProductQuantity(),
        NetworkPrice: item.getNetworkPrice(),
        UsedSellerPrice: item.getProduct().getUsedNetworkSellerPrice(),
        MedicalPrescription: medicalPrescription,
        NetworkGrossPrice: 0
      };

      if(model.UsedSellerPrice){
        model.NetworkPrice = item.getProduct().getSellerUnitPrice()
        model.NetworkGrossPrice = item.getProduct().getSellerUnitGrossPrice()
      }
      return model
    });
    const body = JSON.stringify({
      Source: salesOrder.getSource(),
      Shopper: {
        VirtualCardNumber: shopper.getVirtualCardNumber(),
        SocialNumber: shopper.getSocialNumber().getValue(),
      },
      NetworkInfo: {
        Name: network.getCorporateName(),
        FiscalNumber: network.getFiscalNumber().getValue(),
        Seller: this.appContext.getPdvPassport().getOperatorUserName()
      },
      State: {
        Name: network.getAddress().getRegion(),
        City: network.getAddress().getCity(),
        District: network.getAddress().getDistrict(),
      },
      SalesOrderItems: salesOrderItemList,
    });
    const headers = { "Content-Type": "application/json" };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const payload = await remoteGateway.postWithHeaders(new BackendUrl("/api/pbm/preview-sales-order"), body, headers);
      return await Promise.resolve(this.emitSalesOrderParser.parse(payload));
    }catch (error) {
      return await Promise.reject(error);
    }
  }

  public async emitPreSalesOrder(salesOrder: SalesOrder, shopper: Shopper, network: Network): Promise<SalesOrderResult> {
    const salesOrderItemList = salesOrder.getSalesOrderItemList().map((item) => {
      const medicalPrescription = item.hasMedicalPrescriptionInitialized()
        ? {
            Quantity: item.getMedicalPrescription().getQuantity(),
            MedicalPrescriptionDate: item.getMedicalPrescription().getMedicalPrescriptionDate().replace(/\D/g, ""),
            PractitionerRegistration: {
              Registration: item.getMedicalPrescription().getPractitionerRegistration().getRegistration(),
              State: {
                Value: item.getMedicalPrescription().getPractitionerRegistration().getState().getValue(),
              },
            },
            MedicalPrescriptionDocumentKey: item.getMedicalPrescription().hasDocumentKey()
              ? {
                  Value: item.getMedicalPrescription().getMedicalPrescriptionDocumentKey().getValue(),
                }
              : null,
          }
        : null;

      let networkPrice: number = 0;
      if (item.getProduct().getUsedNetworkPrice()) {
        networkPrice = item.getProduct().getNetworkPrice();
      }

      /*let networkGrossPrice: number = 0;
      if (item.getProduct().getGrossPrice()) {
        networkGrossPrice = item.getProduct().getGrossPrice();
      }*/

      const model = {
        EAN: item.getProduct().getEan().getValue(),
        ProductQuantity: item.getAuthorizedQuantity(),
        MedicalPrescription: medicalPrescription,
        UsedSellerPrice: item.getProduct().getUsedNetworkSellerPrice(),
        NetworkGrossPrice: 0,
        NetworkPrice: 0
      };

      if(model.UsedSellerPrice){
        model.NetworkPrice = item.getProduct().getSellerUnitPrice()
        model.NetworkGrossPrice = item.getProduct().getSellerUnitGrossPrice()
      }
      return model
    });

    const body = JSON.stringify({
      Source: salesOrder.getSource(),
      Shopper: {
        VirtualCardNumber: shopper.getVirtualCardNumber(),
        SocialNumber: shopper.getSocialNumber().getValue(),
        TransactionalPassword: shopper.getTransactionalPassword(),
      },
      NetworkInfo: {
        Name: network.getCorporateName(),
        FiscalNumber: network.getFiscalNumber().getValue(),
        Seller: this.appContext.getPdvPassport().getOperatorUserName()
      },
      State: {
        Name: network.getAddress().getRegion(),
        City: network.getAddress().getCity(),
        District: network.getAddress().getDistrict(),
      },
      SalesOrderItems: salesOrderItemList,
    });
    const headers = { "Content-Type": "application/json" };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const payload = await remoteGateway.postWithHeaders(new BackendUrl("/api/pbm/pre-sales-order"), body, headers);
      return await Promise.resolve(this.emitPreSalesOrderParser.parse(payload));
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
