export class ProductType {
  private key: string;
  private value: string;

  setKey(key: string): void {
    this.key = key;
  }

  getKey(): string {
    return this.key;
  }

  setValue(value: string): void {
    this.value = value;
  }

  getValue(): string {
    return this.value;
  }
}
