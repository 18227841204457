import { BlockItemType } from "../enum/block-item-type.enum";
import { IBlockItem } from "../interfaces/iblock-list-item";

export class ProductBlockItem implements IBlockItem {
  readonly blockListItemType: BlockItemType;
  private productKey: string;
  private ean: string;
  private productName: string;
  private displayValue: string;

  constructor() {
    this.blockListItemType = BlockItemType.Product;
  }

  hasBlockItemBy(blockItemType: BlockItemType): boolean {
    return this.blockListItemType === blockItemType;
  }

  setDisplayValue(value: string): void {
    this.displayValue = this.productName + " - " + value;
  }

  getDisplayValue(): string {
    return this.displayValue;
  }

  getKey(): string {
    return this.getProductKey();
  }

  getBlockListItemType(): BlockItemType {
    return this.blockListItemType;
  }

  setProductKey(value: string): void {
    this.productKey = value;
  }

  getProductKey(): string {
    return this.productKey;
  }

  setEan(value: string): void {
    this.ean = value;
  }

  getEan(): string {
    return this.ean;
  }

  setProductName(value: string): void {
    this.productName = value;
  }

  getProductName(): string {
    return this.productName;
  }

  getDescription(): string {
    return this.productName;
  }
}
