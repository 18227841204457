import { Injectable } from "@angular/core";
import { ProductType } from "src/app/ui/models/domains/pdv/Benefit/product-type";

import { AbstractParser } from "../parser";

@Injectable()
export class ProductTypeParser extends AbstractParser<ProductType[]> {
  parse(payload: any): ProductType[] {
    const productTypes: Array<ProductType> = [];
    payload.forEach((type) => {
      const productType = new ProductType();
      productType.setKey(type.Key);
      productType.setValue(type.Value);
      productTypes.push(productType);
    });
    return productTypes;
  }
}
