import { Injectable } from "@angular/core";
import { FiscalNumber } from "../../models/domains/pdv/fiscal-number";
import { MedicalPrescriptionDocumentKey } from "../../models/domains/pdv/medical-prescription-document-key";
import { Product } from "../../models/domains/pdv/product";
import { Shopper } from "../../models/domains/pdv/shopper";
import { SocialNumber } from "../../models/domains/pdv/social-number";
import { IConfigMedicalPrescription } from "../../repositories/parsers/pdv/does-product-require-scan.parser";
import { MedicalPrescriptionRepository } from "../../repositories/pdv/medical-prescription.repository";

@Injectable({ providedIn: "root" })
export class MedicalPrescriptionService {
  constructor(private medicalPrescriptionRepository: MedicalPrescriptionRepository) {}

  public async doesProductRequireScan(shopper: Shopper, product: Product): Promise<IConfigMedicalPrescription> {
    return await this.medicalPrescriptionRepository.doesProductRequireScan(shopper, product);
  }

  public async uploadMedicalPrescription(shopper: Shopper, data: FormData): Promise<MedicalPrescriptionDocumentKey> {
    return await this.medicalPrescriptionRepository.uploadMedicalPrescription(shopper, data);
  }
}
