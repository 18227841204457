import { Masks } from "../validators/masks";

export class FiscalNumber {
  constructor(private value: string) {}

  public setValue(value: string): void {
    this.value = value;
  }

  public getValue(): string {
    return this.value;
  }

  public getMaskedValue(): string {
    return Masks.fiscalNumberMask(this.value);
  }
}
