import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { AuthLoadingService } from "../pdv/auth-loading.service";
import { DefaultAuthProcessorFactory } from "./processors/auth-processor-factory";

@Injectable()
export class AcceptanceTermGuardService implements CanActivate {
  constructor(private defaultAuthProcessorFactory: DefaultAuthProcessorFactory, private authLoadingService: AuthLoadingService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const authProcessor = this.defaultAuthProcessorFactory.createAcceptanceTermProcessor();
    try {
      this.authLoadingService.startAuth();
      return await authProcessor.processAuthentication(route);
    } catch {
      return false;
    } finally {
      this.authLoadingService.stopAuth();
    }
  }
}
