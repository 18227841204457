import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { IAuthProcessor } from "./auth-processor";
import { DefaultAuthProcessor } from "./default-auth-processor";
import { AuthService } from "../../auth.service";
import { AppContext } from "src/app/ui/contexts/app-context";
import { RouteStrategyFactory } from "src/app/ui/gateways/route-strategy-factory";
import { AcceptanceTermAuthProcessor } from "./acceptance-term-auth-processor";

@Injectable()
export class DefaultAuthProcessorFactory {
  constructor(private authService: AuthService, private appContext: AppContext, private routeStrategyFactory: RouteStrategyFactory, private router: Router) {}

  createProcessor(): IAuthProcessor {
    return new DefaultAuthProcessor(this.authService, this.appContext, this.routeStrategyFactory, this.router);
  }

  createAcceptanceTermProcessor(): IAuthProcessor {
    return new AcceptanceTermAuthProcessor(this.authService, this.appContext, this.routeStrategyFactory, this.router);
  }
}
