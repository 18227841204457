import { Injectable } from "@angular/core";

import { AbstractParser } from "../parser";

export interface IConfigMedicalPrescription {
  enabled: boolean;
  requiredScan: boolean;
  purchaseRequiredScan: boolean;
}

@Injectable()
export class DoesProductRequireScanParser extends AbstractParser<IConfigMedicalPrescription> {
  parse(payload: any): IConfigMedicalPrescription {
    const ret: IConfigMedicalPrescription = {
      enabled: payload.Enabled,
      requiredScan: payload.RequiredScan,
      purchaseRequiredScan: payload.PurchaseRequiredScan,
    };
    return ret;
  }
}
