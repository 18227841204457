import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormArray } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material";
import { EmailType } from "src/app/ui/models/domains/pdv/enum/email-type.enum";
import { TelephoneType } from "src/app/ui/models/domains/pdv/enum/telephone-type.enum";
import { TermDocument, TermForm, TermFormGroup } from "src/app/ui/models/domains/pdv/form-term";
import { Network } from "src/app/ui/models/domains/pdv/network";
import { LoadingService } from "src/app/ui/services/loading.service";
import { AcceptTermService } from "src/app/ui/services/pdv/accept-term.service";
import { SnackbarService } from "src/app/ui/services/snackbar.service";

@Component({
  selector: "app-acceptance-term-form",
  templateUrl: "./acceptance-term-form.component.html",
  styleUrls: ["./acceptance-term-form.component.scss"],
})
export class AcceptanceTermFormComponent implements OnInit {
  @Input("network") network: Network;
  @Output("successSubmit") successSubmit = new EventEmitter<void>();
  @Input("termForm") termForm: TermForm;
  termFormGroup: TermFormGroup;

  constructor(private termService: AcceptTermService, private loadingService: LoadingService, private snackbarService: SnackbarService) {}

  ngOnInit() {
    try {
      this.termFormGroup = TermForm.createForm(this.termForm);
      this.termFormGroup.get("FiscalNumber").get("Value").setValue(this.network.getFiscalNumber().getValue());
    } catch (error) {
      this.snackbarService.showError("Não foi possível carregar as informações.");
    }
  }

  async submitForm(): Promise<void> {
    this.markFilesAsTouched();
    if (this.termFormGroup.invalid) {
      const documentsFormGroupErros = this.termFormGroup.get("Documents").errors;
      const discountFormArray = this.termFormGroup.get("NetworkProductDiscounts") as FormArray;
      if (documentsFormGroupErros && documentsFormGroupErros.validateAllDocumentsType) {
        this.snackbarService.showError("Os arquivos devem ser apenas do tipo PDF.");
        return;
      } else if (discountFormArray && discountFormArray.controls[0].get("Discount").errors) {
        this.snackbarService.showError("O desconto mínimo para marcas deve ser de 10%.");
        return;
      } else if (discountFormArray && discountFormArray.controls[1].get("Discount").errors) {
        this.snackbarService.showError("O desconto mínimo para genéricos deve ser de 15%.");
        return;
      }
      this.snackbarService.showError("Favor preencher campos obrigatórios em vermelho.");
      return;
    }
    const load = this.loadingService.beginLoading();
    try {
      await this.termService.sendTermForm(this.termFormGroup);
      this.successSubmit.emit();
    } catch (error) {
      this.snackbarService.showError("Não foi possível salvar as informações.");
    } finally {
      this.loadingService.finishLoading(load);
    }
  }

  updateFilesValidation(): void {
    const areaList = document.querySelectorAll(".file-area");
    areaList.forEach((area) => {
      const input = area.querySelector("input.file");
      if (input.classList.contains("ng-invalid") && input.classList.contains("ng-touched")) {
        area.classList.add("error");
      } else {
        area.classList.remove("error");
      }
    });
  }

  markFilesAsTouched(): void {
    const files = document.querySelectorAll(".file");
    files.forEach((file) => {
      file.classList.add("ng-touched");
      file.classList.remove("ng-untouched");
    });
    this.updateFilesValidation();
  }

  service24hChange(event: MatCheckboxChange): void {
    const networkInfo = this.termFormGroup.get("NetworkInfo");
    if (event.checked) {
      networkInfo.get("WeekDaysOpeningHour").disable();
      networkInfo.get("WeekDaysClosingHour").disable();
      networkInfo.get("SaturdayOpeningHour").disable();
      networkInfo.get("SaturdayClosingHour").disable();
      networkInfo.get("SundayHolidaysOpeningHour").disable();
      networkInfo.get("SundayHolidaysClosingHour").disable();
    } else {
      networkInfo.get("WeekDaysOpeningHour").enable();
      networkInfo.get("WeekDaysClosingHour").enable();
      networkInfo.get("SaturdayOpeningHour").enable();
      networkInfo.get("SaturdayClosingHour").enable();
      networkInfo.get("SundayHolidaysClosingHour").enable();
      networkInfo.get("SundayHolidaysOpeningHour").enable();
    }
  }

  deliveryChange(event: MatCheckboxChange): void {
    const networkInfo = this.termFormGroup.get("NetworkInfo");
    if (event.checked) {
      networkInfo.get("DeliveryFee").enable();
      networkInfo.get("MinimumValue").enable();
    } else {
      networkInfo.get("DeliveryFee").disable();
      networkInfo.get("MinimumValue").disable();
    }
  }

  get TermDocument() {
    return TermDocument;
  }

  isHeadOfficeNetwork(): boolean {
    return this.network && this.network.isHeadOffice();
  }

  getMobilePhoneType(): string {
    return TelephoneType.mobile;
  }

  getFinancialEmailType(): string {
    return EmailType.financial;
  }

  getCompanyContacts() {
    const networkContactGroup = this.termFormGroup.controls.NetworkContact as FormGroup;
    const companyContactsGroup = networkContactGroup.controls.CompanyContacts as FormGroup;
    return companyContactsGroup.controls;
  }

  getNetworkEmails() {
    const networkContactGroup = this.termFormGroup.controls.NetworkContact as FormGroup;
    const networkContact = networkContactGroup.controls.NetworkEmails as FormGroup;
    return networkContact.controls;
  }

  getNetworkTelephones() {
    const networkContactGroup = this.termFormGroup.controls.NetworkContact as FormGroup;
    const networkTelephones = networkContactGroup.controls.NetworkTelephones as FormGroup;
    return networkTelephones.controls;
  }
}
