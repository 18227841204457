import { NgModule } from "@angular/core";
import { NavBarComponent } from "./nav-bar.component";
import { MenuBarComponent } from "./menu-bar/menu-bar";
import { MatIconModule, MatMenuModule, MatButtonModule, MatDividerModule, MatTooltipModule, MatBadgeModule } from "@angular/material";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MenuAccountComponent } from "./menu-account/menu-account.component";
import { PipeModule } from "src/app/ui/pipes/pipe.module";
import { ImgSvgComponent } from "../../img-svg/img-svg.component";
import { NewsPanelComponent } from "../../news-panel/news-panel.component";

@NgModule({
  declarations: [NavBarComponent, MenuBarComponent, MenuAccountComponent, ImgSvgComponent, NewsPanelComponent],
  imports: [MatIconModule, MatMenuModule, MatBadgeModule, MatButtonModule, CommonModule, FlexLayoutModule, MatDividerModule, MatTooltipModule, PipeModule.forRoot()],
  exports: [NavBarComponent, MatBadgeModule, MenuBarComponent, ImgSvgComponent, NewsPanelComponent],
})
export class NavBarModule {}
