import { Injectable } from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { LoadingPanelComponent } from "../components/loading-panel/loading-panel.component";
import { LoadingOverlayRef } from "../models/domains/loading/loading-overlay-ref";

@Injectable()
export class LoadingService {
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay) {}

  beginLoading(value: string = "Carregando..."): LoadingOverlayRef {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const loadingPanelPortal = new ComponentPortal(LoadingPanelComponent);
    this.overlayRef.attach(loadingPanelPortal).instance.openLoading(value);

    return new LoadingOverlayRef(this.overlayRef);
  }

  finishLoading(overlayRef: LoadingOverlayRef): void {
    overlayRef.closeLoading();
  }
}
