import { FiscalNumber } from "../fiscal-number";
import { ProductConfigurationCluster } from "./product-configuration-cluster";

export class BenefitConfiguration {
  private id: string;
  private companyFiscalNumber: FiscalNumber;
  private productConfiguration: ProductConfigurationCluster;

  setId(id: string): void {
    this.id = id;
  }

  getId(): string {
    return this.id;
  }

  setCompanyFiscalNumber(fiscalNumber: FiscalNumber): void {
    this.companyFiscalNumber = fiscalNumber;
  }

  getCompanyFiscalNumber(): FiscalNumber {
    return this.companyFiscalNumber;
  }

  setProductConfiguration(productConfiguration: ProductConfigurationCluster): void {
    this.productConfiguration = productConfiguration;
  }

  getProductConfiguration(): ProductConfigurationCluster {
    return this.productConfiguration;
  }

  hasProductConfiguration() {
    return !!this.productConfiguration;
  }
}
