import { Injectable } from "@angular/core";
import { RuleEngineConfiguration } from "../../models/domains/pdv/rule-engine-configuration";
import { Shopper } from "../../models/domains/pdv/shopper";
import { RuleEngineConfigurationRepository } from "../../repositories/pdv/rule-engine-configuration.repository";

@Injectable({ providedIn: "root" })
export class RuleEngineConfigurationService {
  constructor(private ruleEngineConfigurationRepository: RuleEngineConfigurationRepository) {}

  public async getPreSalesValidityHours(shopper: Shopper): Promise<number> {
    try {
      const rules = await this.ruleEngineConfigurationRepository.getRuleEngineConfigurationList(shopper);
      const preSalesRuleConfigurarion = rules.find((rule) => rule.getSalesAction() === "Carrinho");
      if (preSalesRuleConfigurarion) return Promise.resolve(preSalesRuleConfigurarion.getMinSalesHours());
      return Promise.resolve(RuleEngineConfiguration.defaultPreSalesValidityHours);
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
