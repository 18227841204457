import { Ean } from "./ean";
import { PriceRegimeType } from "./enum/price-regime-type";
import { ProgressiveDiscount } from "./progressive-discount";

export class Product {
  private authorizedQuantity: number;
  private quantity: number;
  private name: string;
  private ean: Ean;
  private laboratory: string;
  private presentation: string;
  private substance: string;
  private type: string;
  private subType: string;
  private priceRegime: PriceRegimeType;
  private netPrice: number;
  private networkPrice: number;
  private grossPrice: number;
  private pmcPrice: number;
  private price: number;
  private productSubsidy: number;
  private discount: number;
  private progressiveDiscountList: ProgressiveDiscount[];
  private externalAuthorizerDiscount: number;
  private hasExternalAuthorizerBetterDiscount: boolean;
  private hasMedicalPrescription: boolean;
  private industryProgramAdhered: boolean;
  private usedNetworkPrice: boolean;
  private usedNetworkNetPrice: boolean;
  private usedNetworkGrossPrice: boolean;
  private usedNetworkSellerPrice: boolean;
  private subsidyAllowed: boolean;
  private updatesalesordemitemlist: string;
  private canInputPrice: boolean;
  private sellerUnitPrice: number = 0;
  private sellerUnitGrossPrice: number = 0;
  private status: string;
  private unitPrice: number;
  private unitGrossPrice: number;

  public getUsedNetworkSellerPrice(){
    return this.usedNetworkSellerPrice
  }

  public getSellerUnitPrice(){
    return this.sellerUnitPrice
  }
  public getSellerUnitGrossPrice(){
    return this.sellerUnitGrossPrice
  }

  public setSellerUnitPrice(price:number){
    this.sellerUnitPrice = price
    this.usedNetworkSellerPrice = true
  }
  public setSellerUnitGrossPrice(price:number){
    this.sellerUnitGrossPrice = price
    this.usedNetworkSellerPrice = true
  }

  public getCanInputPrice(){
    return this.canInputPrice
  }
  public setCanInputPrice(val: boolean){
    this.canInputPrice = val
  }

  public getUpdatesalesordemitemlist(): string {
    return this.updatesalesordemitemlist;
  }

  public setUpdatesalesordemitemlist(updatesalesordemitemlist: string): void {
    this.updatesalesordemitemlist = updatesalesordemitemlist;
  }

  public getPriceRegime(): PriceRegimeType {
    return this.priceRegime;
  }

  public setPriceRegime(priceRegime: PriceRegimeType): void {
    this.priceRegime = priceRegime;
  }

  public setAuthorizedQuantity(value: number): void {
    this.authorizedQuantity = value;
  }

  public getAuthorizedQuantity(): number {
    return this.authorizedQuantity;
  }

  public setQuantity(value: number): void {
    this.quantity = value;
  }

  public getQuantity(): number {
    return this.quantity;
  }

  public setIndustryProgramAdhered(value: boolean): void {
    this.industryProgramAdhered = value;
  }

  public getIndustryProgramAdhered(): boolean {
    return this.industryProgramAdhered;
  }

  public setName(value: string): void {
    this.name = value;
  }

  public getName(): string {
    return this.name;
  }

  public setEan(value: Ean): void {
    this.ean = value;
  }

  public getEan(): Ean {
    return this.ean;
  }

  public setLaboratory(value: string): void {
    this.laboratory = value;
  }

  public getLaboratory(): string {
    return this.laboratory;
  }

  public setPresentation(value: string): void {
    this.presentation = value;
  }

  public getPresentation(): string {
    return this.presentation;
  }

  public getDisplayName(): string {
    return this.name + " " + this.presentation;
  }

  public setSubstance(value: string): void {
    this.substance = value;
  }

  public getSubstance(): string {
    return this.substance;
  }

  public setType(value: string): void {
    this.type = value;
  }

  public getType(): string {
    return this.type;
  }

  public setSubType(value: string): void {
    this.subType = value;
  }

  public getSubType(): string {
    return this.subType;
  }

  public setNetPrice(value: number): void {
    this.netPrice = value;
  }

  public getNetPrice(): number {
    return this.netPrice;
  }

  public setGrossPrice(value: number): void {
    this.grossPrice = value;
  }

  public getGrossPrice(): number {
    return this.grossPrice;
  }
  //
  public getNetworkPrice(): number {
    return this.networkPrice;
  }

  public setNetworkPrice(networkPrice: number): void {
    this.networkPrice = networkPrice;
  }

  public getExternalAuthorizerDiscount(): number {
    return this.externalAuthorizerDiscount;
  }

  public setExternalAuthorizerDiscount(value: number) {
    this.externalAuthorizerDiscount = value;
  }

  public getHasExternalAuthorizerBetterDiscount(): boolean {
    return this.hasExternalAuthorizerBetterDiscount;
  }

  public setHasExternalAuthorizerBetterDiscount(value: boolean) {
    this.hasExternalAuthorizerBetterDiscount = value;
  }

  public setHasMedicalPrescription(value: boolean): void {
    this.hasMedicalPrescription = value;
  }

  public getHasMedicalPrescription(): boolean {
    return this.hasMedicalPrescription;
  }

  public setDiscount(value: number): void {
    this.discount = value;
  }

  public getDiscount(): number {
    return this.discount;
  }

  public setProgressiveDiscountList(value: ProgressiveDiscount[]): void {
    this.progressiveDiscountList = value;
  }

  public getProgressiveDiscountList(): ProgressiveDiscount[] {
    return this.progressiveDiscountList;
  }

  public setProductSubsidy(value: number): void {
    this.productSubsidy = value;
  }

  public getProductSubsidy(): number {
    return this.productSubsidy;
  }

  public setPrice(value: number): void {
    this.price = value;
  }

  public getPrice(): number {
    return this.price;
  }

  public setUnitPrice(value: number): void {
    this.unitPrice = value;
  }

  public getUnitPrice(): number {
    return this.unitPrice;
  }

  public setUnitGrossPrice(value: number): void {
    this.unitGrossPrice = value;
  }

  public getUnitGrossPrice(): number {
    return this.unitGrossPrice;
  }

  public setPmcPrice(value: number): void {
    this.pmcPrice = value;
  }

  public getPmcPrice(): number {
    return this.pmcPrice;
  }

  public getUsedNetworkPrice(): boolean {
    return this.usedNetworkPrice;
  }

  public setUsedNetworkPrice(value: boolean): void {
    this.usedNetworkPrice = value;
  }

  public getUsedNetworkNetPrice(): boolean {
    return this.usedNetworkNetPrice;
  }

  public setUsedNetworkNetPrice(value: boolean): void {
    this.usedNetworkNetPrice = value;
  }

  public getUsedNetworkGrossPrice(): boolean {
    return this.usedNetworkGrossPrice;
  }

  public setUsedNetworkGrossPrice(value: boolean): void {
    this.usedNetworkGrossPrice = value;
  }

  public getSubsidyAllowed(): boolean {
    return this.subsidyAllowed;
  }

  public setSubsidyAllowed(value: boolean): void {
    this.subsidyAllowed = value;
  }

  public getIntegratedNetworkPrice(): number {
    let networkPrice: number;

    if (this.networkPrice > 0) {
      networkPrice = this.netPrice;
    } else {
      networkPrice = this.networkPrice;
    }

    return networkPrice;
  }
  
  public getStatus(): string {
    return this.status;
  }

  public setStatus(value: string): void {
    this.status = value;
  }
}