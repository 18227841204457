import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { PageableResult } from "../../models/domains/paginator/pageable-result";
import { Router } from "@angular/router";
import { GridService } from "../../services/grid.service";
import { MatPaginator, MatSort, Sort } from "@angular/material";
import { tap } from "rxjs/operators";
import { PagingInfo } from "../../models/domains/paginator/paging-info.model";
import { Direction } from "../../models/domains/paginator/direction.enum";
import { Sort as CustomSort } from "../../models/domains/sort/sort";
import { ExpandableGridType } from "../../models/domains/grids/expandable-grid-type.enum";
import { GridColumn } from "../../models/domains/grids/grid-column";
import { IGridColumnDisplayable } from "../../models/domains/grids/displayable-grid-column";
import { MultiIconGridColumn } from "../../models/domains/grids/multi-icon-grid-column.model.model";
import { MatSvgIconTemplate } from "../../models/domains/grids/mat-svg-icon-template.model";
import { SelectedRowOnPage } from "../../models/domains/grids/selected-row-on-page.model";
import { SelectionModel } from "@angular/cdk/collections";
import { SelectedEventArgs } from "../../models/domains/grids/selected-event-args.model";
import { RowUpdatingEventArgs } from "../../models/domains/grids/row-updating-event-args.model";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { FormTemplateEnumType } from "../../models/domains/grids/form-template-enum-type";
import { ValidationUtils } from "../../utils/validation-utils";

@Component({
  selector: "grid-expandable",
  templateUrl: "./grid-expandable.component.html",
  styleUrls: ["./grid-expandable.component.scss"],
})
export class GridExpandableComponent implements OnInit {
  private _dataSource: PageableResult;
  private selectedRowOnPage: SelectedRowOnPage = new SelectedRowOnPage();
  private element: HTMLElement;
  private formIconName: string = "EditForm";
  _enableSelection: boolean | string;
  @Input() set enableSelection(value: boolean | string) {
    this._enableSelection = value;
    this.checkEnableSelection();
  }

  @Input() set dataSource(value: PageableResult) {
    this._dataSource = value;
    this.populateSelectedRowOnCurrentPage();
  }
  get dataSource(): PageableResult {
    return this._dataSource;
  }

  @Input() columns: Array<GridColumn>;
  @Input() detailColumns: Array<GridColumn> = new Array<GridColumn>();
  @Input() type: ExpandableGridType;
  @Input() applySelectionbutton: boolean | string;
  @Input() paginationEnabled: boolean = true;
  @Input() numberOptions: Array<number> = [10, 25, 50];
  @Input() pageSize: number = 10;

  @Output() onPaging: EventEmitter<PagingInfo> = new EventEmitter();
  @Output() onSorting: EventEmitter<CustomSort> = new EventEmitter();
  @Output() onModalOpennig: EventEmitter<any> = new EventEmitter();
  @Output() onSelected: EventEmitter<SelectedEventArgs> = new EventEmitter();
  @Output() onRowUpdating: EventEmitter<RowUpdatingEventArgs> = new EventEmitter();

  selectionOnAllPages = new SelectionModel<any>(true, []);
  dataSourceEmpty: any = [];
  displayedColumns: Array<string> = new Array<string>();
  displayedDetailColumns: Array<object> = new Array<Object>();
  columnsMatTable: Array<Object> = [];
  columnsMatTableDetail: Array<Object> = [];
  currentPage: number = 1;
  maxPageLinkNumber: number = 5;
  initialPage: number = 1;
  pageLinks = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  expandedObject: Object;
  controls: FormArray;

  constructor(private router: Router, private gridService: GridService) {}

  ngOnInit() {
    if (this.isExpandableForm()) this.createEditFormColumn();

    for (let c = 0; c < this.columns.length; c++) {
      this.columnsMatTable.push(this.columns[c].renderByMaterialTable());
    }
    for (let c = 0; c < this.detailColumns.length; c++) {
      this.columnsMatTableDetail.push(this.detailColumns[c].renderByMaterialTable());
    }

    const columnsDef = this.columnsMatTable.map((x) => x["columnDef"] as string);
    this.displayedColumns = this.displayedColumns.concat(columnsDef);
    this.displayedDetailColumns = this.columnsMatTableDetail.map((x) => x["columnDef"]);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.onPageChange())).subscribe();

    this.sort.sortChange.pipe(tap(() => this.onSortChange())).subscribe();
    this.gridService.setPaginator(this.paginator);
  }

  canDisplayIcon(row: object): boolean {
    try {
      let item: IGridColumnDisplayable = row as IGridColumnDisplayable;
      return item && item.canDisplay();
    } catch (error) {
      return true;
    }
  }

  changeRowColor(rowIndex: number, color: string): void {
    this.element = document.getElementById(`row_${rowIndex}`);
    this.element.style.backgroundColor = color;
  }

  checkEnableSelection() {
    let existSeletionColumn = this.displayedColumns.filter((x) => x === "selection").length > 0;
    if (this.isEnableSelection() && !existSeletionColumn) {
      this.selectionOnAllPages.clear();
      this.displayedColumns.splice(0, 0, "selection");
    } else if (!this.isEnableSelection()) {
      this.displayedColumns = this.displayedColumns.filter((x) => x !== "selection");
    }
  }

  fireAction(column: object, item: any, matSvgIconTemplate?: MatSvgIconTemplate): void {
    let gridColumn = column["customColumn"] as GridColumn;
    if (gridColumn && gridColumn.isIcon()) {
      if (!gridColumn.isModalgridColumnActionType()) {
        this.router.navigate([gridColumn.getNavigateRoute()], { queryParams: { p: gridColumn.evaluate(item) } });
      } else if (matSvgIconTemplate && matSvgIconTemplate.getId() !== this.formIconName) {
        let result = {};
        result["row"] = item;
        result["iconTemplate"] = matSvgIconTemplate;
        this.onModalOpennig.emit(JSON.stringify(result));
      } else if ((matSvgIconTemplate && matSvgIconTemplate.getId() === this.formIconName) || gridColumn.binding === this.formIconName) {
        this.toggleFormRow(item);
      } else {
        this.onModalOpennig.emit(JSON.stringify(item));
      }
    }
  }

  getColumnsFormTemplate(): Array<GridColumn> {
    return this.columns.filter((g) => g.formTemplate !== undefined);
  }

  getColumnStyle(column: object): object {
    let gridColumn = column["customColumn"] as GridColumn;

    return { flex: "0 0 " + gridColumn.getSize(), width: gridColumn.getSize() + " !important", "box-sizing": "border-box" };
  }

  getControl(index: number, fieldName: string) {
    return this.controls.at(index).get(fieldName) as FormControl;
  }

  getDetailColumnStyle(column: object, row: object): object {
    let gridColumn = column["customColumn"] as GridColumn;
    if (this.rowHasError(row)) {
      return { "background-color": "#F8D7DA", width: gridColumn.getSize() };
    } else if (this.rowHasWarning(row)) {
      return { "background-color": "#FFF3CD", width: gridColumn.getSize() };
    }
    return { "background-color": "#FFFFFF", width: gridColumn.getSize() };
  }

  getDetailDivStyle(row: object): object {
    return {
      "padding-left": "50px",
      "padding-top": "20px",
      "padding-bottom": "20px",
      width: "100%",
    };
  }

  getFormattedColumnText(column: object, columnData: Object): string {
    let gridColumn = column["customColumn"] as GridColumn;
    if (column && columnData) {
      return gridColumn.evaluate(columnData);
    }
    return "";
  }

  getIconGridColumnUrl(column: Object): string {
    let gridColumn = column["customColumn"] as GridColumn;
    if (gridColumn && gridColumn.isIcon()) {
      return gridColumn.getIconUrl();
    }
    return "";
  }

  getIconGridColumnToolTip(column: Object): string {
    let gridColumn = column["customColumn"] as GridColumn;
    if (gridColumn && gridColumn.isIcon()) {
      return gridColumn.getIconTooltip();
    }
    return "";
  }

  getMessageSelectAllHeader(): string {
    const isSelectedAllRowOnPage = this.isSelectedAllRowOnPage();
    return isSelectedAllRowOnPage ? "Desmarcar todos itens da página" : "Marcar todos itens da página atual";
  }

  getMessageTotalSelectedRows(): string {
    const countAllSelectedRows = this.selectionOnAllPages.selected.length;
    return countAllSelectedRows > 1 ? `${countAllSelectedRows} items selecionados` : `${countAllSelectedRows} item selecionado`;
  }

  getRowIndex(row: Object): number {
    return this._dataSource.getItems().findIndex((x) => x === row);
  }

  getSvgIconGridColumn(column: Object): string {
    let gridColumn = column["customColumn"] as GridColumn;
    if (gridColumn && gridColumn.isIcon()) {
      return gridColumn.getIconSvg();
    }
    return "";
  }

  hasIconEditForm(matSvgIconTemplate: MatSvgIconTemplate): boolean {
    return matSvgIconTemplate.getId() === this.formIconName;
  }

  hideIconRow(rowIndex: number, binding: string, matSvgIconTemplate?: MatSvgIconTemplate): void {
    matSvgIconTemplate
      ? (this.element = document.getElementById(`button_icon_${binding}_${matSvgIconTemplate.getId()}_${rowIndex}`))
      : (this.element = document.getElementById(`button_icon_${binding}_${rowIndex}`));

    this.element.style.display = "none";
  }

  isEnableActionButtonSelection(): boolean {
    return this.applySelectionbutton === true || this.applySelectionbutton === "true";
  }

  isExpandableDiv(): boolean {
    return this.type == ExpandableGridType.Div;
  }

  isExpandableForm(): boolean {
    return this.type == ExpandableGridType.Form;
  }

  isEnableSelection(): boolean {
    return this._enableSelection === true || this._enableSelection === "true";
  }

  isExpandableTable(): boolean {
    return this.type == ExpandableGridType.Table;
  }

  isFormColumn(column: Object): boolean {
    let gridColumn = column["customColumn"] as GridColumn;
    return gridColumn && gridColumn.isForm() && this.isExpandableForm();
  }

  isMultiIconGridColumn(column: GridColumn): boolean {
    if (column instanceof MultiIconGridColumn) {
      let gridColumn = column as MultiIconGridColumn;
      return gridColumn.getMatSvgIconsRegistred() && gridColumn.getMatSvgIconsRegistred().length > 0;
    }

    return false;
  }

  isIconSvg(column: Object): boolean {
    let gridColumn = column["customColumn"] as GridColumn;
    return gridColumn && gridColumn.isIcon() && gridColumn.isIconSvg();
  }

  isIconUrl(column: Object): boolean {
    let gridColumn = column["customColumn"] as GridColumn;
    return gridColumn && gridColumn.isIcon() && gridColumn.isIconUrl();
  }

  isHidden(column: Object): boolean {
    let gridColumn = column["customColumn"] as GridColumn;
    return gridColumn && gridColumn.isHidden();
  }

  isLastColumn(column: object): boolean {
    let columns = this.columnsMatTable.filter((item) => {
      return item["isIcon"] == false && item["isExpandable"] == false;
    });
    let lastColumn = columns[columns.length - 1];
    return column === lastColumn;
  }

  isRowSelected(row: Object): boolean {
    return this.getOnAllPagesSelectedRow(row) !== undefined;
  }

  isSelectedAllRowOnPage(): boolean {
    return this.selectedRowOnPage.getSelected().length === this.dataSource.getItems().length;
  }

  isSortingDisabled(column: object): boolean {
    if (column) {
      let gridColumn: GridColumn = column["customColumn"];
      if (gridColumn) {
        return !gridColumn.orderBy;
      }
    }
    return false;
  }

  onPageChange(): void {
    let pagingInfo = new PagingInfo();
    let itemsPerPage = this.dataSource.getItemsPerPage();
    if (this.paginator.pageSize != itemsPerPage) {
      this.dataSource.setStart(1);
      this.gridService.setCurrentPage(1);
      this.dataSource.setEnd(this.paginator.pageSize);
      pagingInfo.start = 1;
      pagingInfo.end = this.paginator.pageSize;
      pagingInfo.pageIndex = this.paginator.pageIndex + 1;
    } else {
      let page = this.paginator.pageIndex + 1;
      let start = page * this.paginator.pageSize - (this.paginator.pageSize - 1);
      let end = start + (this.paginator.pageSize - 1);
      pagingInfo.start = start;
      pagingInfo.end = end;
      pagingInfo.pageIndex = page;
    }
    this.onPaging.emit(pagingInfo);
  }

  onRowUpdate(index: number): void {
    this.checkBrowserAssignMethod();
    const rowForm = this.controls.getRawValue()[index];
    const row = this.dataSource.getItems()[index];
    const newValue = Object.assign({}, row, rowForm);
    this.onRowUpdating.emit(new RowUpdatingEventArgs(newValue));
  }

  onSelectionChange(): void {
    this.onSelected.emit(new SelectedEventArgs(this.selectionOnAllPages.selected));
  }

  onSortChange(): void {
    let gridColumn = this.columns.find((element) => {
      return element.display.includes(this.sort.active);
    });
    if (gridColumn) {
      let propertyName = gridColumn.binding;
      if (this.sort.direction.includes("asc")) {
        this.onSorting.emit(new CustomSort(propertyName, Direction.Asc));
      } else {
        this.onSorting.emit(new CustomSort(propertyName, Direction.Desc));
      }
    }
  }

  removeSelectionOnRow(rowIndex: number): void {
    this.element = document.getElementById(`selection_${rowIndex}`);
    this.element.outerHTML = "";
  }

  rowHasError(row: object): boolean {
    let detail = row["detail"];
    if (detail) {
      return detail["hasError"];
    }
    return false;
  }

  rowHasWarning(row: object): boolean {
    let detail = row["detail"];
    if (detail) {
      return detail["hasWarning"];
    }
    return false;
  }

  showDetail(row: object): void {
    if (!this.isExpandableForm()) this.expandRow(row);
  }

  toggleFormRow(row: Object): void {
    this.createFormArray();
    this.expandRow(row);
  }

  toggleSelectAll(): void {
    this.isSelectedAllRowOnPage() ? this.deselectAllCurrentRows() : this.selectAllCurrentRows();

    this.emitOnSelectedChange();
  }

  toggleSelectRow(row: Object): void {
    const rowSelected = this.getOnAllPagesSelectedRow(row);
    if (rowSelected !== undefined) {
      this.selectionOnAllPages.toggle(rowSelected);
      this.selectedRowOnPage.deselect(rowSelected);
    } else {
      this.selectionOnAllPages.toggle(row);
      this.selectedRowOnPage.select(row);
    }

    this.emitOnSelectedChange();
  }

  private createEditFormColumn(): void {
    var foundMultiIconGridColumn = false;
    this.columns.forEach((column) => {
      if (this.isMultiIconGridColumn(column)) {
        foundMultiIconGridColumn = true;
        column.form = true;
        (column as MultiIconGridColumn).addMatIconSvg(new MatSvgIconTemplate(this.formIconName, "edit", "Editar"));
      }
    });

    if (!foundMultiIconGridColumn) {
      let editFormGrid = new GridColumn();
      editFormGrid.binding = this.formIconName;
      editFormGrid.display = "Ação";
      editFormGrid.form = true;
      this.columns.push(editFormGrid);
    }
  }

  private createFormArray(): void {
    var groups = this.dataSource.getItems().map((item) => {
      let group = {};
      Object.keys(item).forEach((key) => {
        let value = item[key];
        const column = this.getColumnsFormTemplate().find((c) => c.binding === key);
        if (column) {
          if (column.formTemplate.type === FormTemplateEnumType.select) {
            value = column.formTemplate.options.find((x) => x.value.toUpperCase() === value.toUpperCase());
          }
          const validations = ValidationUtils.bindValidations(column.formTemplate.validations || []);
          group[key] = new FormControl({ value: value, disabled: column.formTemplate.disabled }, validations);
        }
      });
      return new FormGroup(group);
    });
    this.controls = new FormArray(groups);
  }

  private checkBrowserAssignMethod() {
    if (typeof Object.assign != "function") {
      Object.assign = function (target: any) {
        "use strict";
        if (target == null) {
          throw new TypeError("Cannot convert undefined or null to object");
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) {
            for (var nextKey in nextSource) {
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      };
    }
  }

  private deselectAllCurrentRows() {
    this.selectedRowOnPage.getSelected().forEach((item) => {
      this.selectionOnAllPages.deselect(this.getOnAllPagesSelectedRow(item));
    });
    this.selectedRowOnPage.clear();
  }

  private emitOnSelectedChange() {
    if (this.isEnableSelection() && !this.isEnableActionButtonSelection()) this.onSelectionChange();
  }

  private expandRow(row: object): void {
    if (this.expandedObject === row) {
      this.expandedObject = null;
    } else {
      this.expandedObject = row;
    }
  }

  private getOnAllPagesSelectedRow(row): any {
    return this.selectionOnAllPages.selected.find((rowSelected) => JSON.stringify(rowSelected) === JSON.stringify(row));
  }

  private populateSelectedRowOnCurrentPage() {
    this.selectedRowOnPage.clear();
    this.dataSource.getItems().forEach((item) => {
      if (this.isRowSelected(item)) this.selectedRowOnPage.select(item);
    });
  }

  private selectionHasBeenRemoved(rowIndex: number): boolean {
    this.element = document.getElementById(`selection_${rowIndex}`);
    return this.element === null;
  }

  private selectAllCurrentRows() {
    this.selectedRowOnPage.clear();
    this.dataSource.getItems().forEach((row) => {
      if (!this.isRowSelected(row) && !this.selectionHasBeenRemoved(this.getRowIndex(row))) this.selectionOnAllPages.select(row);

      this.selectedRowOnPage.select(row);
    });
  }
}
