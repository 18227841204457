import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { AnimationEvent } from "@angular/animations";
import { ToastRef } from "./toast-ref";
import { toastAnimations, ToastAnimationState } from "./toast-animation";
import { TOAST_CONFIG_TOKEN, ToastConfig } from "../../models/domains/toast/toast-config";
import { ToastData } from "../../models/domains/toast/toast-data";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["toast.component.scss"],
  animations: [toastAnimations.fadeToast],
})
export class ToastComponent implements OnInit, OnDestroy {
  animationState: ToastAnimationState = "default";
  iconType: string;

  private intervalId: any;

  constructor(readonly data: ToastData, readonly ref: ToastRef, @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig) {}

  ngOnInit() {
    if (this.data.delayTime === false) return;

    this.intervalId = setTimeout(() => (this.animationState = "closing"), (this.data.secondsDurantion || 5) * 1000);
  }

  cssClass(toast: ToastData): string {
    if (!toast) {
      return;
    }
    switch (toast.type) {
      case "success":
        return "toast-container toast-success";
      case "warning":
        return "toast-container toast-warning";
      case "info":
        return "toast-container toast-info";
      case "error":
        return "toast-container toast-danger";
    }
  }

  getIcon(toast: ToastData): string {
    if (!toast) {
      return;
    }
    return toast.type === "success" ? "done" : toast.type;
  }

  getToastConfigFadeIn(): number {
    return this.toastConfig.animation.fadeIn;
  }

  getToastConfigFadeOut(): number {
    return this.toastConfig.animation.fadeOut;
  }

  close() {
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === "closing";
    const itFinished = this.animationState === "closing";

    if (isFadeOut && itFinished) {
      this.close();
    }
  }

  ngOnDestroy() {
    clearTimeout(this.intervalId);
  }
}
