import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "socialNumberPipe" })
export class SocialNumberPipe implements PipeTransform {
  transform(value) {
    if (value) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.***.***-$4");
    } else {
      return "";
    }
  }
}
