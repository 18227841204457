export class SocialNumber {
  constructor(private value: string) {}

  setValue(value: string): void {
    this.value = value;
  }

  getValue(): string {
    return this.value;
  }
}
