import { Component, OnInit } from "@angular/core";
import { Shopper } from "src/app/ui/models/domains/pdv/shopper";
import { DateService } from "src/app/ui/services/pdv/date.service";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";

@Component({
  selector: "pdv-beneficiary-header",
  templateUrl: "./beneficiary-header.component.html",
  styleUrls: ["./beneficiary-header.component.scss"],
})
export class BeneficiaryHeaderComponent implements OnInit {
  private shopper: Shopper;

  constructor(private purchaseService: PurchaseService, private dateService: DateService) {}

  ngOnInit(): void {
    this.purchaseService.getSalesOrder().subscribe((salesOrder) => {
      if (salesOrder) {
        this.shopper = salesOrder.getShopper();
      }
    });
  }

  hasShopper(): boolean {
    return this.shopper ? true : false;
  }

  currentDay(): string {
    return this.dateService.getCurrentDay();
  }

  currentMonth(): string {
    return this.dateService.getCurrentMonth();
  }

  currentYear(): string {
    return this.dateService.getCurrentYear();
  }

  currentWeekDay(): string {
    return this.dateService.getCurrentWeekDay();
  }
}
