import { IConfigMedicalPrescription } from "src/app/ui/repositories/parsers/pdv/does-product-require-scan.parser";
import { ErrorCause } from "./error-cause";
import { MedicalPrescription } from "./medical-prescription";
import { Product } from "./product";
import { SalesOrderItemResult } from "./sales-order-item-result";

export class SalesOrderItem {
  private static key = 1;
  private id: number;
  private productQuantity: number;
  private networkPrice: number;
  private networkGrossPrice: number;
  private medicalPrescription: MedicalPrescription;
  private errorCauseList: ErrorCause[] = [];
  private status: string;
  private totalPrice: number;
  private discountSellerError: boolean;

  constructor(private readonly product: Product, public readonly configMedicalPrescription: IConfigMedicalPrescription) {
    this.id = SalesOrderItem.key++;
    this.productQuantity = 1;
    this.medicalPrescription = new MedicalPrescription();
  }

  public getKey(): number {
    return this.id;
  }

  public getProduct(): Product {
    return this.product;
  }

  public addQuantity(): void {
    this.productQuantity = this.productQuantity + 1;
  }

  public removeQuantity(): void {
    this.productQuantity = this.productQuantity - 1;
  }

  public setProductQuantity(value: number): void {
    this.productQuantity = value;
  }

  public getProductQuantity(): number {
    return this.productQuantity;
  }

  public getAuthorizedQuantity(): number {
    return this.product.getAuthorizedQuantity();
  }

  public setNetworkPrice(value: number): void {
    this.networkPrice = value;
  }

  public getNetworkPrice(): number {
    return this.networkPrice;
  }

  public setNetworkGrossPrice(value: number): void {
    this.networkGrossPrice = value;
  }

  public getNetworkGrossPrice(): number {
    return this.networkGrossPrice;
  }

  public getMedicalPrescription(): MedicalPrescription {
    return this.medicalPrescription;
  }

  public setMedicalPrescription(value: MedicalPrescription) {
    if (!value) {
      throw "MedicalPrescription can't be null";
    }
    this.medicalPrescription = value;
  }

  public getRequireMedicalPrescription(): boolean {
    if (!this.configMedicalPrescription) {
      return false;
    }
    return this.configMedicalPrescription.enabled == true;
  }

  public getRequireScan(): boolean {
    if (!this.configMedicalPrescription) {
      return false;
    }
    return this.configMedicalPrescription.requiredScan == true;
  }

  public getPurchaseRequireScan(): boolean {
    if (!this.configMedicalPrescription) {
      return false;
    }
    return this.configMedicalPrescription.purchaseRequiredScan == true;
  }

  public setErrorCauseList(value: ErrorCause[]): void {
    this.errorCauseList = value;
  }

  public getErrorCauseList(): ErrorCause[] {
    return this.errorCauseList;
  }

  public setTotalPrice(value: number): void {
    this.totalPrice = value;
  }

  public getTotalPrice(): number {
    return this.totalPrice;
  }

  public getStartPrice(): number {
    let startPrice: number;
    if (this.product.getUsedNetworkPrice()) {
      if (this.product.getUsedNetworkNetPrice()) {
        startPrice = this.product.getGrossPrice() / this.product.getAuthorizedQuantity();
      } else if (this.product.getUsedNetworkGrossPrice()) {
        startPrice = this.product.getGrossPrice() / this.product.getAuthorizedQuantity();
      } else {
        startPrice = this.product.getPmcPrice();
      }
    } else {
      startPrice = this.product.getPmcPrice();
    }

    return startPrice;
  }

  public hasDiscountSellerError(): boolean {
    return this.discountSellerError;
  }

  public hasBetterExternalDiscount(): boolean {
    return this.product.getExternalAuthorizerDiscount() >= this.calculateDiscount();
  }

  public calculateDiscount(): number {
    let discount = this.product.getDiscount();

    if (this.product.getUsedNetworkSellerPrice()) {
      const sellerPrice = this.product.getSellerUnitPrice();
      const sellerGrossPrice = this.product.getSellerUnitGrossPrice();
      if (sellerGrossPrice >= sellerPrice) {
        const sellerDiscount = ((sellerGrossPrice - sellerPrice) / sellerGrossPrice) * 100;
        if (sellerDiscount >= discount) {
          discount = Number(sellerDiscount.toFixed(2));
        }
        this.discountSellerError = false;
      } else {
        this.discountSellerError = true;
        return 0;
      }
    }

    if (this.product.getHasExternalAuthorizerBetterDiscount()) {
      if (this.product.getExternalAuthorizerDiscount() > discount){
        discount = this.product.getExternalAuthorizerDiscount();
      }
    }

    for (let progressiveDicount of this.product.getProgressiveDiscountList()) {
      if (this.productQuantity) {
        if (progressiveDicount.getProductQuantity() === this.productQuantity && progressiveDicount.getDiscount() > discount) {
          discount = progressiveDicount.getDiscount();
        }
      }
    }

    return discount;
  }

  public calculatePrice(): number {
    if (this.product.getUsedNetworkSellerPrice()) {
      return this.product.getSellerUnitPrice()
    }
    if (this.product.getUsedNetworkPrice() && (this.product.getUsedNetworkNetPrice() || this.product.getUsedNetworkGrossPrice())) {
      return this.product.getNetPrice()
    }

    return this.product.getPrice();
  }

  public calculateDiscountedPrice(): number {
    let price = this.product.getUnitGrossPrice();
    if (this.product.getUsedNetworkSellerPrice()) {
      price = this.product.getSellerUnitGrossPrice();
    }
    const discount = this.calculateDiscount();
    const discountedPrice = price - (price * (discount / 100));
    if(this.product.getUsedNetworkSellerPrice()) {
      this.getProduct().setSellerUnitPrice(discountedPrice);
    }
    return discountedPrice;
  }

  public calculateTotalPrice(): number {
    let price = this.product.getUnitGrossPrice();
    if (this.product.getUsedNetworkSellerPrice()) {
      price = this.product.getSellerUnitGrossPrice();
    }
    const discount = this.calculateDiscount();
    const discountedPrice = price - (price * (discount / 100));
    return discountedPrice * this.productQuantity;
  }

  public hasMedicalPrescriptionInitialized(): boolean {
    return (
      !!this.getMedicalPrescription().getPractitionerRegistration().getState().getValue() ||
      !!this.getMedicalPrescription().getPractitionerRegistration().getRegistration() ||
      !!this.getMedicalPrescription().getMedicalPrescriptionDate()
    );
  }

  public copyFromResultItem(resultItem: SalesOrderItemResult): void {
    this.setErrorCauseList(resultItem.getErrorCauseList());
    this.getProduct().setStatus(resultItem.getStatus());
    this.getProduct().setGrossPrice(resultItem.getGrossPrice());
    this.getProduct().setUnitGrossPrice(resultItem.getUnitGrossPrice());
    this.getProduct().setUnitPrice(resultItem.getUnitPrice());
    this.getProduct().setUsedNetworkPrice(resultItem.getUsedNetworkPrice());
    this.getProduct().setUsedNetworkNetPrice(resultItem.getUsedNetworkNetPrice());
    this.getProduct().setUsedNetworkGrossPrice(resultItem.getUsedNetworkGrossPrice());
    this.getProduct().setDiscount(resultItem.getDiscount());
    this.getProduct().setAuthorizedQuantity(resultItem.getAuthorizedQuantity());
    this.getProduct().setPrice(resultItem.getPrice());
    this.getProduct().setQuantity(resultItem.getProductQuantity());
  }

  public getStatus(): string {
    return this.status;
  }

  public setStatus(value: string): void {
    this.status = value;
  }
}
