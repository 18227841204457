import { Url } from "./url";
import { environment } from "../../../../../environments/environment";

export class MdmBackendUrl extends Url {
  constructor(private action: string) {
    super(action);
  }

  getUrl(): string {
    return environment.mdmBackendUrl + this.action;
  }
}
