import { ProductSubtype } from "./product-subtype";
import { ProductType } from "./product-type";

export class ProductConfiguration {
  private productType = new ProductType();
  private productSubType = new ProductSubtype();
  private purchaseEnabled: boolean;
  private subsidyEnabled: boolean;
  private subsidy: number;

  setProductType(type: ProductType): void {
    this.productType = type;
  }

  getProductType(): ProductType {
    return this.productType;
  }

  setProductSubType(subType: ProductSubtype): void {
    this.productSubType = subType;
  }

  getProductSubType(): ProductSubtype {
    return this.productSubType;
  }

  setPurchaseEnabled(permission: boolean): void {
    this.purchaseEnabled = permission;
  }

  getPurchaseEnabled(): boolean {
    return this.purchaseEnabled;
  }

  setSubsidyEnabled(permission: boolean): void {
    this.subsidyEnabled = permission;
  }

  getSubsidyEnabled(): boolean {
    return this.subsidyEnabled;
  }

  setSubsidy(value: number): void {
    this.subsidy = value;
  }

  getSubsidy(): number {
    return this.subsidy;
  }
}
