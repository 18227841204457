import { Injectable } from "@angular/core";
import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { IndustryProgramsModel } from "../../models/domains/pdv/industry-programs.model";

@Injectable()
export class IndustryProgramsRepository {
  constructor(private remoteGatewayFactory: RemoteGatewayFactory) {}

  public async getStates(pageIndex : number = 1, pageTotal : number = 50): Promise<IndustryProgramsModel> {
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const data : any = await remoteGateway.get(new BackendUrl(`/api/pbm/drugstore/industry-programs/${pageIndex}/${pageTotal}`));
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
