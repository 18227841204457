import { Injectable } from "@angular/core";
import { SalesOrderResult } from "src/app/ui/models/domains/pdv/sales-order-result";
import { AbstractParser } from "../parser";

@Injectable()
export class SalesParser extends AbstractParser<SalesOrderResult> {
  parse(payload: any): SalesOrderResult {
    const sales = new SalesOrderResult();
    if (payload) {
      sales.setUniqueSequentialNumber(payload.Number);
    }
    return sales;
  }
}
