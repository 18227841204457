import { Ean } from "./ean";
import { MedicalPrescriptionDocumentKey } from "./medical-prescription-document-key";
import { StateAcronymModel } from "./state-acronym-model";

export class MedicalPrescriptionViewItem {
  private checked: boolean;
  private file: File;
  private medicalPrescriptionDocumentKey: MedicalPrescriptionDocumentKey;
  private productName: string;
  private pratitionerRegistrationState: StateAcronymModel;
  private pratitionerRegistration: number;
  private medicalPrescriptionDate: string;

  constructor(private readonly ean: Ean, productName: string, pratitionerRegistrationState: StateAcronymModel, pratitionerRegistration: number, medicalPrescriptionDate: string) {
    this.productName = productName;
    this.pratitionerRegistrationState = pratitionerRegistrationState;
    this.pratitionerRegistration = pratitionerRegistration;
    this.medicalPrescriptionDate = medicalPrescriptionDate;
  }

  public getEan(): Ean {
    return this.ean;
  }

  public setMedicalPrescriptionDocumentKey(documentKey: MedicalPrescriptionDocumentKey): void {
    this.medicalPrescriptionDocumentKey = documentKey;
  }

  public getMedicalPrescriptionDocumentKey(): MedicalPrescriptionDocumentKey {
    return this.medicalPrescriptionDocumentKey;
  }

  public hasMedicalPrescriptionSetted(): boolean {
    if (this.pratitionerRegistrationState && this.pratitionerRegistration && this.medicalPrescriptionDate) {
      return true;
    }
    return false;
  }

  public checkItem(): void {
    this.checked = true;
  }

  public unCheckItem(): void {
    this.checked = false;
  }

  public isChecked(): boolean {
    return this.checked;
  }

  public setFile(file: File): void {
    this.file = file;
  }

  public getFile(): File {
    return this.file;
  }

  public getFileName(): string {
    return this.file.name;
  }

  public hasFile(): boolean {
    return !!this.file;
  }

  public getProductName(): string {
    return this.productName;
  }

  public setProductName(productName: string): void {
    this.productName = productName;
  }

  public getPratitionerRegistrationState(): StateAcronymModel {
    return this.pratitionerRegistrationState;
  }

  public setPratitionerRegistrationState(pratitionerRegistrationState: StateAcronymModel): void {
    this.pratitionerRegistrationState = pratitionerRegistrationState;
  }

  public getPratitionerRegistration(): number {
    return this.pratitionerRegistration;
  }

  public setPratitionerRegistration(pratitionerRegistration: number): void {
    this.pratitionerRegistration = pratitionerRegistration;
  }

  public getMedicalPrescriptionDate(): string {
    return this.medicalPrescriptionDate;
  }

  public setMedicalPrescriptionDate(medicalPrescriptionDate: string): void {
    this.medicalPrescriptionDate = medicalPrescriptionDate;
  }
}
