import { Injectable } from "@angular/core";
import { ProductSubtype } from "src/app/ui/models/domains/pdv/Benefit/product-subtype";

@Injectable()
export class ProductSubtypeParser {
  parse(payload: any): Array<ProductSubtype> {
    const productSubtypes: Array<ProductSubtype> = [];
    if (payload.length > 0) {
      payload.forEach((subtype) => {
        const productSubtype = new ProductSubtype();
        productSubtype.setKey(subtype.Key);
        productSubtype.setValue(subtype.Value);
        productSubtypes.push(productSubtype);
      });
    }
    return productSubtypes;
  }
}
