import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AppContext } from "src/app/ui/contexts/app-context";
import { FinancialStatementPagingRequest } from "src/app/ui/models/domains/pdv/financial-statement-paging-request";
import { LoadingService } from "src/app/ui/services/loading.service";
import { NetworkService } from "src/app/ui/services/pdv/network.service";
import { SalesService } from "src/app/ui/services/pdv/sales.service";
import { Toast } from "../../../toast/toast";

@Component({
  selector: "pdv-financial-statement-search-bar",
  templateUrl: "./financial-statement-search-bar.component.html",
  styleUrls: ["./financial-statement-search-bar.component.scss"],
})
export class FinancialStatementSearchBarComponent implements OnInit {
  searchFormGroup: FormGroup = new FormGroup({
    month: new FormControl(""),
    year: new FormControl(new Date().getFullYear()),
  });

  constructor(
    private loadingService: LoadingService,
    private toast: Toast,
    private salesService: SalesService,
    private appContext: AppContext,
    private networkService: NetworkService,
  ) {}

  ngOnInit(): void {
    this.salesService.clearResults();
  }

  async searchNetworkFinancialStatements(): Promise<void> {
    if (!this.validForm()) {
      return null;
    }

    const ref = this.loadingService.beginLoading();
    const fiscalNumber = this.appContext.getNetwork().getFiscalNumber().getValue();
    const year = this.searchFormGroup.get("year").value;
    const month = this.searchFormGroup.get("month").value;

    const pagingRequest = new FinancialStatementPagingRequest(fiscalNumber, year, month, 1, 1);

    try {
      await this.networkService.searchFinancialStatements(pagingRequest);
    } catch (error) {
      this.toast.showWarningToast(error);
    } finally {
      this.loadingService.finishLoading(ref);
    }
  }

  clearResults() {
    this.networkService.clearFinancialStatementsPageableResult();
  }

  hasResults(): boolean {
    return !!this.networkService.getFinancialStatementsPageableResult() && this.networkService.getFinancialStatementsPageableResult().getItems().length > 0;
  }

  validForm(): boolean {
    const month = this.searchFormGroup.get("month").value;
    const year = this.searchFormGroup.get("year").value;

    if (month === undefined) {
      this.toast.showWarningToast("É necessário informar o mês.");
      return false;
    } else if (!year || year.length < 4) {
      this.toast.showWarningToast("É necessário informar o ano.");
      return false;
    }

    return true;
  }
}
