import { Injectable } from "@angular/core";
import { ExportOrderKey } from "../../models/domains/pdv/exports/export-order-key";
import { CSVFileExportResult, XLSXFileExportResult } from "../../models/domains/pdv/exports/file-export-result";
import { FileExportRepository } from "../../repositories/pdv/file-export.repository";

@Injectable({ providedIn: "root" })
export class FileExportService {
  constructor(private fileExportRepository: FileExportRepository) {}

  public async exportFileAsCSV(exportOrderKey: ExportOrderKey): Promise<CSVFileExportResult> {
    var result = await this.fileExportRepository.exportFileAsCSV(exportOrderKey);
    return result;
  }

  public async exportFileAsXLSX(exportOrderKey: ExportOrderKey): Promise<XLSXFileExportResult> {
    return await this.fileExportRepository.exportFileAsXLSX(exportOrderKey);
  }

  public async getFileExportStatus(exportOrderKey: ExportOrderKey): Promise<boolean> {
    var result = await this.fileExportRepository.getFileExportStatus(exportOrderKey);
    return result;
  }

  public async getFileExportStatusNumber(exportOrderKey: ExportOrderKey): Promise<number> {
    var result = await this.fileExportRepository.getFileExportStatusNumber(exportOrderKey);
    return result;
  }
}
