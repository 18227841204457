import { BeneficiaryKey } from "./beneficiary-key";
import { BenefitConfiguration } from "./Benefit/benefit-configuration";
import { Company } from "./company";
import { FinancialBalance } from "./financial-balance";
import { SocialNumber } from "./social-number";

export class Shopper {
  private active: boolean;
  private company: Company;
  private beneficiaryKey: BeneficiaryKey;
  private financialBalance: FinancialBalance;
  private name: string;
  private socialNumber: SocialNumber;
  private virtualCardNumber: string;
  private isBlockedCard: boolean;
  private benefitConfiguration: BenefitConfiguration;
  private haveTransactionalPassword: boolean;
  private transactionalPassword: string;

  public activate(): void {
    this.active = true;
  }

  public deactivate(): void {
    this.active = false;
  }

  public isActive(): boolean {
    return this.active;
  }

  public setBeneficiaryKey(value: BeneficiaryKey): void {
    this.beneficiaryKey = value;
  }

  public getBeneficiaryKey(): BeneficiaryKey {
    return this.beneficiaryKey;
  }

  public setFinancialBalance(value: FinancialBalance): void {
    this.financialBalance = value;
  }

  public getFinancialBalance(): FinancialBalance {
    return this.financialBalance;
  }

  public setIsBlockedCard(value: boolean): void {
    this.isBlockedCard = value;
  }

  public getIsBlockedCard(): boolean {
    return this.isBlockedCard;
  }

  public unlimitedBalance(): boolean {
    return this.financialBalance.getUnlimited();
  }

  public availableValue(): number {
    return this.financialBalance.getAvailable();
  }

  public hasBalance(): boolean {
    return this.financialBalance.getAvailable() > 0 || this.financialBalance.getUnlimited();
  }

  public setName(value: string): void {
    this.name = value;
  }

  public getName(): string {
    return this.name;
  }

  public setVirtualCardNumber(value: string): void {
    this.virtualCardNumber = value;
  }

  public getVirtualCardNumber(): string {
    return this.virtualCardNumber;
  }

  public setSocialNumber(value: SocialNumber): void {
    this.socialNumber = value;
  }

  public getSocialNumber(): SocialNumber {
    return this.socialNumber;
  }

  public setCompany(value: Company): void {
    this.company = value;
  }

  public getCompany(): Company {
    return this.company;
  }

  public setBenefitConfiguration(value: BenefitConfiguration): void {
    this.benefitConfiguration = value;
  }

  public getBenefitConfiguration(): BenefitConfiguration {
    return this.benefitConfiguration;
  }

  public hasBlockedProducts(): boolean {
    return this.benefitConfiguration.getProductConfiguration().getBlockListConfiguration().length > 0;
  }

  public allowedPurchase(): boolean {
    return this.getBenefitConfiguration().getProductConfiguration().getProductClusterExceptInfo().getAllowedPurchase();
  }

  public allowedFullEmploymentPayroll(): boolean {
    return this.getBenefitConfiguration().getProductConfiguration().getProductClusterExceptInfo().getAllowedFullEmploymentPayroll();
  }

  public allowedCheckoutOnline(): boolean {
    return this.getBenefitConfiguration().getProductConfiguration().getProductClusterExceptInfo().getAllowedCheckoutOnline();
  }

  public productSubtypesConfigurated(): string {
    const subsidyEnabled = this.benefitConfiguration
      .getProductConfiguration()
      .getProductConfiguration()
      .filter((item) => item.getSubsidyEnabled());
    const allProductSubTypes = subsidyEnabled.map((item) => item.getProductSubType().getValue());
    const uniqueProductSubTypes = Array.from(new Set(allProductSubTypes));

    return uniqueProductSubTypes.join(", ");
  }

  public maxSubsidy(): number {
    const subsidyList = this.benefitConfiguration
      .getProductConfiguration()
      .getProductConfiguration()
      .map((item) => {
        return item.getSubsidy();
      });
    return Math.max(...subsidyList);
  }

  public setHaveTransactionalPassword(value: boolean): void {
    this.haveTransactionalPassword = value;
  }

  public getHaveTransactionalPassword(): boolean {
    return this.haveTransactionalPassword;
  }

  public getTransactionalPassword(): string {
    return this.transactionalPassword;
  }

  public setTransactionalPassword(transactionalPassword: string): void {
    this.transactionalPassword = transactionalPassword;
  }
}
