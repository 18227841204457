import { Shopper } from "src/app/ui/models/domains/pdv/shopper";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "selector-company-benefit-dialog",
  templateUrl: "./selector-company-benefit-dialog.html",
  styleUrls: ["./selector-company-benefit-dialog.scss"],
})
export class SelectorCompanyBenefitDialog {
  public current: Shopper;
  constructor(public dialogRef: MatDialogRef<SelectorCompanyBenefitDialog>, @Inject(MAT_DIALOG_DATA) public data: Shopper[]) {}

  public getShopperName(): string {
    return this.data[0].getName();
  }

  public getCompanyLabel(benefit: Shopper): string {
    return benefit.getCompany().getCorporateName();
  }

  public getBlockedLabel(benefit: Shopper): string {
    let strRetorno = "";
    if (benefit.getIsBlockedCard()) {
      strRetorno = " - Cartão Bloqueado";
    }
    return strRetorno;
  }
}
