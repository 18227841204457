import { PagingRequest } from "../paginator/paging-request";

export class FinancialStatementPagingRequest extends PagingRequest {
  constructor(private fiscalNumber: string, private referenceYear: number, private referenceMonth: number, private startIndex: number, private endIndex: number) {
    super();
    this.setStart(this.startIndex);
    this.setEnd(this.endIndex);
  }

  public getFiscalNumber(): string {
    return this.fiscalNumber;
  }

  public getReferenceYear(): number {
    return this.referenceYear;
  }

  public getReferenceMonth(): number {
    return this.referenceMonth;
  }
}
