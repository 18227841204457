import { Injectable } from "@angular/core";

import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { BeneficiaryKey } from "../../models/domains/pdv/beneficiary-key";
import { Card } from "../../models/domains/pdv/card";
import { Company } from "../../models/domains/pdv/company";
import { FinancialBalance } from "../../models/domains/pdv/financial-balance";
import { Shopper } from "../../models/domains/pdv/shopper";
import { SocialNumber } from "../../models/domains/pdv/social-number";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { SearchFinancialBalanceBySocialNumberParser } from "../parsers/pdv/search-financial-balance-by-social-number.parser";
import { SearchShopperByParser } from "../parsers/pdv/search-shopper-by.parser";

@Injectable()
export class ShopperRepository {
  constructor(
    private remoteGatewayFactory: RemoteGatewayFactory,
    private searchShopperByParser: SearchShopperByParser,
    private searchFinancialBalanceBySocialNumberParser: SearchFinancialBalanceBySocialNumberParser,
  ) {}

  public async searchShopperBenefitByCardNumber(card: Card): Promise<Shopper> {
    const headers = { "Content-Type": "application/json" };
    try {
            const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway()
            const payload = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/beneficiary/find/card/' + card.getCardNumber()), headers);
            return await Promise.resolve(this.searchShopperByParser.parse(payload)) ;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async searchShopperBnefitsBySocialNumber(socialNumber: SocialNumber): Promise<Shopper[]> {
    const headers = { "Content-Type": "application/json" };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
            const payload = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/benefit/find/' + socialNumber.getValue()), headers) as any[];
            return await Promise.resolve(payload.map(x => {
                return this.searchShopperByParser.parse({Company: x.Company, ...x.Beneficiary})
            }));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async searchFinancialBalance(card: Card): Promise<FinancialBalance> {
    const headers = { "Content-Type": "application/json" };
    try {
            const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway()
            const payload = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/financial-statements/' + card.getCardNumber() + '/balance'), headers);
      return await Promise.resolve(this.searchFinancialBalanceBySocialNumberParser.parse(payload));
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
