import { Injectable } from "@angular/core";
import { ProductCategory } from "src/app/ui/models/domains/pdv/Benefit/product-category";

@Injectable()
export class ProductCategoryParser {
  parse(payload: any): Array<ProductCategory> {
    const productCategoryList: Array<ProductCategory> = [];
    payload.forEach((data) => {
      const productCategory = new ProductCategory();
      productCategory.setKey(data.Key);
      productCategory.setName(data.Name);
      productCategory.setProductTypeKey(data.ProductTypeKey);
      productCategory.setProductSubtypeKey(data.ProductSubTypeKey);
      productCategory.setDiscount(data.Discount);
      productCategoryList.push(productCategory);
    });
    return productCategoryList;
  }
}
