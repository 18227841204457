import { Component, OnInit } from "@angular/core";
import { DateService } from "src/app/ui/services/pdv/date.service";

@Component({
  selector: "pdv-financial-statement",
  templateUrl: "./financial-statement.component.html",
  styleUrls: ["./financial-statement.component.scss"],
})
export class FinancialStatementComponent implements OnInit {
  constructor(private dateService: DateService) {}

  ngOnInit(): void {}

  currentDay(): string {
    return this.dateService.getCurrentDay();
  }

  currentMonth(): string {
    return this.dateService.getCurrentMonth();
  }

  currentYear(): string {
    return this.dateService.getCurrentYear();
  }

  currentWeekDay(): string {
    return this.dateService.getCurrentWeekDay();
  }
}
