import { InjectionToken } from "@angular/core";

export interface ToastConfig {
  position?: {
    top: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
}

export const toastConfig: ToastConfig = {
  position: {
    top: 80,
    right: 80,
  },
  animation: {
    fadeOut: 250,
    fadeIn: 150,
  },
};

export const TOAST_CONFIG_TOKEN = new InjectionToken<ToastConfig>("toast-config");
