import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return "Sem conexão com a Internet";
    }

    if (error.message) {
      return error.message;
    } else {
      return "Ocorreu um erro inesperado.";
    }
  }

  getServerMessage(error: HttpErrorResponse): string {
    let errorMessage: string = "Ocorreu um erro inesperado.";

    switch (error.status) {
      case 0:
        errorMessage = "Sem conexão com a Internet, verifique sua conexão e tente novamente.";
        break;
      case 400:
        errorMessage = error.error.Data.ErrorMessage || "Ocorreu um erro inesperado.";
        break;
      case 401:
        errorMessage = "Não autorizado";
        break;
      case 403:
        errorMessage = "Acesso negado";
        break;
      case 404:
        errorMessage = "Recurso não encontrado";
        break;
      case 500:
        errorMessage = "Erro interno do servidor";
        break;
      default:
        errorMessage = error.error.Data.ErrorMessage || "Ocorreu um erro inesperado.";
        break;
    }

    return errorMessage;
  }
}
