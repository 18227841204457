import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material";
import { Observable } from "rxjs";
import { PurchaseStage } from "src/app/ui/models/domains/pdv/enum/purchase-stage.enum";
import { SalesOrder } from "src/app/ui/models/domains/pdv/sales-order";
import { Shopper } from "src/app/ui/models/domains/pdv/shopper";
import { HomeService } from "src/app/ui/services/pdv/home.service";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";
import { ShopperService } from "src/app/ui/services/pdv/shopper.service";
import { ConfirmDialog } from "../../../dialog/confirm-dialog/confirm-dialog.component";
import { Toast } from "../../../toast/toast";
import { environment } from "src/environments/environment";

@Component({
  selector: "pdv-search-beneficiary-bar",
  templateUrl: "./search-beneficiary-bar.component.html",
  styleUrls: ["./search-beneficiary-bar.component.scss"],
})
export class SearchBeneficiaryBarComponent implements OnInit {
  beneficiaryFormControl = new FormControl();
  private shopper: Shopper;
  public state: number;
  private confirmDialogRef: MatDialogRef<ConfirmDialog>;
  private salesOrder: Observable<SalesOrder>;
  private redirectClerkSupport = environment.clerkSupport;
  private whatsappRedirectLink = environment.redirectToWhatsapp;

  constructor(
    private purchaseService: PurchaseService,
    private shopperService: ShopperService,
    private toast: Toast,
    private homeService: HomeService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.homeService.getPurchaseStage().subscribe((state) => {
      this.state = state;
    });
    this.salesOrder = this.purchaseService.getSalesOrder();
    this.salesOrder.subscribe((salesOrder) => {
      if (salesOrder) {
        this.shopper = salesOrder.getShopper();
      }
    });
  }

  async searchBeneficiary(): Promise<void> {
    try {
      const inputData: string = this.beneficiaryFormControl.value.replace(/\D/g, "");
      this.beneficiaryFormControl.disable();
      await this.shopperService.searchBeneficiary(inputData);
    } catch (error) {
      this.toast.showWarningToast("Beneficiário não encontrado!");
    } finally {
      this.beneficiaryFormControl.setValue("");
      this.beneficiaryFormControl.enable();
    }
  }

  showToast(mensagem: string) {
    this.toast.showWarningToast(mensagem);
  }

  initializeSearchBeneficiary() {
    this.confirmDialogRef = this.dialog.open(ConfirmDialog, {
      disableClose: true,
      hasBackdrop: true,
      height: "250px",
      width: "500px",
      data: {
        title: "Atenção!",
        text: "Ao selecionar outro beneficiário, o processo atual será perdido. Confirma?",
      },
    });

    this.confirmDialogRef.componentInstance.confirmDialogEvent.subscribe(async () => {
      this.purchaseService.addShopper(null);
      this.homeService.standByStage();
    });
  }

  isFinalizeStage() {
    return this.state == PurchaseStage.FinalizeStage;
  }

  getStandByStyle() {
    if (this.shopper) {
      return null;
    } else {
      return { height: "60vh" };
    }
  }

  hasShopper(): boolean {
    return this.shopper ? true : false;
  }

  getNameShopper(): string {
    return this.shopper.getName();
  }

  goToClerkSupport() {
    window.open(this.redirectClerkSupport);
  }

  redirectToWhatsapp() {
    window.open(this.whatsappRedirectLink);
  }

  hasWhatsapp() {
    return !(this.whatsappRedirectLink.length === 0);
  }
}
