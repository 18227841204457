export class Address {
  private street: string;
  private number: string;
  private complement: string;
  private zipCode: string;
  private district: string;
  private city: string;
  private region: string;

  setStreet(value: string): void {
    this.street = value;
  }

  getStreet(): string {
    return this.street;
  }

  setNumber(value: string): void {
    this.number = value;
  }

  getNumber(): string {
    return this.number;
  }

  setComplement(value: string): void {
    this.complement = value;
  }

  getComplement(): string {
    return this.complement;
  }

  setZipCode(value: string): void {
    this.zipCode = value;
  }

  getZipCode(): string {
    return this.zipCode;
  }

  setDistrict(value: string): void {
    this.district = value;
  }

  getDistrict(): string {
    return this.district;
  }

  setCity(value: string): void {
    this.city = value;
  }

  getCity(): string {
    return this.city;
  }

  setRegion(value: string): void {
    this.region = value;
  }

  getRegion(): string {
    return this.region;
  }
}
