import { ErrorCause } from "./error-cause";
import { MedicalPrescription } from "./medical-prescription";
import { ProductResult } from "./product-result";

export class SalesOrderItemResult {
  private authorizedQuantity: number;
  private productQuantity: number;
  private hasMedicalPrescription: boolean;
  private paymentMethod: number;
  private paymentMethodDescription: string;
  private price: number;
  private unitPrice: number;
  private unitGrossPrice: number;
  private mdmPrice: number;
  private grossPrice: number;
  private discount: number;
  private discountValue: number;
  private networkPrice: number;
  private subsidyDiscount: number;
  private subsidyValue: number;
  private cardValue: number;
  private checkoutValue: number;
  private employmentPayrollValue: number;
  private errorCauseList: ErrorCause[];
  private medicalPrescription: MedicalPrescription;
  private usedAttendanceDiscount: boolean;
  private usedNetworkPrice: boolean;
  private usedNetworkGrossPrice: boolean;
  private usedNetworkNetPrice: boolean
  private presentation: string;
  private usedSellerPrice: boolean
  private subsidyAllowed: boolean;
  private status: string;

  constructor(private readonly productResult: ProductResult) { }

  public getStatus(): string {
    return this.status;
  }

  public setStatus(value: string): void {
    this.status = value;
  }

  public getSubsidyAllowed(): boolean {
    return this.subsidyAllowed;
  }

  public setSubsidyAllowed(value: boolean): void {
    this.subsidyAllowed = value;
  }

  getUsedSellerPrice(): boolean {
    return this.usedSellerPrice;
  }

  setUsedSellerPrice(value: boolean): void {
    this.usedSellerPrice = value;
  }
  getAuthorizedQuantity(): number {
    return this.authorizedQuantity;
  }

  setAuthorizedQuantity(value: number): void {
    this.authorizedQuantity = value;
  }

  getProductResult(): ProductResult {
    return this.productResult;
  }

  setProductQuantity(value: number): void {
    this.productQuantity = value;
  }

  getProductQuantity(): number {
    return this.productQuantity;
  }

  setHasMedicalPrescription(value: boolean): void {
    this.hasMedicalPrescription = value;
  }

  getHasMedicalPrescription(): boolean {
    return this.hasMedicalPrescription;
  }

  setPaymentMethod(value: number): void {
    this.paymentMethod = value;
  }

  getPaymentMethod(): number {
    return this.paymentMethod;
  }

  setPaymentMethodDescription(value: string): void {
    this.paymentMethodDescription = value;
  }

  getPaymentMethodDescription(): string {
    return this.paymentMethodDescription;
  }

  setPrice(value: number): void {
    this.price = value;
  }

  getPrice(): number {
    return this.price;
  }

  setUnitPrice(value: number): void {
    this.unitPrice = value;
  }

  getUnitPrice(): number {
    return this.unitPrice;
  }

  setUnitGrossPrice(value: number): void {
    this.unitGrossPrice = value;
  }

  getUnitGrossPrice(): number {
    return this.unitGrossPrice;
  }

  setMdmPrice(value: number): void {
    this.mdmPrice = value;
  }

  getMdmPrice(): number {
    return this.mdmPrice;
  }

  setDiscount(value: number): void {
    this.discount = value;
  }

  getDiscount(): number {
    return this.discount;
  }

  setDiscountValue(value: number): void {
    this.discountValue = value;
  }

  getDiscountValue(): number {
    return this.discountValue;
  }

  setNetworkPrice(value: number): void {
    this.networkPrice = value;
  }

  getNetworkPrice(): number {
    return this.networkPrice;
  }

  getGrossPrice(): number {
    return this.grossPrice;
  }

  setGrossPrice(grossPrice: number): void {
    this.grossPrice = grossPrice;
  }

  setSubsidyDiscount(value: number): void {
    this.subsidyDiscount = value;
  }

  getSubsidyDiscount(): number {
    return this.subsidyDiscount;
  }

  setSubsidyValue(value: number): void {
    this.subsidyValue = value;
  }

  getSubsidyValue(): number {
    return this.subsidyValue;
  }

  setCardValue(value: number): void {
    this.cardValue = value;
  }

  getCardValue(): number {
    return this.cardValue;
  }

  setCheckoutValue(value: number): void {
    this.checkoutValue = value;
  }

  getCheckoutValue(): number {
    return this.checkoutValue;
  }

  setEmploymentPayrollValue(value: number): void {
    this.employmentPayrollValue = value;
  }

  getEmploymentPayrollValue(): number {
    return this.employmentPayrollValue;
  }

  setErrorCauseList(value: ErrorCause[] = []): void {
    this.errorCauseList = value;
  }

  getErrorCauseList(): ErrorCause[] {
    return this.errorCauseList;
  }

  setMedicalPrescription(value: MedicalPrescription): void {
    this.medicalPrescription = value;
  }

  getMedicalPrescription(): MedicalPrescription {
    return this.medicalPrescription;
  }

  getTotalPrice(): number {
    return Number(this.price.toFixed(2));
  }

  getUsedAttendanceDiscount(): boolean {
    return this.usedAttendanceDiscount;
  }

  setUsedAttendanceDiscount(usedAttendanceDiscount: boolean): void {
    this.usedAttendanceDiscount = usedAttendanceDiscount;
  }

  getUsedNetworkGrossPrice(): boolean {
    return this.usedNetworkGrossPrice;
  }

  setUsedNetworkGrossPrice(usedNetworkGrossPrice: boolean): void {
    this.usedNetworkGrossPrice = usedNetworkGrossPrice;
  }

  getUsedNetworkNetPrice(): boolean {
    return this.usedNetworkNetPrice;
  }

  setUsedNetworkNetPrice(usedNetworkNetPrice: boolean): void {
    this.usedNetworkNetPrice = usedNetworkNetPrice;
  }

  getUsedNetworkPrice(): boolean {
    return this.usedNetworkPrice;
  }

  setUsedNetworkPrice(usedNetworkPrice: boolean): void {
    this.usedNetworkPrice = usedNetworkPrice;
  }

  getStartPrice(): number {
    let startPrice: number;

    if (this.getUsedNetworkPrice) {
      if (this.getUsedNetworkNetPrice()) {
        startPrice = this.getGrossPrice() / this.getAuthorizedQuantity();
      } else if (this.getUsedNetworkGrossPrice()) {
        startPrice = this.getGrossPrice() / this.getAuthorizedQuantity();
      } else {
        startPrice = this.getMdmPrice();
      }
    } else {
      startPrice = this.getMdmPrice();
    }

    return startPrice;
  }

  public getPresentation(): string {
    return this.presentation;
  }

  public setPresentation(presentation: string): void {
    this.presentation = presentation;
  }
}
