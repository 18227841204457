import { AlertService } from "../../services/alert.service";
import { Alert, AlertType } from "../../models/domains/alert/alert";
import { Input, Component, Output, EventEmitter } from "@angular/core";

@Component({
  moduleId: module.id,
  selector: "alert",
  templateUrl: "alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent {
  @Input() id: string;
  @Output() remove = new EventEmitter<Alert>();

  alerts: Alert[] = [];
  show: boolean = true;
  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.alertService.getAlert(this.id).subscribe((alert: Alert) => {
      if (!alert.message) {
        this.alerts = [];
        return;
      }
      this.alerts.push(alert);
    });
  }

  removeAlert(alert: Alert) {
    this.alerts = this.alerts.filter((x) => x !== alert);
    this.remove.emit(alert);
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }
    switch (alert.type) {
      case AlertType.Success:
        return "alert alert-success";
      case AlertType.Error:
        return "alert alert-danger";
      case AlertType.Info:
        return "alert alert-info";
      case AlertType.Warning:
        return "alert alert-warning";
    }
  }

  getIcon(alert: Alert) {
    if (!alert) {
      return;
    }
    switch (alert.type) {
      case AlertType.Success:
        return "done";
      case AlertType.Error:
        return "error";
      case AlertType.Info:
        return "info";
      case AlertType.Warning:
        return "warning";
    }
  }
}
