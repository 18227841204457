import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "currencyPdvPipe" })
export class CurrencyPdvPipe implements PipeTransform {
  transform(value: number, currencyCode: string = "BRL", symbolDisplay?: boolean, digits?: string) {
    if (value) {
      const currencyPipe: CurrencyPipe = new CurrencyPipe("pt-BR");
      const newValue: string = currencyPipe.transform(value, currencyCode, symbolDisplay, digits);
      return newValue;
    }
    return "R$ 0,00";
  }
}
