export class SelectedEventArgs {
  private values: Array<any>;

  constructor(values: Array<any>) {
    this.values = values;
  }

  getValues(): Array<any> {
    return this.values;
  }
}
