export class NetworkAcceptTerm {
  constructor(private referenceKey: string, private acceptTermDate: Date) {}

  getReferenceKey(): string {
    return this.referenceKey;
  }

  getAcceptTermDate(): Date {
    return this.acceptTermDate;
  }
}
