import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { EmailType } from "./enum/email-type.enum";
import { TelephoneType } from "./enum/telephone-type.enum";

export class TermFormGroup extends FormGroup {
  createFormData(): FormData {
    const body = this.getRawValue();
    const files = body.Files;
    let formData = new FormData();
    Object.keys(files).forEach((doc) => {
      if (files[doc]) formData.append(doc, files[doc], files[doc].name);
    });
    delete body.Files;
    delete body.Documents;
    formData.append("body", JSON.stringify(body));

    return formData;
  }
}

export enum TermDocument {
  SocialContract = "SocialContract",
  AnvisaPermit = "AnvisaPermit",
  PrefecturePermit = "PrefecturePermit",
  SanitaryPermit = "SanitaryPermit",
  RegularityCertificate = "RegularityCertificate",
  BillingDocument = "BillingDocument",
}

export class TermForm {
  FiscalNumber = {
    Value: "",
  };
  NetworkProductBilling = {
    BankName: "",
    AccountNumber: "",
    AccountDigit: "",
    AgencyNumber: "",
    AgencyDigit: "",
  };
  NetworkContact = {
    CompanyContacts: [
      {
        Key: {
          Value: "",
        },
        Name: "",
        JobDescription: "",
      },
    ],

    NetworkEmails: [
      {
        Key: {
          Value: "",
        },
        Address: "",
        Type: "",
      },
    ],

    NetworkTelephones: [
      {
        Key: {
          Value: "",
        },
        Number: "",
        Type: "",
      },
    ],
  };
  NetworkInfo = {
    Is24HoursService: false,
    WeekDaysOpeningHour: null,
    WeekDaysClosingHour: null,
    SaturdayOpeningHour: null,
    SaturdayClosingHour: null,
    SundayHolidaysOpeningHour: null,
    SundayHolidaysClosingHour: null,
    IndustrializedMedicine: false,
    Manipulation: false,
    Homeopathy: false,
    Delivery: false,
    DeliveryFee: 0,
    MinimumValue: 0,
    SoftwareHouse: "",
    MedicalPrescription: null,
    FinancialReportPeriodType: "Biweekly",
    AdministrativeTax: 0,
    HighCostMedicine: false,
    PopularPharmacy: false,
    CentralizerFiscalNumber: {
      Value: "",
    },
    PaymentTerm: 0,
  };
  NetworkProductDiscounts = [
    {
      Discount: 10,
      Label: "Marcas",
      ProductTypeKey: "0d0695c9-1582-48e7-b55b-b411e6c1e333",
      ProductSubTypeKey: "96d1369f-54d2-4b18-975d-02e43a0d2490",
    },
    {
      Discount: 15,
      Label: "Genérico",
      ProductTypeKey: "0d0695c9-1582-48e7-b55b-b411e6c1e333",
      ProductSubTypeKey: "2b5d851b-1952-482e-8a16-29e59ba0977d",
    },
  ];
  NetworkDocumentation = {
    BillingDocument: {
      Filename: "",
      DocumentUrl: "",
    },
    SocialContract: {
      Filename: "",
      DocumentUrl: "",
    },
    AnvisaPermit: {
      DocumentNumber: "",
      Filename: "",
      Validity: "",
      DocumentUrl: "",
    },
    PrefecturePermit: {
      DocumentNumber: "",
      Filename: "",
      Validity: "",
      DocumentUrl: "",
    },
    SanitaryPermit: {
      DocumentNumber: "",
      Filename: "",
      Validity: "",
      DocumentUrl: "",
    },
    RegularityCertificate: {
      ProfessionalName: "",
      ProfessionalRegistration: "",
      Filename: "",
      DocumentUrl: "",
    },
  };
  Documents = {
    SocialContract: null,
    AnvisaPermit: null,
    PrefecturePermit: null,
    SanitaryPermit: null,
    RegularityCertificate: null,
    BillingDocument: null,
  };

  static createForm(form: TermForm = new TermForm()): TermFormGroup {
    const builder = new FormBuilder();

    const companyContactsFormArray = new FormArray([]);
    if (form.NetworkContact.CompanyContacts.length > 0) {
      for (let i = 0; i < form.NetworkContact.CompanyContacts.length; i++) {
        const contact = new FormGroup({
          Key: new FormGroup({
            Value: new FormControl(form.NetworkContact.CompanyContacts[i].Key.Value),
          }),
          Name: new FormControl(form.NetworkContact.CompanyContacts[i].Name),
          JobDescription: new FormControl(form.NetworkContact.CompanyContacts[i].JobDescription),
        });

        companyContactsFormArray.push(contact);
      }
    } else {
      const contact = new FormGroup({
        Key: new FormGroup({
          Value: new FormControl(""),
        }),
        Name: new FormControl(""),
        JobDescription: new FormControl(""),
      });

      companyContactsFormArray.push(contact);
    }

    const networkEmailsFormArray = new FormArray([]);
    if (form.NetworkContact.NetworkEmails.length > 0) {
      for (let i = 0; i < form.NetworkContact.NetworkEmails.length; i++) {
        const email = new FormGroup({
          Key: new FormGroup({
            Value: new FormControl(form.NetworkContact.NetworkEmails[i].Key.Value),
          }),
          Address: new FormControl(form.NetworkContact.NetworkEmails[i].Address),
          Type: new FormControl(form.NetworkContact.NetworkEmails[i].Type),
        });

        networkEmailsFormArray.push(email);
      }
    } else {
      const email = new FormGroup({
        Key: new FormGroup({
          Value: new FormControl(""),
        }),
        Address: new FormControl(""),
        Type: new FormControl(EmailType.others),
      });

      const financialemail = new FormGroup({
        Key: new FormGroup({
          Value: new FormControl(""),
        }),
        Address: new FormControl(""),
        Type: new FormControl(EmailType.financial),
      });

      networkEmailsFormArray.push(email);
      networkEmailsFormArray.push(financialemail);
    }

    const networkTelephonesFormArray = new FormArray([]);
    if (form.NetworkContact.NetworkEmails.length > 0) {
      for (let i = 0; i < form.NetworkContact.NetworkTelephones.length; i++) {
        const telephone = new FormGroup({
          Key: new FormGroup({
            Value: new FormControl(form.NetworkContact.NetworkTelephones[i].Key.Value),
          }),
          Number: new FormControl(form.NetworkContact.NetworkTelephones[i].Number),
          Type: new FormControl(form.NetworkContact.NetworkTelephones[i].Type),
        });

        networkTelephonesFormArray.push(telephone);
      }
    } else {
      const telephone1 = new FormGroup({
        Key: new FormGroup({
          Value: new FormControl(""),
        }),
        Number: new FormControl(""),
        Type: new FormControl(TelephoneType.work),
      });
      const telephone2 = new FormGroup({
        Key: new FormGroup({
          Value: new FormControl(""),
        }),
        Number: new FormControl(""),
        Type: new FormControl(TelephoneType.work),
      });
      const mobile = new FormGroup({
        Key: new FormGroup({
          Value: new FormControl(""),
        }),
        Number: new FormControl(""),
        Type: new FormControl(TelephoneType.mobile),
      });

      networkTelephonesFormArray.push(telephone1);
      networkTelephonesFormArray.push(telephone2);
      networkTelephonesFormArray.push(mobile);
    }

    const formGroup = new TermFormGroup({
      FiscalNumber: new FormGroup({
        Value: new FormControl(form.FiscalNumber.Value, []),
      }),
      NetworkProductBilling: new FormGroup({
        BankName: new FormControl(form.NetworkProductBilling.BankName, [Validators.required]),
        AgencyNumber: new FormControl(form.NetworkProductBilling.AgencyNumber, [Validators.required]),
        AgencyDigit: new FormControl(form.NetworkProductBilling.AgencyDigit, [Validators.required]),
        AccountNumber: new FormControl(form.NetworkProductBilling.AccountNumber, [Validators.required]),
        AccountDigit: new FormControl(form.NetworkProductBilling.AccountDigit, [Validators.required]),
      }),
      NetworkContact: new FormGroup({
        CompanyContacts: companyContactsFormArray,
        NetworkEmails: networkEmailsFormArray,
        NetworkTelephones: networkTelephonesFormArray,
      }),
      NetworkInfo: new FormGroup({
        Is24HoursService: new FormControl(form.NetworkInfo.Is24HoursService, []),
        WeekDaysOpeningHour: new FormControl(
          {
            value: form.NetworkInfo.WeekDaysOpeningHour,
            disabled: form.NetworkInfo.Is24HoursService,
          },
          [],
        ),
        WeekDaysClosingHour: new FormControl(
          {
            value: form.NetworkInfo.WeekDaysClosingHour,
            disabled: form.NetworkInfo.Is24HoursService,
          },
          [],
        ),
        SaturdayOpeningHour: new FormControl(
          {
            value: form.NetworkInfo.SaturdayOpeningHour,
            disabled: form.NetworkInfo.Is24HoursService,
          },
          [],
        ),
        SaturdayClosingHour: new FormControl(
          {
            value: form.NetworkInfo.SaturdayClosingHour,
            disabled: form.NetworkInfo.Is24HoursService,
          },
          [],
        ),
        SundayHolidaysOpeningHour: new FormControl(
          {
            value: form.NetworkInfo.SundayHolidaysOpeningHour,
            disabled: form.NetworkInfo.Is24HoursService,
          },
          [],
        ),
        SundayHolidaysClosingHour: new FormControl(
          {
            value: form.NetworkInfo.SundayHolidaysClosingHour,
            disabled: form.NetworkInfo.Is24HoursService,
          },
          [],
        ),
        IndustrializedMedicine: new FormControl(form.NetworkInfo.IndustrializedMedicine, []),
        Manipulation: new FormControl(form.NetworkInfo.Manipulation, []),
        Homeopathy: new FormControl(form.NetworkInfo.Homeopathy, []),
        Delivery: new FormControl(form.NetworkInfo.Delivery, []),
        DeliveryFee: new FormControl(
          {
            value: form.NetworkInfo.DeliveryFee,
            disabled: !form.NetworkInfo.Delivery,
          },
          [],
        ),
        MinimumValue: new FormControl(
          {
            value: form.NetworkInfo.MinimumValue,
            disabled: !form.NetworkInfo.Delivery,
          },
          [],
        ),
        SoftwareHouse: new FormControl(form.NetworkInfo.SoftwareHouse, []),
        MedicalPrescription: new FormControl(form.NetworkInfo.MedicalPrescription, []),
        FinancialReportPeriodType: new FormControl(form.NetworkInfo.FinancialReportPeriodType, [Validators.required]),
        AdministrativeTax: new FormControl({ value: form.NetworkInfo.AdministrativeTax, disabled: true }, []),
        HighCostMedicine: new FormControl(form.NetworkInfo.HighCostMedicine, []),
        PopularPharmacy: new FormControl(form.NetworkInfo.PopularPharmacy, []),
        CentralizerFiscalNumber: new FormGroup({
          Value: new FormControl(form.NetworkInfo.CentralizerFiscalNumber.Value, [Validators.required]),
        }),
        PaymentTerm: new FormControl(form.NetworkInfo.PaymentTerm, [Validators.required]),
      }),
      NetworkProductDiscounts: builder.array(
        form.NetworkProductDiscounts.map((el) => {
          let minDiscount: number;
          if (el.ProductSubTypeKey == "2b5d851b-1952-482e-8a16-29e59ba0977d") {
            minDiscount = 15;
          } else {
            minDiscount = 10;
          }

          return new FormGroup(
            {
              Discount: new FormControl(el.Discount, [Validators.required, Validators.min(minDiscount)]),
              Label: new FormControl(el.Label, []),
              ProductTypeKey: new FormControl(el.ProductTypeKey, []),
              ProductSubTypeKey: new FormControl(el.ProductSubTypeKey, []),
            },
            validateMinDiscount,
          );
        }),
      ),
      NetworkDocumentation: new FormGroup({
        BillingDocument: new FormGroup({
          DocumentUrl: new FormControl(form.NetworkDocumentation.BillingDocument.DocumentUrl, []),
          Filename: new FormControl(form.NetworkDocumentation.BillingDocument.Filename, []),
        }),
        SocialContract: new FormGroup({
          DocumentUrl: new FormControl(form.NetworkDocumentation.SocialContract.DocumentUrl, []),
          Filename: new FormControl(form.NetworkDocumentation.SocialContract.Filename, []),
        }),
        AnvisaPermit: new FormGroup({
          DocumentNumber: new FormControl(form.NetworkDocumentation.AnvisaPermit.DocumentNumber, []),
          Validity: new FormControl(form.NetworkDocumentation.AnvisaPermit.Validity, []),
          DocumentUrl: new FormControl(form.NetworkDocumentation.AnvisaPermit.DocumentUrl, []),
          Filename: new FormControl(form.NetworkDocumentation.AnvisaPermit.Filename, []),
        }),
        PrefecturePermit: new FormGroup({
          DocumentNumber: new FormControl(form.NetworkDocumentation.PrefecturePermit.DocumentNumber, []),
          Validity: new FormControl(form.NetworkDocumentation.PrefecturePermit.Validity, []),
          DocumentUrl: new FormControl(form.NetworkDocumentation.PrefecturePermit.DocumentUrl, []),
          Filename: new FormControl(form.NetworkDocumentation.PrefecturePermit.Filename, []),
        }),
        SanitaryPermit: new FormGroup({
          DocumentNumber: new FormControl(form.NetworkDocumentation.SanitaryPermit.DocumentNumber, []),
          Validity: new FormControl(form.NetworkDocumentation.SanitaryPermit.Validity, []),
          DocumentUrl: new FormControl(form.NetworkDocumentation.SanitaryPermit.DocumentUrl, []),
          Filename: new FormControl(form.NetworkDocumentation.SanitaryPermit.Filename, []),
        }),
        RegularityCertificate: new FormGroup({
          ProfessionalName: new FormControl(form.NetworkDocumentation.RegularityCertificate.ProfessionalName, []),
          ProfessionalRegistration: new FormControl(form.NetworkDocumentation.RegularityCertificate.ProfessionalRegistration, []),
          DocumentUrl: new FormControl(form.NetworkDocumentation.RegularityCertificate.DocumentUrl, []),
          Filename: new FormControl(form.NetworkDocumentation.RegularityCertificate.Filename, []),
        }),
      }),
      Documents: new FormGroup(
        {
          SocialContract: new FormControl(form.Documents.SocialContract, [documentTypeValidator]),
          AnvisaPermit: new FormControl(form.Documents.AnvisaPermit, [documentTypeValidator]),
          PrefecturePermit: new FormControl(form.Documents.PrefecturePermit, [documentTypeValidator]),
          SanitaryPermit: new FormControl(form.Documents.SanitaryPermit, [documentTypeValidator]),
          RegularityCertificate: new FormControl(form.Documents.RegularityCertificate, [documentTypeValidator]),
          BillingDocument: new FormControl(
            form.Documents.BillingDocument,
            form.NetworkDocumentation.BillingDocument.DocumentUrl ? [documentTypeValidator] : [Validators.required, documentTypeValidator],
          ),
        },
        validateAllDocumentsType,
      ),
      Files: new FormGroup({
        SocialContract: new FormControl(null, []),
        AnvisaPermit: new FormControl(null, []),
        PrefecturePermit: new FormControl(null, []),
        SanitaryPermit: new FormControl(null, []),
        RegularityCertificate: new FormControl(null, []),
        BillingDocument: new FormControl(null, []),
      }),
    });

    return formGroup;
  }
}

function documentTypeValidator(control: AbstractControl) {
  if (!!control.value) {
    if (control.value.match(/\.[0-9a-z]+$/i)[0] !== ".pdf") {
      return { documentTypeValidator: true };
    }
  }
  return null;
}

export const validateAllDocumentsType: ValidatorFn = (formGroup: FormGroup) => {
  if (formGroup) {
    const socialContractErrors = formGroup.get("SocialContract").errors;
    if (socialContractErrors && socialContractErrors.documentTypeValidator) {
      return { validateAllDocumentsType: true };
    }

    const anvisaPermitErrors = formGroup.get("AnvisaPermit").errors;
    if (anvisaPermitErrors && anvisaPermitErrors.documentTypeValidator) {
      return { validateAllDocumentsType: true };
    }

    const prefecturePermitErros = formGroup.get("PrefecturePermit").errors;
    if (prefecturePermitErros && prefecturePermitErros.documentTypeValidator) {
      return { validateAllDocumentsType: true };
    }

    const sanitaryPermitErros = formGroup.get("SanitaryPermit").errors;
    if (sanitaryPermitErros && sanitaryPermitErros.documentTypeValidator) {
      return { validateAllDocumentsType: true };
    }

    const regularityCertificateErros = formGroup.get("RegularityCertificate").errors;
    if (regularityCertificateErros && regularityCertificateErros.documentTypeValidator) {
      return { validateAllDocumentsType: true };
    }

    const billingDocumentErros = formGroup.get("BillingDocument").errors;
    if (billingDocumentErros && billingDocumentErros.documentTypeValidator) {
      return { validateAllDocumentsType: true };
    }
    return null;
  }
  return null;
};

export const validateMinDiscount: ValidatorFn = (formGroup: FormGroup) => {
  if (formGroup) {
    const discountErrors = formGroup.get("Discount").errors;
    if (discountErrors && (discountErrors.min || discountErrors.reqrequired)) {
      return { validateAllDocumentsType: true };
    }
    return null;
  }
  return null;
};
