import { Injectable } from "@angular/core";
import { AppContext } from "../../contexts/app-context";
import { StateAcronymModel } from "../../models/domains/pdv/state-acronym-model";
import { StateRepository } from "../../repositories/pdv/state.repository";

@Injectable({ providedIn: "root" })
export class StateService {
  constructor(private stateRepository: StateRepository) {}

  public async getStates(): Promise<Array<StateAcronymModel>> {
    return await this.stateRepository.getStates();
  }
}
