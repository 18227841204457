import { Injectable } from "@angular/core";
import { CSVFileExportResult } from "src/app/ui/models/domains/pdv/exports/file-export-result";
import { AbstractParser } from "../parser";

@Injectable()
export class ExportFileParser extends AbstractParser<CSVFileExportResult> {
  parse(payload: any): CSVFileExportResult {
    const fileExportResult = new CSVFileExportResult(payload);
    return fileExportResult;
  }
}
