import { EventEmitter } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Component({
  templateUrl: "./complete-term-dialog.component.html",
  styleUrls: ["./complete-term-dialog.component.scss"],
})
export class CompleteTermDialogComponent implements OnInit {
  public completeTermEvent: EventEmitter<boolean>;

  constructor(public dialogRef: MatDialogRef<CompleteTermDialogComponent>) {
    this.completeTermEvent = new EventEmitter();
  }

  ngOnInit() {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  completeTermNow() {
    this.completeTermEvent.emit(true);
    this.closeDialog();
  }

  completeTermLater() {
    this.completeTermEvent.emit(false);
    this.closeDialog();
  }
}
