import { TermForm } from "src/app/ui/models/domains/pdv/form-term";
import { AbstractParser } from "../parser";

export class TermFormParser extends AbstractParser<TermForm> {
  parse(payload: any): TermForm {
    const termForm = new TermForm();
    if (!payload) return termForm;

    termForm.FiscalNumber.Value = payload.FiscalNumber.Value;
    if (payload.NetworkProductBilling) {
      termForm.NetworkProductBilling.BankName = payload.NetworkProductBilling.BankName;
      termForm.NetworkProductBilling.AccountNumber = payload.NetworkProductBilling.AccountNumber;
      termForm.NetworkProductBilling.AccountDigit = payload.NetworkProductBilling.AccountDigit;
      termForm.NetworkProductBilling.AgencyNumber = payload.NetworkProductBilling.AgencyNumber;
      termForm.NetworkProductBilling.AgencyDigit = payload.NetworkProductBilling.AgencyDigit;
      if (payload.NetworkProductBilling.NetworkProductBillingDocument) {
        termForm.NetworkDocumentation.BillingDocument.DocumentUrl = payload.NetworkProductBilling.NetworkProductBillingDocument.DocumentUrl;
        termForm.NetworkDocumentation.BillingDocument.Filename = payload.NetworkProductBilling.NetworkProductBillingDocument.Filename;
      }
    }
    if (payload.NetworkContact) {
      const companyContacts = new Array<any>();
      payload.NetworkContact.CompanyContacts.forEach((contact) => {
        const companyContact = {
          Key: {
            Value: contact.Key.Value,
          },
          Name: contact.Name,
          JobDescription: contact.JobDescription,
        };
        companyContacts.push(companyContact);
      });

      const networkEmails = new Array<any>();
      payload.NetworkContact.NetworkEmails.forEach((email) => {
        const networkEmail = {
          Key: {
            Value: email.Key.Value,
          },
          Address: email.Address,
          Type: email.Type,
        };
        networkEmails.push(networkEmail);
      });

      const networkTelephones = new Array<any>();
      payload.NetworkContact.NetworkTelephones.forEach((telephone) => {
        const networkTelephone = {
          Key: {
            Value: telephone.Key.Value,
          },
          Number: telephone.Number,
          Type: telephone.Type,
        };
        networkTelephones.push(networkTelephone);
      });

      termForm.NetworkContact.CompanyContacts = companyContacts;
      termForm.NetworkContact.NetworkEmails = networkEmails;
      termForm.NetworkContact.NetworkTelephones = networkTelephones;
    }
    if (payload.NetworkInfo) {
      termForm.NetworkInfo.Is24HoursService = payload.NetworkInfo.Is24HoursService;
      termForm.NetworkInfo.WeekDaysOpeningHour = payload.NetworkInfo.WeekDaysOpeningHour;
      termForm.NetworkInfo.WeekDaysClosingHour = payload.NetworkInfo.WeekDaysClosingHour;
      termForm.NetworkInfo.SaturdayOpeningHour = payload.NetworkInfo.SaturdayOpeningHour;
      termForm.NetworkInfo.SaturdayClosingHour = payload.NetworkInfo.SaturdayClosingHour;
      termForm.NetworkInfo.SundayHolidaysOpeningHour = payload.NetworkInfo.SundayHolidaysOpeningHour;
      termForm.NetworkInfo.SundayHolidaysClosingHour = payload.NetworkInfo.SundayHolidaysClosingHour;
      termForm.NetworkInfo.IndustrializedMedicine = payload.NetworkInfo.IndustrializedMedicine;
      termForm.NetworkInfo.Manipulation = payload.NetworkInfo.Manipulation;
      termForm.NetworkInfo.Homeopathy = payload.NetworkInfo.Homeopathy;
      termForm.NetworkInfo.Delivery = payload.NetworkInfo.Delivery;
      termForm.NetworkInfo.DeliveryFee = payload.NetworkInfo.DeliveryFee;
      termForm.NetworkInfo.MinimumValue = payload.NetworkInfo.MinimumValue;
      termForm.NetworkInfo.SoftwareHouse = payload.NetworkInfo.SoftwareHouse;
      termForm.NetworkInfo.MedicalPrescription = payload.NetworkInfo.MedicalPrescription;
      termForm.NetworkInfo.FinancialReportPeriodType = payload.NetworkInfo.FinancialReportPeriodType;
      termForm.NetworkInfo.AdministrativeTax = payload.NetworkInfo.AdministrativeTax;
      termForm.NetworkInfo.HighCostMedicine = payload.NetworkInfo.HighCostMedicine;
      termForm.NetworkInfo.PopularPharmacy = payload.NetworkInfo.PopularPharmacy;
      termForm.NetworkInfo.CentralizerFiscalNumber.Value = payload.NetworkInfo.CentralizerFiscalNumber.Value;
      termForm.NetworkInfo.PaymentTerm = payload.NetworkInfo.PaymentTerm;
    }
    payload.NetworkProductDiscounts.forEach((item) => {
      termForm.NetworkProductDiscounts.forEach((termItem) => {
        if (termItem.ProductSubTypeKey === item.ProductSubTypeKey) termItem.Discount = item.Discount;
      });
    });
    if (payload.NetworkDocumentation) {
      if (payload.NetworkDocumentation.SocialContract) {
        termForm.NetworkDocumentation.SocialContract.DocumentUrl = payload.NetworkDocumentation.SocialContract.DocumentUrl;
        termForm.NetworkDocumentation.SocialContract.Filename = payload.NetworkDocumentation.SocialContract.Filename;
      }
      if (payload.NetworkDocumentation.AnvisaPermit) {
        termForm.NetworkDocumentation.AnvisaPermit.DocumentNumber = payload.NetworkDocumentation.AnvisaPermit.DocumentNumber;
        termForm.NetworkDocumentation.AnvisaPermit.Validity = payload.NetworkDocumentation.AnvisaPermit.Validity;
        termForm.NetworkDocumentation.AnvisaPermit.DocumentUrl = payload.NetworkDocumentation.AnvisaPermit.DocumentUrl;
        termForm.NetworkDocumentation.AnvisaPermit.Filename = payload.NetworkDocumentation.AnvisaPermit.Filename;
      }
      if (payload.NetworkDocumentation.PrefecturePermit) {
        termForm.NetworkDocumentation.PrefecturePermit.DocumentNumber = payload.NetworkDocumentation.PrefecturePermit.DocumentNumber;
        termForm.NetworkDocumentation.PrefecturePermit.Validity = payload.NetworkDocumentation.PrefecturePermit.Validity;
        termForm.NetworkDocumentation.PrefecturePermit.DocumentUrl = payload.NetworkDocumentation.PrefecturePermit.DocumentUrl;
        termForm.NetworkDocumentation.PrefecturePermit.Filename = payload.NetworkDocumentation.PrefecturePermit.Filename;
      }
      if (payload.NetworkDocumentation.RegularityCertificate) {
        termForm.NetworkDocumentation.RegularityCertificate.ProfessionalName = payload.NetworkDocumentation.RegularityCertificate.ProfessionalName;
        termForm.NetworkDocumentation.RegularityCertificate.ProfessionalRegistration = payload.NetworkDocumentation.RegularityCertificate.ProfessionalRegistration;
        termForm.NetworkDocumentation.RegularityCertificate.DocumentUrl = payload.NetworkDocumentation.RegularityCertificate.DocumentUrl;
        termForm.NetworkDocumentation.RegularityCertificate.Filename = payload.NetworkDocumentation.RegularityCertificate.Filename;
      }
      if (payload.NetworkDocumentation.SanitaryPermit) {
        termForm.NetworkDocumentation.SanitaryPermit.DocumentNumber = payload.NetworkDocumentation.SanitaryPermit.DocumentNumber;
        termForm.NetworkDocumentation.SanitaryPermit.Validity = payload.NetworkDocumentation.SanitaryPermit.Validity;
        termForm.NetworkDocumentation.SanitaryPermit.DocumentUrl = payload.NetworkDocumentation.SanitaryPermit.DocumentUrl;
        termForm.NetworkDocumentation.SanitaryPermit.Filename = payload.NetworkDocumentation.SanitaryPermit.Filename;
      }
    }
    return termForm;
  }
}
