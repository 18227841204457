import { Injectable } from "@angular/core";
import { ConsultSalesPagingRequest } from "../../models/domains/paginator/consult-sales-paging-request";
import { SalesOrderResult } from "../../models/domains/pdv/sales-order-result";
import { SalesRepository } from "../../repositories/pdv/sales.repository";

@Injectable({ providedIn: "root" })
export class SalesService {
  private salesFoundList: Array<SalesOrderResult>;
  private SelectedSale: SalesOrderResult;

  constructor(private salesRepository: SalesRepository) {
    this.clearResults();
  }

  public clearResults() {
    this.salesFoundList = new Array<SalesOrderResult>();
    this.SelectedSale = null;
  }

  public async searchSales(consultSalesPagingRequest: ConsultSalesPagingRequest): Promise<any> {
    try {
      this.clearResults();
      const salesFoundList = await this.salesRepository.searchSales(consultSalesPagingRequest);
      if (salesFoundList) {
        this.salesFoundList = salesFoundList;
        Promise.resolve(true);
      } else {
        Promise.resolve(false);
      }
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async refundSale(salesOrderResult: SalesOrderResult): Promise<any> {
    try {
      const refundResponse = await this.salesRepository.refundSale(salesOrderResult);
      Promise.resolve(refundResponse);
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public getSalesFoundList(): Array<SalesOrderResult> {
    return this.salesFoundList;
  }

  public setSalesFoundList(salesFoundList: Array<SalesOrderResult>) {
    this.salesFoundList = salesFoundList;
  }

  public getSelectedSale(): SalesOrderResult {
    return this.SelectedSale;
  }

  public setSelectedSale(SelectedSale: SalesOrderResult): void {
    this.SelectedSale = SelectedSale;
  }
}
