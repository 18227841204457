import { Component, AfterViewInit, Input, ElementRef, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { UserCredentials } from "../../../models/domains/credentials/user-credentials.model";

@Component({
  selector: "nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements AfterViewInit {
  @Input() appName: string;
  @Input() urlLogo: string;
  @Input() version: string;
  @Input() userCredentials: UserCredentials;
  @Input() color: string = "primary";
  @Input() title: string;
  @Output() logOut = new EventEmitter();
  @Output() changePassword = new EventEmitter();
  @Output() healthCheck = new EventEmitter();

  afterViewInit: boolean = false;

  constructor(public el: ElementRef, private ref: ChangeDetectorRef) {}

  logOutApp(): void {
    this.logOut.emit();
  }

  changePasswordAccount(): void {
    this.changePassword.emit();
  }

  health(): void {
    this.healthCheck.emit();
  }

  ngAfterViewInit(): void {
    this.setCustomClass();
    this.afterViewInit = true;
    this.ref.detectChanges();
  }

  setCustomClass() {
    let header = (this.el.nativeElement.querySelectorAll("#header") as HTMLElement)[0];
    header.classList.add(this.color);
  }
}
