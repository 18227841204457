import { RuleEngineConfiguration } from "src/app/ui/models/domains/pdv/rule-engine-configuration";
import { AbstractParser } from "../parser";

export class RuleEngineConfigurationLitsParser extends AbstractParser<RuleEngineConfiguration[]> {
  parse(payload: any): RuleEngineConfiguration[] {
    return payload.Items.map((item) => {
      return new RuleEngineConfiguration(item.SalesAction, item.MinSalesHours);
    });
  }
}
