import { Ean } from "./ean";

export class ProductResult {
  private name: string;
  private ean: Ean;
  private laboratory: string;
  private presentation: string;
  private substance: string;
  private type: string;
  private subType: string;

  setName(value: string): void {
    this.name = value;
  }

  getName(): string {
    return this.name;
  }

  setEan(value: Ean): void {
    this.ean = value;
  }

  getEan(): Ean {
    return this.ean;
  }

  setLaboratory(value: string): void {
    this.laboratory = value;
  }

  getLaboratory(): string {
    return this.laboratory;
  }

  setPresentation(value: string): void {
    this.presentation = value;
  }

  getPresentation(): string {
    return this.presentation;
  }

  setSubstance(value: string): void {
    this.substance = value;
  }

  getSubstance(): string {
    return this.substance;
  }

  setType(value: string): void {
    this.type = value;
  }

  getType(): string {
    return this.type;
  }

  setSubType(value: string): void {
    this.subType = value;
  }

  getSubType(): string {
    return this.subType;
  }
}
