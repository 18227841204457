import { Injectable } from "@angular/core";
import { FinancialBalance } from "src/app/ui/models/domains/pdv/financial-balance";

import { AbstractParser } from "../parser";

@Injectable()
export class SearchFinancialBalanceBySocialNumberParser extends AbstractParser<FinancialBalance> {
  parse(payload: any): FinancialBalance {
    const financialBalance = new FinancialBalance();
    financialBalance.setAvailable(payload.Available);
    financialBalance.setCycleClosedDay(payload.CycleClosedDay);
    financialBalance.setLimit(payload.Limit);
    financialBalance.setPayroll(payload.Payroll);
    financialBalance.setSales(payload.Sales);
    financialBalance.setSubsidy(payload.Subsidy);
    financialBalance.setUnlimited(payload.Unlimited);
    return financialBalance;
  }
}
