import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { IndustryProgram } from "src/app/ui/models/domains/pdv/industry-programs.model";
import { IndustryProgramsRepository } from "src/app/ui/repositories/pdv/industry-programs.repository";
import flickity from "flickity";
import { BannersService } from "src/app/ui/services/observers/banners-observer.service";

@Component({
  selector: "pdv-add-footer",
  templateUrl: "./add-footer.component.html",
  styleUrls: ["./add-footer.component.scss"],
})
export class AddFooterComponent implements OnInit {
  industryProgramList: IndustryProgram[] = [];

  constructor(
    private industryProgramsRepository: IndustryProgramsRepository,
    private bannersService: BannersService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.bannersService.industryPrograms$
      .subscribe(async data => {
        if (!data) {
          await this.getIndustryPrograms();
        } else {
          this.industryProgramList = data.Data;
          this.cdr.detectChanges();
          document.querySelectorAll(".carousel").forEach(c => {
            let flick = new flickity(c, {
              cellAlign: "left", wrapAround: true, pageDots: false, groupCells: 3, autoPlay: true,
              pauseAutoPlayOnHover: true
            });
          });
        }
      });
  }

  async getIndustryPrograms() {
    await this.industryProgramsRepository.getStates()
      .then((data) => {
        this.bannersService.setIndustryPrograms(data);
      });
  }
}
