import { Injectable } from '@angular/core';
import { IndustryProgramsModel } from '../../models/domains/pdv/industry-programs.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannersService {
  private _industryProgramsSubject = new BehaviorSubject<IndustryProgramsModel | null>(null);
  industryPrograms$ = this._industryProgramsSubject.asObservable();

  setIndustryPrograms(data: IndustryProgramsModel) {
    this._industryProgramsSubject.next(data);
  }
}