import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AppContext } from "../../contexts/app-context";
import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { FiscalNumber } from "../../models/domains/pdv/fiscal-number";
import { TermForm, TermFormGroup } from "../../models/domains/pdv/form-term";
import { NetworkAcceptTerm } from "../../models/domains/pdv/network-accept-term";
import { Term } from "../../models/domains/pdv/term";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { MdmBackendUrl } from "../../models/domains/urls/mdm-backend.url";
import { NetworkAcceptTermParser } from "../parsers/pdv/network.accept.term.parser";
import { TermFormParser } from "../parsers/pdv/term-form.parser";
import { TermParser } from "../parsers/pdv/term.parser";

@Injectable()
export class AcceptTermRepository {
  constructor(
    private remoteGatewayFactory: RemoteGatewayFactory,
    private termParser: TermParser,
    private termFormParser: TermFormParser,
    private networkAcceptTerm: NetworkAcceptTermParser,
  ) {}

  public async acceptTerm(fiscalNumber: FiscalNumber, term: Term): Promise<void> {
    const body = {
      TermKey: term.getTermKey(),
      FiscalNumber: fiscalNumber.getValue(),
      Accepted: true,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();

          await remoteGateway.postWithHeaders(new BackendUrl('/api/pbm/drugstore/network/term/accept'), body, headers);      
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async getTerm(networkInfoKey: string): Promise<Term> {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
            const data: any = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/network/term/' + networkInfoKey), headers);
      return Promise.resolve(this.termParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async getNetworkAcceptTermByFiscalNumber(fiscalNumber: FiscalNumber): Promise<NetworkAcceptTerm> {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();

            const data: any = await remoteGateway.getWithHeaders(new BackendUrl('/api/pbm/drugstore/network/term/active/'+ fiscalNumber.getValue()), headers);            
      return Promise.resolve(this.networkAcceptTerm.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async getTermForm(fiscalNumber: FiscalNumber): Promise<TermForm> {
    const headers = { "Content-Type": "application/json" };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const data: any = await remoteGateway.getWithHeaders(new MdmBackendUrl("/api/mdm/networks/additional-info?fiscalNumber=" + fiscalNumber.getValue()), headers);
      return Promise.resolve(this.termFormParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async sendTermForm(form: TermFormGroup): Promise<Term> {
    const formData = form.createFormData();
    const headers = {
      Accept: "application/json",
    };
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const data: any = await remoteGateway.postWithHeaders(new MdmBackendUrl("/api/mdm/networks/additional-info"), formData, headers);
      return Promise.resolve(this.termParser.parse(data));
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public async downloadTerm(termId: string, networkInfoKey: string): Promise<Blob> {
    const headers = { "Content-Type": "application/json" };
    const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
    try {
      const data: Blob = await remoteGateway.downloadAsGet(new MdmBackendUrl("/api/mdm/term/download/" + termId + "/" + networkInfoKey), headers);
      return Promise.resolve(data);
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
