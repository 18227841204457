import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injectable, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AppContext } from "src/app/ui/contexts/app-context";
import { FilterOption } from "src/app/ui/models/domains/filters/filter-option";
import { FilterOptionType } from "src/app/ui/models/domains/filters/filter-option-type.enum";
import { PagingRequest } from "src/app/ui/models/domains/paginator/paging-request";
import { Ean } from "src/app/ui/models/domains/pdv/ean";
import { ProductSimple } from "src/app/ui/models/domains/pdv/product-simple";
import { SalesOrderItem } from "src/app/ui/models/domains/pdv/sales-order-item";
import { LoadingService } from "src/app/ui/services/loading.service";
import { MedicalPrescriptionService } from "src/app/ui/services/pdv/medical-prescription.service";
import { ProductService } from "src/app/ui/services/pdv/product.service";
import { PurchaseService } from "src/app/ui/services/pdv/purchase.service";
import { Toast } from "../../../toast/toast";

@Component({
  selector: "pdv-search-product-bar",
  templateUrl: "./search-product-bar.component.html",
  styleUrls: ["./search-product-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@Injectable({ providedIn: "root" })
export class SearchProductBar implements OnInit {
  @Input() placeHolder: string;
  searchControl: FormControl = new FormControl();
  filteredOptions: BehaviorSubject<Array<ProductSimple>> = new BehaviorSubject(null);
  isSearchingProduct: boolean;

  constructor(
    private productService: ProductService,
    private medicalPrescriptionService: MedicalPrescriptionService,
    private purchaseService: PurchaseService,
    private loadingService: LoadingService,
    private appContext: AppContext,
    private toast: Toast,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(debounceTime(400)).subscribe((data) => {
      if (typeof data === "string") {
        this.findProductSimpleList(data);
      }
    });
  }

  async findProductSimpleList(filter: string) {
    this.isSearchingProduct = true;
    const pagingRequest = new PagingRequest();
    pagingRequest.setStart(0);
    pagingRequest.setEnd(10);

    const filterText = new FilterOption(FilterOptionType.Text, "filterText", "filterText");
    filterText.setValue(filter);

    pagingRequest.setFilters([filterText]);

    try {
      this.cdr.detectChanges();
      const pageableResult = await this.productService.findProductSimpleList(pagingRequest, this.appContext.getNetwork());
      if (pageableResult.getItems().length > 0) {

          const productSimpleList = pageableResult.getItems().map((productSimple: ProductSimple) => {
            return productSimple;
          });
          this.filteredOptions.next(productSimpleList);

        if(pageableResult.getItems().length === 1){
          this.addProduct(productSimpleList[0].getEan());
        }

      } else {
        this.toast.showWarningToast("Nenhum produto encontrado.");
      }
    } catch (error) {
    } finally {
      this.isSearchingProduct = false;
      this.cdr.detectChanges();
    }
  }

  async addProduct(ean: Ean) {
    const ref = this.loadingService.beginLoading();
    if (!this.hasItem(ean)) {
      try {
        let network = this.appContext.getNetwork();
        let shopper = this.appContext.getSalesOrder().getShopper();
        const product = await this.productService.findProductByEan(ean, shopper, network);
        if (product.getPrice() === 0) {
          this.toast.showWarningToast("Produto sem preço. Por favor, entre em contato com o suporte para mais detalhes.");
        } else {
          const configMedicalPrescription = await this.medicalPrescriptionService.doesProductRequireScan(shopper, product);
          const salesOrderItem = new SalesOrderItem(product, configMedicalPrescription);
          const cmrField = this.appContext
            .getSalesOrder()
            .getSalesOrderItemList()
            .find((x) => x.hasMedicalPrescriptionInitialized());

          if (cmrField) {
            salesOrderItem.setMedicalPrescription(cmrField.getMedicalPrescription());
          }

          this.purchaseService.addSalesOrderItem(salesOrderItem);
          this.filteredOptions.next(null);
        }
      } catch (error) {
        if(error.error.Data){
          if(error.error.Data.ErrorCode === "Product.Not.Found.In.Configuration.Cluster"){
            this.toast.showWarningToast(error.error.Data.ErrorMessage);
          }else{
            this.toast.showWarningToast("Erro ao adicionar produto.");
          }
        }else{
          this.toast.showWarningToast("Erro ao adicionar produto.");
        }
      } finally {
        this.searchControl.reset();
        this.loadingService.finishLoading(ref);
      }
    } else {
      this.loadingService.finishLoading(ref);
    }
  }

  displayFn(productSimple?: ProductSimple): string | undefined {
    return productSimple ? productSimple.getDisplayName() : undefined;
  }

  hasItem(ean: Ean): boolean {
    let hasItem = false;
    try {
      const salesOrder = this.appContext.getSalesOrder();
      for (let i = 0; i < salesOrder.getSalesOrderItemList().length; i++) {
        const item = salesOrder.getSalesOrderItemList()[i];
        if (item.getProduct().getEan().getValue() === ean.getValue()) {
          hasItem = true;
          this.purchaseService.addSalesOrderItemQuantity(item);
          this.searchControl.reset();
          this.filteredOptions.next(null);
        }
      }
    } catch (error) {
      console.error(error);
    }
    return hasItem;
  }
}
