import { Injectable } from "@angular/core";
import { RemoteGatewayFactory } from "../../gateways/remote-gateway-factory";
import { StateAcronymModel } from "../../models/domains/pdv/state-acronym-model";
import { BackendUrl } from "../../models/domains/urls/back-end.url";
import { StateAcronymParser } from "../parsers/pdv/state-acronym-parser";

@Injectable()
export class StateRepository {
  constructor(private remoteGatewayFactory: RemoteGatewayFactory, private stateAcronymParser: StateAcronymParser) {}

  public async getStates(): Promise<StateAcronymModel[]> {
    try {
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const data = await remoteGateway.get(new BackendUrl("/api/pbm/states/acronyms"));
      return Promise.resolve(this.stateAcronymParser.parse(data));
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
