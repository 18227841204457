export const environment = {
  tagAnalytics: "#{tagAnalytics}#",
  authBaseUrl: "#{authBaseUrl}#",
  authServiceTokenValidationUrl: "#{authServiceTokenValidationUrl}#",
  authUserTokenValidationUrl: "#{authUserTokenValidationUrl}#",
  backendUrl: "#{backendUrl}#",
  grantType: "#{grantType}#",
  mdmBackendUrl: "#{mdmBackendUrl}#",
  networkServiceUrl: "#{networkServiceUrl}#",
  networkServiceApiKey: "#{networkServiceApiKey}#",
  pipUrl: "#{pipUrl}#",
  production: "#{production}#",
  redirectUrl: "#{redirectUrl}#",
  reportServiceUrl: "#{reportServiceUrl}#",
  reportUrl: "#{reportUrl}#",
  clerkSupport: "#{clerkSupport}#",
  redirectToWhatsapp: "#{redirectToWhatsapp}#",
  clarityId: "#{clarityId}#",

  appName: "PBM-PORTAL-DROGARIA-APP",
  authClientId: "30",
  forgotPasswordConfirmationUrl: "http://localhost:4200/reset-password",
  forgotPasswordUrl: "https://idpusrauth-api.azurewebsites.net",
  redirectOnExitingApp: "access-error",
  version: "2.9.1",
};
