export class IFileFileExportResult {
  notifyDownload(name: string): void {}
}

export class CSVFileExportResult implements IFileFileExportResult {
  constructor(private blob: Blob) {}

  notifyDownload(name: string = "file-export"): void {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(this.blob);
    a.href = url;
    a.download = `${name}-${this.formatCurrentDate()}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  private formatCurrentDate(): string {
    function pad2(n: number) {
      return (n < 10 ? "0" : "") + n;
    }
    const date = new Date();
    return date.getFullYear() + pad2(date.getMonth() + 1) + pad2(date.getDate()) + pad2(date.getHours()) + pad2(date.getMinutes()) + pad2(date.getSeconds());
  }
}

export class XLSXFileExportResult implements IFileFileExportResult {
  constructor(private blob: Blob) {}

  notifyDownload(name: string = "file-export"): void {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(this.blob);
    a.href = url;
    a.download = `${name}-${this.formatCurrentDate()}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  private formatCurrentDate(): string {
    function pad2(n: number) {
      return (n < 10 ? "0" : "") + n;
    }
    const date = new Date();
    return date.getFullYear() + pad2(date.getMonth() + 1) + pad2(date.getDate()) + pad2(date.getHours()) + pad2(date.getMinutes()) + pad2(date.getSeconds());
  }
}
