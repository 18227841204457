import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { Masks } from "../ui/models/domains/validators/masks";

@Directive({
  selector: "[appMask]",
})
export class MaskDirective {
  @Input("appMask") mask: string;

  constructor(private eleRef: ElementRef) {}

  @HostListener("input")
  onChange() {
    this.eleRef.nativeElement.value = Masks[this.mask](this.eleRef.nativeElement.value);
  }
}
