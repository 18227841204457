import { RemoteGatewayFactory } from "../gateways/remote-gateway-factory";
import { Injectable } from "@angular/core";
import { NetworkServiceUrl } from "../models/domains/urls/network-backend.url";
import { environment } from "src/environments/environment";

@Injectable()
export class IdpNetworkRepository {
  constructor(private remoteGatewayFactory: RemoteGatewayFactory) {}

  public async getNetworks(network:string): Promise<any> {
    try {
      
      const headers = {
        "api-key": `api-key ${environment.networkServiceApiKey}`,
      };
      const remoteGateway = this.remoteGatewayFactory.createDefaultRemoteGateway();
      const data = await remoteGateway.getWithHeaders(new NetworkServiceUrl(`/v1/networks/${network}`), headers);
      return data;
    } catch (error) {
      return await Promise.reject(error);
    }
  }
}
