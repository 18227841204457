export class ProductClusterExceptInfo {
  private allowedPurchase: boolean;
  private allowedCheckoutOnline: boolean;
  private allowedFullEmploymentPayroll: boolean;

  setAllowedPurchase(value: boolean): void {
    this.allowedPurchase = value;
  }
  getAllowedPurchase(): boolean {
    return this.allowedPurchase;
  }

  setAllowedCheckoutOnline(value: boolean): void {
    this.allowedCheckoutOnline = value;
  }
  getAllowedCheckoutOnline(): boolean {
    return this.allowedCheckoutOnline;
  }

  setAllowedFullEmploymentPayroll(value: boolean): void {
    this.allowedFullEmploymentPayroll = value;
  }
  getAllowedFullEmploymentPayroll(): boolean {
    return this.allowedFullEmploymentPayroll;
  }
}
