import { Injectable } from "@angular/core";

import { PageableResult } from "src/app/ui/models/domains/paginator/pageable-result";
import { Ean } from "src/app/ui/models/domains/pdv/ean";
import { ProductKey } from "src/app/ui/models/domains/pdv/product-key";
import { ProductSimple } from "src/app/ui/models/domains/pdv/product-simple";

import { AbstractParser } from "../parser";

@Injectable()
export class FindProductSimpleListParser extends AbstractParser<PageableResult> {
  parse(payload: any): PageableResult {
    const pageableResult = new PageableResult();
    pageableResult.setStart(payload.Start);
    pageableResult.setEnd(payload.TotalPerPages);
    pageableResult.setTotalItems(payload.Total);
    pageableResult.setItems(this.parseSimpleProducts(payload.Data));
    return pageableResult;
  }

  private parseSimpleProducts(payload: Array<any>): Array<ProductSimple> {
    const simpleProductList: Array<ProductSimple> = payload.map((item) => {
      const simpleProduct = new ProductSimple();
      simpleProduct.setProductKey(new ProductKey(item.ProductKey.Value));
      simpleProduct.setEan(new Ean(item.Ean.Value));
      simpleProduct.setProductName(item.ProductName);
      simpleProduct.setPresentation(item.Presentation);
      simpleProduct.setLaboratory(item.Laboratory);
      simpleProduct.setProductSubType(item.ProductSubType);
      return simpleProduct;
    });
    return simpleProductList;
  }
}
