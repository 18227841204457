export class FileUpload {
  private beneficiaryId:string;
  private fiscalNumber:string;
  private nsu:string;

  setBeneficiaryId(value: string): void {
    this.beneficiaryId = value;
  }

  getBeneficiaryId(): string {
    return this.beneficiaryId;
  }

  setFiscalNumber(value: string): void {
    this.fiscalNumber = value;
  }

  getFiscalNumber(): string {
    return this.fiscalNumber;
  }
  
  setNsu(value: string): void {
    this.nsu = value;
  }

  getNsu(): string {
    return this.nsu;
  }
}
