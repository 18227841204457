import { EventEmitter, Inject } from "@angular/core";
import { Component } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { MaskInput } from "src/app/ui/models/domains/pdv/mask-input";

@Component({
  templateUrl: "./input-dialog.component.html",
  styleUrls: ["./input-dialog.component.scss"],
})
export class InputDialogComponent {
  public inputDialogEvent = new EventEmitter();
  public cancelDialogEvent = new EventEmitter();
  public inputValue: string = "";
  public transactionalPassword = MaskInput.getNumericalPassword();

  constructor(public dialogRef: MatDialogRef<InputDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IInputDialogData) {}

  public getTitle(): string {
    return this.data.title;
  }

  public getText(): string {
    return this.data.text;
  }

  public getCancelText(): string {
    return this.data.cancelText ? this.data.cancelText : "CANCELAR";
  }

  public closeDialog(): void {
    this.cancelDialogEvent.emit();
    this.dialogRef.close();
  }

  public emitConfirmEvent() {
    this.inputDialogEvent.emit(this.inputValue);
    this.dialogRef.close();
  }
}

export interface IInputDialogData {
  title?: string;
  text?: string;
  cancelText?: string;
}
